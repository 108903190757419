import React, {useEffect, useState} from 'react';
import {Menu, MenuItem, Sidebar, SubMenu} from "react-pro-sidebar";
import {FaCircle, FaUsers} from 'react-icons/fa';
import {FiLogOut, FiUsers} from "react-icons/fi";
import {AiOutlineRead, AiOutlineUserAdd} from "react-icons/ai";

import '../dashboard/App.scss';
import './SuperAdminApp.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useNavigate} from "react-router-dom";
import createPersistentStorage from "../persistent-storage";
import {getUsers} from "./superAdminApi";
import {User} from "./models/User";
import UserPage from "./UserPage/UserPage";
import EditUserPage from "./EditUserPage/EditUserPage";
import GuidePage from "./GuidePage/GuidePage";
import {MdEventNote, MdOutlineSupervisedUserCircle} from "react-icons/md";
import CampaignsPage from "./CampaignsPage/CampaignsPage";
import {BsLayoutTextSidebarReverse} from "react-icons/bs";
import SuperAdminLogsPage from "./SuperAdminLogsPage/SuperAdminLogsPage";
import AllUsersPage from "./AllUsersPage/AllUsersPage";
import OtherFunctionsPage from "./OtherFunctionsPage/OtherFunctionsPage";

const superAccessTokenStorage = createPersistentStorage<string>("super_admin_access_token");
const adminTokenStorage = createPersistentStorage<string>("admin_access_token");

enum Page {
    Dashboard,
    User,
    EditUser,
    Guide,
    Campaigns,
    Logs,
    AllUsers,
    OtherFunctions
}
export function SuperAdminApp() {
    // const [cookies, setCookie] = useCookies<string>(['user']);
    const [initialized, setInitialized ] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [currentPage, setCurrentPage] = useState(Page.Dashboard);
    const [currentUser, setCurrentUser] = useState<User>();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(superAccessTokenStorage);
        if (!superAccessTokenStorage.read()) {
            navigateToLogin();
        }
        setInitialized(true);
    }, []);

    useEffect(() => {
        updateUsers();
        setInitialized(true);
    }, []);


    const updateUsers = () => {
        getUsers().then(users => {
            setUsers(users.data);
            setLoadingUsers(false);
        });
    }

    const logout = () => {
        superAccessTokenStorage.delete()
        adminTokenStorage.delete()
        navigateToLogin();
    }

    const openUserPage = (user: User, updateAllUsers: boolean = false) => {
        setCurrentUser(user);
        setCurrentPage(Page.User);
        if (updateAllUsers) {
            updateUsers();
        }
    }

    const openCampaigns = () => setCurrentPage(Page.Campaigns);
    const openNewUserPage = () => {
        setCurrentUser(undefined);
        setCurrentPage(Page.EditUser);
    }

    const editUserClicked = (user: User) => {
        setCurrentUser(user);
        setCurrentPage(Page.EditUser);
    }

    const openGuide = () => setCurrentPage(Page.Guide);
    const openAlUsers = () => setCurrentPage(Page.AllUsers);
    const navigateToLogin = () => navigate("/super-admin/login");

    return (
        <div className="superAdminApp">
            <div className="sidebarArea">
                <h1>Super Admin</h1>
                <Sidebar>
                    <Menu>
                        <MenuItem icon={<MdEventNote />} onClick={openCampaigns}>Kampagner</MenuItem>
                        <MenuItem icon={<FiUsers />} onClick={openAlUsers}>Alle Brugere</MenuItem>
                        <SubMenu className="submenu" defaultOpen={false} label="Brugere" icon={<FaUsers />}>
                            <MenuItem icon={<AiOutlineUserAdd />} onClick={openNewUserPage} className="newUser">Ny bruger</MenuItem>
                            {
                                !loadingUsers && users.map(user => (
                                    <MenuItem key={user.id} icon={<FaCircle/>} active={currentPage === Page.User && currentUser?.id === user.id} onClick={() => openUserPage(user)}>{user.userName}</MenuItem>
                                ))
                            }
                        </SubMenu>
                    </Menu>
                    <Menu className="line-above">
                        <MenuItem icon={<MdOutlineSupervisedUserCircle />} active={currentPage === Page.OtherFunctions} onClick={() => setCurrentPage(Page.OtherFunctions)}>Andet</MenuItem>
                        <MenuItem icon={<BsLayoutTextSidebarReverse />} active={currentPage === Page.Logs} onClick={() => setCurrentPage(Page.Logs)}>Logs</MenuItem>
                    </Menu>
                    <Menu className="line-above">
                        <MenuItem icon={<AiOutlineRead />} onClick={openGuide}>Admin Guide</MenuItem>
                    </Menu>
                    <Menu className="line-above">
                        <MenuItem icon={<FiLogOut />} onClick={logout}>Log ud</MenuItem>
                    </Menu>
                </Sidebar>
            </div>
            {initialized &&
                <div className="mainArea">
                    {currentPage === Page.User && <UserPage user={currentUser} onEditUser={editUserClicked}/>}
                    {currentPage === Page.EditUser && <EditUserPage user={currentUser} onGotoUser={openUserPage} onCancel={() => currentUser ? setCurrentPage(Page.User) : setCurrentPage(Page.Dashboard)} />}
                    {currentPage === Page.Guide && <GuidePage />}
                    {currentPage === Page.Campaigns && <CampaignsPage users={users}/>}
                    {currentPage === Page.Logs && <SuperAdminLogsPage users={users}/>}
                    {currentPage === Page.OtherFunctions && <OtherFunctionsPage />}
                    {(currentPage === Page.AllUsers || !currentPage) && <AllUsersPage users={users} />}

                </div>
            }
        </div>
    )
}

