import React, {FormEvent, useEffect, useState} from "react";
import "./style.scss";
import axios from "axios";
import createPersistentStorage from "../../../persistent-storage";
import {react_app_backend_url} from "../../../globalApi";
import Button from "react-bootstrap/Button";
import {checkIfLoggedNoRedirect} from "../../dashboardApi";

const accessTokenStorage = createPersistentStorage<string>("access_token");
const adminAccessTokenStorage = createPersistentStorage<string>("admin_access_token");

function LoginClient() {
    const [isSubmitted] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        checkIfLoggedNoRedirect().then(authed => {
            if (authed) {
                window.location.href = "/"
            }
        });
    }, [])

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        //Prevent page reload
        event.preventDefault();


        let formData = new FormData(event.currentTarget);

        axios.post(react_app_backend_url + "login/access-token",
            formData,
            {headers: {'Content-Type': 'multipart/form-data'}, validateStatus: () => true})
            .then((response) => {
                if (response.status === 200 ) {
                    accessTokenStorage.write(response.data.access_token)
                    window.location.href = "/"
                }
                if (response.status !== 200) {
                    setError(response.data.detail)
                }
            })
    };

    const clearTokens = () => {
        accessTokenStorage.delete()
        adminAccessTokenStorage.delete();
    }

    // JSX code for login form
    const renderForm = (
        <div className="form">
            <span style={{"color":"red"}}>{error}</span>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>Brugernavn </label>
                    <input type="text" name="username" id="username" required/>

                </div>
                <div className="input-container">
                    <label>Kode </label>
                    <input type="password" name="password" id="password" required/>
                </div>
                <div className="button-container">
                    <input type="submit" value="Login"/>
                </div>
            </form>
        </div>
    );

    return (
        <div className="app">
            <Button className="resetTokens" variant="outline-warning" onClick={clearTokens}>Reset logins</Button>
            <div className="login-form">
                <div className="title">Log ind for at se madspilds skærm</div>
                {isSubmitted ? <div>Brugger er blevet logget ind</div> : renderForm}
            </div>
        </div>
    );
}

export default LoginClient;
