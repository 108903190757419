import styles from "./CampaignsPage.module.scss"
import React, {FC, useEffect, useState} from 'react';
import {createNewCampaign, getCampaigns, updateCampaign} from "../superAdminApi";
import {Campaign} from "../../models/Campaign";
import {monthNameString} from "../../utils/dateUtils";
import CampaignCard from "../components/CampaignCard/CampaignCard";
import {User} from "../models/User";
import Button from "react-bootstrap/Button";

interface CampaignPageProps {
    users: User[]
}

interface MonthMap {
    [key: string]: Campaign[];
}

interface MonthSpec {
    monthIndex: number,
    year: number
}

const CampaignsPage: FC<CampaignPageProps> = (props) => {
    const [upcomingCampaignsMap, setUpcomingCampaignsMap] = useState<MonthMap>({});
    const [upcomingMonths, setUpcomingMonths ] = useState<MonthSpec[]>([]);
    const [activeCampaigns, setActiveCampaigns] = useState<Campaign[]>([]);
    const [openedCampaign, setOpenedCampaign] = useState<Campaign | undefined>();
    const [showOpenCampaign, setShowOpenCampaign] = useState<boolean>(false)
    const [loading, setLoading ] = useState<boolean>(true);
    const [usersMap, setUsersMap ] = useState<{[key: string]: string}>({})

    useEffect(() => fetchAndPrepareCampaigns(),[]);

    useEffect(() => {
        let umap: {[key: string]: string} = {};
        for (const user of props.users) {
            umap[user.id] = user.userName;
        }
        setUsersMap(umap);
    }, [props.users]);

    const fetchAndPrepareCampaigns = () => {
        setLoading(true);
        getCampaigns().then(result => {
            console.log("CAMPAIGNS", result)
            let upcomingMap: MonthMap = {};
            let upcomingAsArray = result.data.filter(x => isInFuture(x.year, x.monthIndex));
            for (let campaign of upcomingAsArray) {
                const key = buildCampaignMapKey(campaign.year, campaign.monthIndex);
                if (!upcomingMap[key]) {
                    upcomingMap[key] = [];
                }
                upcomingMap[key].push(campaign);
            }
            setActiveCampaigns(result.data.filter(x => !isInFuture(x.year, x.monthIndex)));
            setUpcomingCampaignsMap(upcomingMap);

           if (upcomingAsArray.length === 0) {
                setUpcomingMonths([]);
                return;
            }

            const now = new Date();
            const last: MonthSpec = {
                year: upcomingAsArray[upcomingAsArray.length - 1].year,
                monthIndex: upcomingAsArray[upcomingAsArray.length - 1].monthIndex
            }
            let toIterate: MonthSpec[] = [];
            for (let year = now.getFullYear(); year <= last.year; year++) {
                for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
                    if ((year === now.getFullYear() && monthIndex <= now.getMonth()) ||
                        (year === last.year && monthIndex > last.monthIndex)) {
                        continue;
                    }
                    toIterate.push({year, monthIndex})
                }
            }
            setUpcomingMonths(toIterate);
            setLoading(false);
        })
    };

    const buildCampaignMapKey = (year: number, monthIndex: number): string => `${year}-${monthIndex}`;
    const isInFuture = (year: number, monthIndex: number) => {
        const now = new Date();
        return year > now.getFullYear() || (year === now.getFullYear() && monthIndex > now.getMonth());
    }
    const openCampaign = (campaign: Campaign) => {
        setOpenedCampaign(campaign)
        setShowOpenCampaign(true);
    }

    const closeCampaign = (campaign: Campaign, save: boolean) => {
        if (save) {
            if (!campaign.id) {
                createNewCampaign(campaign).then(r => fetchAndPrepareCampaigns());
            }
            updateCampaign(campaign).then(r => fetchAndPrepareCampaigns())
        }
        setShowOpenCampaign(false);
    }

    const newCampaignClicked = () => {
        setOpenedCampaign(undefined);
        setShowOpenCampaign(true);
    }

    return (
        <div className={styles.campaignsPage}>
            { showOpenCampaign &&
                <div className={styles.openedCampaignArea}>
                    <div className={styles.backdrop}></div>
                    <div className={styles.cardContainer}>
                        <CampaignCard
                            campaign={openedCampaign}
                            readOnly={false}
                            onCancelClick={() => setShowOpenCampaign(false)}
                            onSaveClick={(campaign) => closeCampaign(campaign, true)}
                            users={usersMap}
                        />
                    </div>
                </div>
            }
            <div className={styles.campaignListingArea}>
               <div className={styles.headerBar}>
                   <h1>Kampagner</h1>
                   <Button variant="btn btn-primary" onClick={newCampaignClicked}>Opret ny kampagne</Button>
               </div>
                <h2>Denne måned</h2>
                <div className={styles.campaignCards}>
                    {activeCampaigns.map(campaign =>
                            <CampaignCard
                                campaign={campaign}
                                readOnly={true}
                                onOpenClick={openCampaign}
                                key={campaign.id}
                                users={usersMap}
                            />

                    )}
                </div>

                <h2>Kommende Kampagner</h2>
                {upcomingMonths.map(monthSpec =>
                    <div className={styles.monthHeader} key={buildCampaignMapKey(monthSpec.year, monthSpec.monthIndex)}>
                        <h3>{monthNameString(monthSpec.monthIndex)} {monthSpec.year}</h3>
                        <div className={styles.campaignCards}>
                            {upcomingCampaignsMap[buildCampaignMapKey(monthSpec.year, monthSpec.monthIndex)]
                                && upcomingCampaignsMap[buildCampaignMapKey(monthSpec.year, monthSpec.monthIndex)].map(campaign =>
                                        <CampaignCard
                                            campaign={campaign}
                                            readOnly={true}
                                            onOpenClick={openCampaign}
                                            key={campaign.id}
                                            users={usersMap}
                                        />
                                )
                            }
                        </div>
                    </div>
                )}
                <h2>Afsluttede Kampagner</h2>
            </div>
        </div>
    );
}

export default CampaignsPage;
