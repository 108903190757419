import React, {FC, useEffect, useState} from "react";
import {AiOutlineCheck, AiOutlineClose, AiOutlineEdit} from "react-icons/ai";
import {Campaign, CampaignGroup} from "../../../models/Campaign";
import styles from './EditableGroups.module.scss';
import Button from "react-bootstrap/Button";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import EditableText from "./EditableText";

interface EditableGroupsProps {
    usersMap: {[key: string]: string},
    campaign: Campaign,
    groupsUpdated: (groups: CampaignGroup[]) => any;
}

const EditableGroups: FC<EditableGroupsProps> = (props) => {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [groups, setGroups] = useState<CampaignGroup[]>([])
    const [groupsBeforeEdit, setGroupsBeforeEdit] = useState<CampaignGroup[]>([]);
    const [unassignedUsers, setUnassignedUsers] = useState<string[]>([]);

    useEffect(() => setGroupsBeforeEdit(cloneGroups(props.campaign?.data?.groups)), [props.campaign?.data])
    useEffect(() => setGroups(props.campaign?.data?.groups ? cloneGroups(props.campaign.data.groups) : []), [JSON.stringify(props.campaign?.data?.groups)])


    useEffect(() => {
        if (props.campaign?.userIds) {
            let currentlyAssigned: string[] = [];
            for (const group of groups) {
                currentlyAssigned = [...currentlyAssigned, ...group.userIds];
            }
            const unassigned = props.campaign.userIds.filter(x => !currentlyAssigned.includes(x));
            setUnassignedUsers(unassigned);
        } else {
            setUnassignedUsers([]);
        }
    }, [groups, props.campaign?.userIds]);

    const cloneGroups = (toClone: CampaignGroup[] | undefined) => {
        return toClone ?
            toClone.map(group => {return { ...group, userIds: [...group.userIds]}}) :
            [];
    }

    useEffect(() => console.log("GROUPS", groups), [groups])

    const startEditMode = () => { setGroupsBeforeEdit(cloneGroups(props.campaign?.data?.groups)); setIsEditing(true); }
    const endEditMode = () => { setIsEditing(false); }
    const cancel = () => { endEditMode(); props.groupsUpdated(groupsBeforeEdit)}
    const addGroup = () => props.groupsUpdated([...groups, { name: `Pulje ${groups.length+1}`, userIds: []}])
    const onDragEnd = (drop: DropResult) => {
        let newGroups = [...groups];

        // remove from source
        if (drop.source && drop.source.droppableId !== "unassigned") {
            if (!isNaN(parseInt(drop.source.droppableId))) {
                let groupIndex = +drop.source.droppableId;
                newGroups[groupIndex].userIds.splice(drop.source.index, 1);
            }
        }

            // add to destination
        if (drop.destination && drop.destination.droppableId !== "unassigned") {
            if (!isNaN(parseInt(drop.destination.droppableId))) {
                let groupIndex = +drop.destination.droppableId;
                newGroups[groupIndex].userIds.splice(drop.destination.index, 0, drop.draggableId);
            }
        }
        props.groupsUpdated(newGroups);
    }

    return (
        <span style={{marginLeft: "4px"}} className={styles.editableGroups}>
            { !isEditing &&
                <>
                    <span style={{cursor: "pointer"}} onClick={() => startEditMode()}>
                        <AiOutlineEdit />
                    </span>
                    <div className={styles.roGroups}>
                        { groups && groups.map((group, idx) =>
                            <div className={styles.group}>
                                <h5>{group.name}</h5>
                                <ul>
                                    {group.userIds.map(userId => <li key={userId}>{props.usersMap[userId]}</li>)}
                                </ul>
                            </div>
                        )}
                    </div>
                </>

            }
            { isEditing &&
                <div className={styles.editMode}>
                    <div className={styles.top}>
                        <Button variant="primary" onClick={addGroup}>Ny pulje</Button>
                    </div>
                    <div className={styles.groups}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <div className={styles.draggableList}>
                                <h5 className={styles.groupName}>Ikke tildelt</h5>
                                <Droppable droppableId={"unassigned"}>
                                    {provided => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} className={styles.unassignedList}>
                                            {unassignedUsers.map((userId, index) => (
                                                <Draggable key={userId} draggableId={userId} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            { props.usersMap[userId] }
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                            { groups && groups.map((group, idx) => (
                                <div key={idx}>
                                    <h5 className={styles.groupName}>
                                        <EditableText text={group.name} textUpdated={newName => { group.name = newName; props.groupsUpdated(groups)}}></EditableText>
                                    </h5>
                                    <Droppable droppableId={`${idx}`}>
                                        {provided => (
                                            <div {...provided.droppableProps} ref={provided.innerRef} className={styles.groupList}>
                                                {group.userIds.map((userId, index) => (
                                                    <Draggable key={userId} draggableId={userId} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                { props.usersMap[userId] }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            ))}
                        </DragDropContext>
                    </div>

                    <AiOutlineCheck onClick={endEditMode} style={{fontSize: "10px", marginLeft: "4px"}}/>
                    <AiOutlineClose onClick={cancel} style={{fontSize: "10px", marginLeft: "4px"}}/>
                </div>
            }
        </span>
    )
}

export default EditableGroups;
