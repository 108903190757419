import React, {FC, useEffect, useState} from 'react';
import styles from './SystemSettingsPage.module.scss';
// import Alert from "react-bootstrap/Alert";
// import {getEmptyingLimits, updateEmptyingLimits} from "../adminApi";
// import {getNumDining, updateNumDining} from "../../adminApi";

interface SystemSettingsPageProps {}

const SystemSettingsPage: FC<SystemSettingsPageProps> = () => {
    // const [errorMsg, setErrorMsg] = useState<string>("");
    // const [baseWeight, setBaseWeight] = useState<number>(0);
    // const [originalBaseWeight, setOriginalBaseWeight] = useState<number>(0);
    // const [loadingBaseWeight, setLoadingBaseWeight] = useState<boolean>(true);
    // const [baseWeightModified, setBaseWeightModified] = useState<boolean>(false);
    //
    // const [numDining, setNumDining] = useState<number>(0);
    // const [originalNumDining, setOriginalNumDining] = useState<number>(0);
    // const [loadingNumDining, setLoadingNumDining] = useState<boolean>(true);
    // const [numDiningModified, setNumDiningModified] = useState<boolean>(false);

    // useEffect(() => {
    //     let mounted = true;
    //     // getBaseWeight().then((res: any) => {
    //     //     if (mounted) {
    //     //         if (res && res.status === 200) {
    //     //             var bw = res.data?.baseWeight;
    //     //             if (bw === undefined) {
    //     //                 setErrorMsg("Fejl i at hente værdier. Ugyldig data returneret. Load siden igen");
    //     //             } else {
    //     //                 setOriginalBaseWeight(bw);
    //     //                 setBaseWeight(bw);
    //     //                 setLoadingBaseWeight(false);
    //     //             }
    //     //         } else {
    //     //             setErrorMsg("Kunne ikke hente værdien for ekstra vægt. Load siden igen");
    //     //         }
    //     //     }
    //     // })
    //
    //     getNumDining().then((res: any) => {
    //         if (mounted) {
    //             if (res && res.status === 200) {
    //                 console.log(res)
    //                 var bw = res.data?.numDining;
    //                 if (bw === undefined) {
    //                     setErrorMsg("Fejl i at hente værdier. Ugyldig data returneret. Load siden igen");
    //                 } else {
    //                     setOriginalNumDining(bw);
    //                     setNumDining(bw);
    //                     setLoadingNumDining(false);
    //                 }
    //             } else {
    //                 setErrorMsg("Kunne ikke hente værdien for ekstra vægt. Load siden igen");
    //             }
    //         }
    //     })
    //     return () => { mounted = false };
    // }, []);
    //
    // // const saveBaseWeight = async () => {
    // //     setErrorMsg("");
    // //     try {
    // //         await updateBaseWeight(baseWeight);
    // //         setBaseWeightModified(false);
    // //     } catch (e) {
    // //         setErrorMsg("Kunne ikke gemme værdi for ekstra vægt. Prøv igen");
    // //     }
    // // }
    //
    // const saveNumDining = async () => {
    //     setErrorMsg("");
    //     try {
    //         await updateNumDining(numDining);
    //         setNumDiningModified(false);
    //     } catch (e) {
    //         setErrorMsg("Kunne ikke gemme værdi for antal spisende. Prøv igen");
    //     }
    // }
    //
    // const extractNumericValue = (val: any): number =>  {
    //     let numeric = Number(val?.target?.value);
    //     if (isNaN(numeric)) {
    //         setErrorMsg(`Ikke et gyldigt tal: ${val}. Brug '.' som komma`);
    //         return 0;
    //     } else {
    //         return numeric;
    //     }
    // }
    //
    // const resetLimits = () => {
    //     setErrorMsg("");
    //     setBaseWeightModified(false);
    //     setBaseWeight(originalBaseWeight);
    // }
    //
    // const resetNumDining = () => {
    //     setErrorMsg("");
    //     setNumDiningModified(false);
    //     setNumDining(originalNumDining);
    // }

    return (
        <div className={styles.SystemSettingsPage}>
            <div className="adminFrame">
                <h1>System Indstillinger</h1>
                <i>Her kan du sætte diverse indstillinger for hvordan systemet kører. Lav kun ændringer her hvis du ved hvad du laver.</i>
                {/*{errorMsg && <Alert variant="danger">{errorMsg}</Alert>}*/}
                <hr />
                {/*<h3>Antal spisende</h3>*/}
                {/*{!loadingNumDining &&*/}
                {/*    <div>*/}
                {/*        <i>*/}
                {/*            <p>Antal spisende som vil blive registreret sammen med hvert måltid. Denne værdi vil*/}
                {/*                IKKE påvirke tidligere målte tømninger - den påvirker kun fremtidige målinger*/}
                {/*            </p>*/}
                {/*        </i>*/}

                {/*        <div className={styles.FormArea}>*/}
                {/*            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">*/}
                {/*                <Form.Label column sm={3}>Antal spisende</Form.Label>*/}
                {/*                <Col sm={2}>*/}
                {/*                    <FormControl type="number" step={1} value={numDining} onChange={x => {*/}
                {/*                        setNumDining(extractNumericValue(x));*/}
                {/*                        setNumDiningModified(true);*/}
                {/*                    }}/>*/}
                {/*                </Col>*/}
                {/*            </Form.Group>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}

                {/*<div className="standardButtons">*/}
                {/*    <Button variant="primary" onClick={saveNumDining} disabled={!numDiningModified && !loadingNumDining}>Gem</Button>*/}
                {/*    <Button variant="warning" onClick={resetNumDining} disabled={!numDiningModified}>Fortryd</Button>*/}
                {/*</div>*/}

                {/*<hr />*/}
                {/*<h3>Ekstra målt vægt</h3>*/}
                {/*{!loadingBaseWeight &&*/}
                {/*    <div>*/}
                {/*        <i>*/}
                {/*            <p>Denne værdi vil blive fratrukket ALLE jeres målinger. Generelt vil den skulle være 0, da det*/}
                {/*                vil*/}
                {/*                give en korrekt måling af jeres madspild - så længe i har sikret at vægten er på 0 når der*/}
                {/*                intet*/}
                {/*                mad er i skraldspanden (Tryk TARE på vægten).</p>*/}
                {/*            <p>Men i det tilfælde at maden for eksempel bliver vejet ved at den bliver indsamlet ved bordene*/}
                {/*                i*/}
                {/*                en stor bøtte, og hele bøtten derefter bliver sat på vægten (som er på 0 inden bøtten bliver*/}
                {/*                sat på), vil man skulle indtaste en værdi i dette felt, da vægten af bøtten også vil blive*/}
                {/*                vejet*/}
                {/*                med. Så hvis bøtten for eksempel vejer 2 kilo, vil værdien i nedenstående felt skulle være 2*/}
                {/*                kg.*/}
                {/*                Men dette er KUN relevant hvis vægten er på 0 inden man vejer og at der vejes ikke-mad med*/}
                {/*                som en*/}
                {/*                del af vægten.*/}
                {/*            </p>*/}
                {/*        </i>*/}

                {/*        <div className={styles.FormArea}>*/}
                {/*            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">*/}
                {/*                <Form.Label column sm={3}>Ekstra målt vægt</Form.Label>*/}
                {/*                <Col sm={2}>*/}
                {/*                    <FormControl type="number" step={0.1} value={baseWeight} onChange={x => {*/}
                {/*                        setBaseWeight(extractNumericValue(x));*/}
                {/*                        setBaseWeightModified(true);*/}
                {/*                    }}/>*/}
                {/*                </Col>*/}
                {/*            </Form.Group>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}

                {/*<div className="standardButtons">*/}
                {/*    <Button variant="primary" onClick={saveBaseWeight} disabled={!baseWeightModified && !loadingBaseWeight}>Gem</Button>*/}
                {/*    <Button variant="warning" onClick={resetLimits} disabled={!baseWeightModified}>Fortryd</Button>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default SystemSettingsPage;
