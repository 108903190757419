import React, {FC, useEffect, useState} from 'react';
import {Meal, MealType} from "../../../../models/Meal";
import './MealCard.scss';
import {Col, Form, FormControl, Row} from "react-bootstrap";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePicker, {DateObject} from "react-multi-date-picker";
import StatefulEditableNumber from "../../../../sharedComponents/StatefulEditableNumber";

interface SimpleTimePickerProps {
    value: string,
    onChange: any
}

const SimpleTimePicker: FC<SimpleTimePickerProps> = (props) => {
    const [time, setTime] = useState<DateObject>();

    useEffect(() => {
        let t = new DateObject({ date: "01/01/20202 "+props.value, format: "MM/DD/YYYY HH:mm" });
        setTime(t);
    }, [props.value]);

    return (
        <DatePicker
            value={time}
            onChange={(x: DateObject) => props.onChange(x.format("HH:mm"))}
            disableDayPicker
            format="HH:mm"
            plugins={[
                <TimePicker hideSeconds={true}/>
            ]}
            inputClass="form-control timePicker"
        />
    );
}

interface MealCardProps {
    meal: Meal,
    onChange?: any;
    onDefaultNumDiningChanged?: any;
    // onDelete?: any;
    readOnly: boolean
}

const MealCard: FC<MealCardProps> = (props) => {
    const triggerChange = (key: keyof Meal, val: string | number, forceNumber=false) => {
        let m: any = Object.assign({}, props.meal);
        if (forceNumber && typeof val === 'string') {
            try {
                m[key] = Number.parseFloat(val);
            } catch (e) {
                console.error("Unable to parse number ", val, e);
                return;
            }
        } else {
            m[key] = val;
        }
        props.onChange(m as Meal);
    }

    const changeBaselineAvgWaste = (x: any) => {
        if (typeof x === 'string') {
            try {
                let num = Number.parseFloat(x);
                props.onDefaultNumDiningChanged(props.meal, num);
            } catch (e) {
                console.error("Unable to parse number x")
            }
        }
    }

    const formControlTriggerChange = (key: keyof Meal, val: any, forceNumber=false) => triggerChange(key, val, forceNumber);

    return (
        <div className="MealCard box">
            { !props.readOnly &&
                <>
                    <div className="Header">
                        <h3>{props.meal.name || "Ikke navngivet"} </h3>
                        {/*{ props.onDelete && <Button variant="outline-danger" onClick={() => props.onDelete(props.meal)}>Slet</Button> }*/}
                    </div>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={4} >Måltidsnavn</Form.Label>
                        <Col>
                            <FormControl type="text" required value={props.meal.name} onChange={(x: any) => formControlTriggerChange("name", x.target.value)}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={4} >Måltidstype</Form.Label>
                        <Col>
                            <span>{MealType[props.meal.mealType]}</span>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={4}>Default antal spisende</Form.Label>
                        <Col sm={2}>
                            <FormControl type="number" step={1} value={props.meal.defaultNumDining} onChange={(x: any) => formControlTriggerChange("defaultNumDining", x.target.value, true)}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={4}>Inkluder tømninger fra</Form.Label>
                        <Col sm={1} className="my-auto">
                            <SimpleTimePicker value={props.meal.startTime} onChange={(x: any) => triggerChange("startTime", x)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={4}>Inkluder tømninger til</Form.Label>
                        <Col sm={2} className="my-auto">
                            <SimpleTimePicker value={props.meal.endTime} onChange={(x: any) => triggerChange("endTime", x)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={4}>Gennemsnit spild/måltid/person (g)</Form.Label>
                        <Col sm={2}>
                            <FormControl type="number" step={0.1} value={(props.meal.baselineAvgWaste*1000).toFixed(1)} onChange={(x: any) => formControlTriggerChange("baselineAvgWaste", x.target.value/1000.0, true)}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Form.Label column sm={4}>Mål for måltid/måltid/person (g)</Form.Label>
                        <Col sm={2}>
                            <FormControl type="number" step={0.1} value={(props.meal.goalWaste*1000).toFixed(1)} onChange={(x: any) => formControlTriggerChange("goalWaste", x.target.value/1000.0, true)}/>
                        </Col>
                    </Form.Group>
                </>
            }
            { props.readOnly &&
                <>
                    <div className="readOnly">
                        <h3>{props.meal.name || "Ikke navngivet"} </h3>
                         <div className="emptyTimes">
                             <label>Tidsrum for tømninger:</label>
                             <span>kl {props.meal.startTime} - {props.meal.endTime}</span>
                        </div>
                        <div className="line">
                            <label>Gennemsnit spild:</label>
                            <span>{props.meal.baselineAvgWaste} kg</span>
                        </div>
                        <div className= "line">
                            <label>Mål for måltid:</label>
                            <span>{props.meal.goalWaste} kg</span>
                        </div>
                        <div className="line">
                            <label>Antal spisende:</label>
                            <span>
                                <StatefulEditableNumber value={props.meal.defaultNumDining} valueChanged={(newVal: number) => props.onDefaultNumDiningChanged(props.meal, newVal)} integerOnly={true} />
                            </span>
{/*<FormControl type="number" step={1} value={props.meal.defaultNumDining} onChange={(x: any) => formControlTriggerChange("defaultNumDining", x, true)}/>*/}
                        </div>
                    </div>
                </>
            }
        </div>
    )
};

export default MealCard;
