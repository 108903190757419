import React, {FC, useState} from "react";
import {AiOutlineCheck, AiOutlineClose, AiOutlineEdit} from "react-icons/ai";

interface StatefulEditableNumberProps {
    fixedPoint?: number
    value: number
    valueChanged: (val: number) => any;
    integerOnly?: boolean
    validate?: (val: number) => boolean
}

/***
 * Shows an editable number with an edit symbol next to it. Double clicking the number or clicking the edit symbol
 * will cause it to become a number input field with a cancel and save option. If the number is changed and
 * save is clicked the valueChanged function from props is called.
 */
const StatefulEditableNumber: FC<StatefulEditableNumberProps> = (props) => {
    const [currentValue, setCurrentValue] = useState<number>(props.value)
    const [isEditing, setIsEditing] = useState<boolean>(false)

    const startEditMode = () => { setIsEditing(true); }
    const endEditMode = () => { setIsEditing(false); }
    const cancel = () => { endEditMode(); setCurrentValue(props.value)}
    const save = () => { endEditMode(); props.valueChanged(currentValue); }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const num = event.target.value || "0"
        let parsed = 0;
        if (props.integerOnly) {
            parsed = Number.parseInt(num)
        } else {
            parsed = Number.parseFloat(num)
        }
        if (props.validate === undefined || props.validate(parsed)) {
            setCurrentValue(parsed)
        }
    }

    return (
        <span style={{marginLeft: "4px"}}>
            { !isEditing &&
                <span style={{cursor: "pointer"}} onClick={() => startEditMode()}>
                    {props.fixedPoint ? currentValue.toFixed(props.fixedPoint) : currentValue} <AiOutlineEdit />
                </span>
            }
            { isEditing &&
                <span>
                    <input value={currentValue}
                           type="number"
                           onChange={onChange}
                           onKeyDown={event => event.key === 'Enter' && save()}
                    />
                    <AiOutlineCheck onClick={save} className="clickable" style={{color: "lightgreen", fontWeight: "bold", fontSize: "16px", marginLeft: "4px"}}/>
                    <AiOutlineClose onClick={cancel} className="clickable" style={{color: "red", fontWeight: "bold", fontSize: "16px", marginLeft: "4px"}}/>
                </span>
            }
        </span>
    )
}

export default StatefulEditableNumber;
