import React, {FC, useEffect, useState} from 'react';
import styles from './LeftBox.module.scss';
import sadSmileyIcon from './sad_smiley.png';
import smileyIcon from './smiley.svg';
import flameIcon from './smallFlame.svg';
import {CurrentStatus} from "../../../models/CurrentStatus";
import {Stats} from "../../../models/Stats";

interface LeftBoxProps {
    currentStatus: CurrentStatus | undefined,
    stats: Stats | undefined
}

const LeftBox: FC<LeftBoxProps> = (props) => {
    const { currentStatus, stats } = props;
    const barTopPos: number = 92;
    const barBottomPos: number = 609;

    const [ dailyGoal, setDailyGoal ] = useState(0);
    const [ dailyPercent, setDailyPercent ] = useState(0);
    const [ goalPos, setGoalPos ] = useState(0);
    const [ gradientPosPercent, setGradientPosPercent ] = useState(0);
    const [ currentPos, setCurrentPos ] = useState(0);
    const [ totalWasteToday, setTotalWasteToday ] = useState(0);
    const [ inactive, setInactive ] = useState(true);

    useEffect(() => {
        let weight = currentStatus?.maximumStable || 0;
        let previous = currentStatus?.weightFromPrevMealsToday || 0;
        currentStatus?.currentMeal ? setTotalWasteToday(weight + previous) : setTotalWasteToday(previous);
    }, [currentStatus?.maximumStable, currentStatus?.weightFromPrevMealsToday]);

    useEffect(() => setInactive(!!currentStatus?.blacklistedMeal), [currentStatus?.blacklistedMeal]);

    useEffect(() => {
        setDailyGoal(stats?.dataPoints === 0 || stats?.today.goalKg === undefined ? 20 : stats.today.goalKg)
    }, [stats?.today.goalKg]);

    useEffect(() => {
        let dailyPercent = 100*totalWasteToday/(dailyGoal || 1);
        let goalPosPercent = dailyPercent > 100 ? 100 * (100 / dailyPercent) : 100;
        let gradientPosPercent = goalPosPercent < 100 ? 100-goalPosPercent : 10;
        let barRange = barBottomPos - barTopPos;
        let currentPos: number;
        let goalPos: number;

        if (dailyPercent < 86) {
            currentPos = barBottomPos - (barRange * dailyPercent/100)
            goalPos = barTopPos;
        } else if (dailyPercent < 100) {
            currentPos = barBottomPos - (barRange * 0.861);
            goalPos = barTopPos;
        } else if (dailyPercent < 116.1) {
            currentPos = barTopPos;
            goalPos = barBottomPos - (barRange * 0.861)
        } else {
            currentPos = barTopPos;
            goalPos = barBottomPos - barRange * (goalPosPercent/100);
        }

        setDailyPercent(dailyPercent);
        setGoalPos(goalPos);
        setGradientPosPercent(gradientPosPercent);
        setCurrentPos(currentPos);
    },[totalWasteToday, dailyGoal])


    return (
        <div className={styles.LeftBox}>
            <div className="header">Vores spild</div>
            <div className="barArea">
                <svg className="bar" viewBox="0 0 20 120">
                    <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse">
                        <stop offset={`${gradientPosPercent}%`} stopColor="#e77878" />
                        <stop offset={`${Math.min(gradientPosPercent+50, 100)}%`} stopColor="#78e7a0" />
                    </linearGradient>
                    <defs>
                        <filter id="filter4">
                            <feGaussianBlur stdDeviation="3" />
                        </filter>
                        <filter id="filter3">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="10.5" edgeMode="true" />
                        </filter>
                    </defs>
                    <path className="meterBackground" d="M 10 10 L 10 110 " fill='none'/>
                    {inactive === false && <path className="meter" style={ {strokeDasharray: `${Math.ceil(dailyPercent)}px, 200px` }} d="M 10 110 L 10 10" fill='none'/> }
                    {/*<path filter="url(#f1)" style={ {strokeDasharray: `${speedometerValue}, 198px` }} className="meter" d="M25,90 A40,40 0 1,1 80,90" fill='none' opacity="0.6"/>*/}
                </svg>
                {inactive === false && <div className="dailyGoalBox" style={{top: `${goalPos}px`}}>
                        <div className="circle">
                            <img src={flameIcon} alt=""/>
                        </div>
                        <div className="texts">
                            <span>Daglige grænse</span>
                            <h4>Under {dailyGoal.toFixed(1)} kg</h4>
                        </div>
                    </div>
                }
                {inactive === false && <div className="currentBox" style={{top: `${currentPos}px`}}>
                        <img src={dailyPercent <= 100 ? smileyIcon : sadSmileyIcon} alt=""/>
                        <div className="texts">
                            <span>I dag</span>
                            <h4>{totalWasteToday.toFixed(1)} kg</h4>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default LeftBox;
