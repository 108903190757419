import React, {FC, useEffect, useState} from 'react';
import styles from './StreakBox.module.scss';
import {Stats} from '../../../models/Stats';
import flame from './flame.svg';

interface StreakBoxProps {
    stats: Stats | undefined // TODO
};

const StreakBox: FC<StreakBoxProps> = (props) => {
    const [streakLength, setStreakLength] = useState<string | number>("?");

    useEffect(() => {
        setStreakLength(props.stats?.streaks.current === undefined ? "?" : props.stats.streaks.current);
    }, [props.stats?.streaks.current])

    return (
        <div className={styles.StreakBox}>
            <div className="flame">
                <img src={flame} alt="" />
            </div>
            <div className="texts">
                <h2 className="test">Streak</h2>
                <h1>{ streakLength } dag{ streakLength === 1 ? "" : "e" }</h1>
            </div>
        </div>
    );
}

export default StreakBox;
