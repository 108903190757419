import styles from "./EditUserPage.module.scss"
import React, {FC, useEffect, useState} from 'react';
import {CreateUserDto, User} from "../models/User";
import {Alert, Form, FormCheck, FormControl} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {v4 as uuidv4} from 'uuid';
import {createUser, updateUser} from "../superAdminApi";
import LogoHandler from "../components/LogoHandler/LogoHandler";

interface EditUserPageProps {
    user: User | undefined,
    onGotoUser: (user: User, refreshUsers: boolean) => any,
    onCancel: () => any
}

const EditUserPage: FC<EditUserPageProps> = (props) => {
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [user, setUser] = useState<User>({} as User)
    const [dashboardPassword, setDashboardPassword] = useState<string>("")
    const [adminPassword, setAdminPassword] = useState<string>("")
    const [isEditing, setIsEditing] = useState<boolean>()
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if (props.user) {
            setIsEditing(true);
            setUser({...props.user});
        } else {
            setIsEditing(false);
            const newUser: User = {
                id: "",
                email: "",
                userName: "A",
                userType: "efterskole",
                isActive: true,
                notificationsEmail: "",
                emailReportsEnabled: true,
                rpiKey: uuidv4()
            }
            setUser(newUser);
        }

    },[props.user])

    const save = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            setValidated(false);
            return;
        }
        setValidated(true);

        if (!isEditing) {
            let toSave = {...user} as CreateUserDto;
            toSave.admin_password = adminPassword;
            toSave.dashboard_password = dashboardPassword;
            createUser(toSave).then(result => {
                if (result.status > 203) {
                    setErrorMsg(`Kunne ikke oprette bruger.. Fejlkode ${result.status}`);
                    return;
                } else {
                    console.log(result);
                    props.onGotoUser(result.data as User, true)
                }
            });
        } else {
            updateUser(user).then(result => {
                if (result.status > 203) {
                    setErrorMsg(`Kunne ikke gemme bruger.. Fejlkode ${result.status}`);
                    return;
                } else {
                    props.onGotoUser(result.data as User, true)
                }
            })
        }
        event.stopPropagation();
    }

    const triggerChange = (key: keyof User, val: any) => setUser({ ...user, [key]: val} as User);

    const formControlTriggerChange = (key: keyof User, val: any ) => triggerChange(key, val.target.value);

    const triggerBooleanChange = (key: keyof User, val: any ) => triggerChange(key, val.target.checked);

    return (
        <div className={styles.editUserPage}>
            <h1>{isEditing ? `Rediger ${user?.userName}` : "Ny skole"}</h1>
            <LogoHandler userId={user?.id} readOnly={!isEditing} isAlt={false}></LogoHandler>
            <LogoHandler userId={user?.id} readOnly={!isEditing} isAlt={true}></LogoHandler>
            <Form validated={validated} onSubmit={save}>
            {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                <table style={{width:700}}>
                    <tbody>
                        { isEditing && (
                            <tr>
                                <th>Id</th>
                                <td>{user?.id}</td>
                            </tr>
                        )}
                        <tr>
                            <th>Skole Navn</th>
                            <td><FormControl type="text" required value={user.userName} placeholder="Indtast navnet på skolen"
                                             onChange={(x: any) => formControlTriggerChange("userName", x)}/></td>
                        </tr>
                        <tr>
                            <th>Brugernavn</th>
                            <td><FormControl type="text" required value={user?.email} placeholder="Indtast login til skolen"
                                             onChange={(x: any) => formControlTriggerChange("email", x)}/></td>
                        </tr>
                        { !isEditing && (
                            <tr>
                                <th>Dashboard password</th>
                                <td><FormControl type="password" required  value={dashboardPassword} placeholder="Password til at logge på skærm"
                                                 onChange={(x: any) => setDashboardPassword(x.target.value)}/></td>
                            </tr>
                        )}
                        { !isEditing && (
                            <tr>
                                <th>Admin password</th>
                                <td><FormControl type="password" required value={adminPassword} placeholder="Password til at logge på som skole admin"
                                                 onChange={(x: any) => setAdminPassword(x.target.value)}/></td>
                            </tr>
                        )}
                        <tr>
                            <th>Bruger Aktiv</th>
                            <td><FormCheck type="switch" checked={user.isActive}
                                           onChange={(x: any) => triggerBooleanChange("isActive", x)} /></td>
                        </tr>
                        <tr>
                            <th>Send e-mail rapporter</th>
                            <td><FormCheck type="switch" checked={user.emailReportsEnabled}
                                           onChange={(x: any) => triggerBooleanChange("emailReportsEnabled", x)} /></td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td><FormControl type="email" required value={user?.notificationsEmail}
                                             onChange={(x: any) => formControlTriggerChange("notificationsEmail", x)}/></td>
                        </tr>
                        <tr>
                            <th>RPI key</th>
                            <td>{user?.rpiKey}</td>
                        </tr>
                    </tbody>
                </table>
                <div className={styles.buttons}>
                    <Button type="submit" variant="success">{isEditing ? "Gem ændringer" : "Tilføj skole" }</Button>
                    <Button variant="secondary" onClick={props.onCancel}>Fortryd</Button>
                </div>

            </Form>
        </div>
    );
}

export default EditUserPage;
