import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import {TicksConfiguration} from '../models/TickModel';
import {MealList, MealType} from "../models/Meal";
import createPersistentStorage from "../persistent-storage";
import {react_app_backend_url} from "../globalApi";
import {BlacklistEntry} from "../models/BlacklistEntry";
import {recursiveToCamel} from "../dashboard/dashboardApi";
import {Trace} from "../superAdmin/models/Trace";
import {WasteEntryDto} from "../superAdmin/models/WasteEntryDto";
import {WasteEntryUpdateDto} from "./dtos/WeightEntryUpdateDto";

const accessTokenStorage = createPersistentStorage<string>("admin_access_token");

const orgCreate = axios.create;
axios.create = function (config?: AxiosRequestConfig): AxiosInstance {

    const result = orgCreate.call(this, config);
    (axios.interceptors.request as any).handlers.forEach((handler: any) => {
        result.interceptors.request.use(handler.fulfilled, handler.rejected);
    });
    (axios.interceptors.response as any).handlers.forEach((handler: any) => {
        result.interceptors.response.use(handler.fulfilled, handler.rejected);
    });

    return result;
}

const adminAxios = axios.create();

adminAxios.interceptors.request.use(function (config) {
    if (config?.headers) {
        const token = accessTokenStorage.read();
        if (token !== undefined) {
            config.headers.Authorization = 'Bearer ' + accessTokenStorage.read();
        }
    }

    return config;
});

adminAxios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response?.status === 403 || error.response?.status === 401) {
            console.error("Authorization error: " + error.response.status)
            window.location.href = "/admin/login";
        } else if (error.message) {
            console.error(`Error: ${error.message}`)
        }
    });

const backendUrl = react_app_backend_url + "";

/***
 * Returns a list of the added IDs
 * @param entries
 */
export function addBlacklistEntries(entries: BlacklistEntry[]) : Promise<{ status: number, data: { [key: number]: string }}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.put(backendUrl + "admin/blacklist", entries);
}

export function getBlacklist() : Promise<{ status: number, data: BlacklistEntry[] }> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + "admin/blacklist", );
}

export function removeBlacklistEntries(ids: string[]) : Promise<{ status: number, data: { [key: number]: string }}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.put(backendUrl + "admin/blacklist/delete", ids);
}

export function getHistoricData() : Promise<any> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + "admin/historicData", );
}

export function updateStatsStartDate(from: string) : Promise<undefined> {
    const client = applyCaseMiddleware(adminAxios);
    return client.put(backendUrl + "admin/statsFromTime", { statsCalculatedFrom: from }, );
}

export function getStatsStartDate() : Promise<any> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + "admin/statsFromTime", );
}

export function getMealsConfiguration() : Promise<{ data: { meals: MealList, status: number }}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + "admin/mealsConfiguration", );
}

export function updateMealsConfiguration(mealsConfiguration: MealList) : Promise<any> {
    const client = applyCaseMiddleware(adminAxios);
    return client.put(backendUrl + "admin/mealsConfiguration", { meals: mealsConfiguration }, );
}

export function getTicksConfiguration() : Promise<any> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + "admin/ticksConfiguration", );
}

export function updateTicksConfiguration(ticksConf: TicksConfiguration) : Promise<any> {
    const client = applyCaseMiddleware(adminAxios);
    return client.put(backendUrl + "admin/ticksConfiguration", ticksConf);
}
//
// export function getMainGoal() : Promise<any> {
//     const client = applyCaseMiddleware(adminAxios);
//     return client.get(backendUrl + "admin/mainGoal", );
// }
//
// export function updateMainGoal(mainGoal: number) : Promise<any> {
//     const client = applyCaseMiddleware(adminAxios);
//     return client.put(backendUrl + "admin/mainGoal", { mainGoal: mainGoal }, );
// }

// export function getBaseWeight() : Promise<any> {
//     const client = applyCaseMiddleware(adminAxios);
//     return client.get(backendUrl + "admin/baseWeight", );
// }
//
//
// export function updateBaseWeight(baseWeight: number) : Promise<any> {
//     const client = applyCaseMiddleware(adminAxios);
//     return client.put(backendUrl + "admin/baseWeight", { baseWeight: baseWeight });
// }

// export function getNumDining() : Promise<any> {
//     const client = applyCaseMiddleware(adminAxios);
//     return client.get(backendUrl + "admin/numDining", );
// }
//
// export function updateNumDining(baseWeight: number) : Promise<any> {
//     const client = applyCaseMiddleware(adminAxios);
//     return client.put(backendUrl + "admin/numDining", { numDining: baseWeight });
// }

export function updateDefaultNumDining(mealType: keyof typeof MealType, newDefaultNumDining: number) : Promise<any> {
    const client = applyCaseMiddleware(adminAxios);
    return client.put(backendUrl + "admin/default-num-dining", { mealType: mealType, defaultNumDining: newDefaultNumDining });
}

export function getTrace() : Promise<{data: Trace[], status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + `admin/get-trace`)
}

export function getZoomLevel() : Promise<any> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + "admin/zoomLevel", );
}

export function updateZoomLevel(zoomLevel: number) : Promise<any> {
    const client = applyCaseMiddleware(adminAxios);
    return client.put(backendUrl + "admin/zoomLevel", { zoomLevel: zoomLevel });
}

export function getUsers() : Promise<any> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + "admin/users")
}

export function getCampaigns(): Promise<any> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + "admin/campaigns", {
        transformResponse: (res) => {
            // Do your own parsing here if needed ie JSON.parse(res);
            const parsed = JSON.parse(res)
            const camelized = recursiveToCamel(parsed)
            return camelized;
        }}   );

    // return client.get(backendUrl + "admin/campaigns")
}

export function getUserInfo(): Promise<any> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + "admin/userInfo")
}

export function getLogs(): Promise<any> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + "admin/logs")
}

export function getLogo() : Promise<any> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + "admin/logo");
}

export function getWasteEntries() : Promise<{data: WasteEntryDto[], status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + `admin/waste-entries`)
}

export function updateWasteEntry(entryId: string, update: WasteEntryUpdateDto) : Promise<{data: any, status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.put(backendUrl + `admin/waste-entries/${entryId}`, update)
}

export function deleteWasteEntry(entryId: string) : Promise<{data: any, status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.delete(backendUrl + `admin/waste-entries/${entryId}`)
}

export function postWasteEntry(userId: string, weight: string, date: string, mealType: string) : Promise<{data: any, status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.post(backendUrl + `admin/waste-entries?timestamp=${date}&weight=${weight}&meal_type=${mealType}`)
}

