import React, {FC, useEffect, useState} from 'react';
import "./WasteHistoryTable.scss"
import {WasteEntryDto} from "../../superAdmin/models/WasteEntryDto";
import {FaTimesCircle} from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import {prettyPrintDateOnly} from "../../utils/dateUtils";
import DatePicker, {DateObject} from "react-multi-date-picker";
import Alert from "react-bootstrap/Alert";
import {
    deleteWasteEntry,
    getWasteEntries,
    postWasteEntry,
    updateWasteEntry
} from "../adminApi";
import Select from "react-select";
import {MealTypes} from "../StatsPage/models";
import StatefulEditableNumber from "../../sharedComponents/StatefulEditableNumber";
import {WasteEntryUpdateDto} from "../dtos/WeightEntryUpdateDto";
import {MealNames} from "../../models/Meal";

interface WasteHistoryTableProps {
    userId: string
}

const WasteHistoryTable: FC<WasteHistoryTableProps> = (props) => {
    const [errorMsg, setErrorMsg] = useState<string>();
    const [wasteHistory, setWasteHistory] = useState<WasteEntryDto[]>([]);
    const [newEntryTime, setNewEntryDate] = useState(new Date());
    const [newEntryWeight, setNewEntryWeight] = useState<string>("0")
    const [mealTypeOptions] = useState(Object.keys(MealTypes).map((x: string) => { return { value: x.toString(), label: x.toString()}}))
    const [newEntryMealType, setNewEntryMealType] = useState(mealTypeOptions[0])
    const [hoveredEntry, setHoveredEntry] = useState<WasteEntryDto>()
    const [wasteEntryToDelete, setWasteEntryToDelete] = useState<WasteEntryDto>()
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    useEffect(() => {
        if (props.userId) {
            updateLatestWasteHistory()
        }
    },[props.userId])

    const updateLatestWasteHistory = () => {
        getWasteEntries().then(payload => {
            setWasteHistory(payload.data)
        });
    }

    const newEntryDateChanged = (x: DateObject | DateObject[] | null) => {
        if (x instanceof DateObject) {
            setNewEntryDate(x.toDate());
        } else {
            setErrorMsg("Fejl i ny dato... afbryder at gemme");
            setNewEntryDate(new Date())
        }
    }

    const saveNewEntry = () => {
        setErrorMsg(undefined)
        if (newEntryWeight !== undefined) {
            let v = newEntryWeight.replace(',','.');
            let n = Number(v)
            if (Number.isNaN(n)) {
                setErrorMsg("Kan ikke gemme vægt da det ikke er et gyldigt tal")
                return;
            }

            postWasteEntry(props.userId, ""+n, newEntryTime.toISOString(), newEntryMealType.value).then(
                success => {
                    updateLatestWasteHistory()
                },
                errorMsg => {
                    setErrorMsg(errorMsg?.message || "Unkown error occurred");
                    console.log(errorMsg); })
        }
    }

    const updateWeightEntry = (wasteEntry: WasteEntryDto, weight?: number, numDining?: number, chickenLegs?: boolean) => {
        let update: WasteEntryUpdateDto = {
            id: wasteEntry.id,
            originalWeight: weight === undefined ? wasteEntry.originalWeight : weight,
            numDining: numDining || wasteEntry.numDining,
            chickenLegs: chickenLegs === undefined ? !!wasteEntry.chickenLegs : chickenLegs
        }
        updateWasteEntry(wasteEntry.id, update).then(x => updateLatestWasteHistory())
    }

    const updateWeight = (wasteEntry: WasteEntryDto, weight: number) => updateWeightEntry(wasteEntry, weight, undefined, undefined);

    const validateNumDining = (numDining: number) => {
        if (numDining === 0) {
            alert("Antal spisende må ikke være 0. Hvis måltidet ikke skal registreres skal det slettes istedet.");
            return false;
        }
        return true;
    }

    const updateNumDining = (wasteEntry: WasteEntryDto, numDining: number) => updateWeightEntry(wasteEntry, undefined, numDining, undefined);

    const toggleChickenLegs = (wasteEntry: WasteEntryDto, chickenLegs: boolean) => updateWeightEntry(wasteEntry, undefined, undefined, chickenLegs);

    const triggerDeleteWasteEntry = (wasteEntry: WasteEntryDto | undefined) => {
        if (wasteEntry) {
            deleteWasteEntry(wasteEntry.id).then(x => {
                updateLatestWasteHistory()
            })
        }
        setShowDeleteConfirmation(false)
        setWasteEntryToDelete(undefined)
    }

    return (
        <div>
            <div className="addArea">
                <table>
                    <tbody>
                    <tr>
                        <td>Tidspunkt</td>
                        <td><DatePicker
                                multiple={false}
                                value={newEntryTime}
                                onChange={newEntryDateChanged}
                                // plugins={[<AnalogTimePicker />]}
                                format="D MMM YYYY"
                        ></DatePicker></td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td>
                            <Select
                                className="select"
                                onChange={x => setNewEntryMealType(x as { label: string, value: string })}
                                defaultValue={newEntryMealType}
                                options={mealTypeOptions}
                                />
                        </td>
                    </tr>
                    <tr>
                        <td>Vægt</td>
                        <td>
                            <input value={newEntryWeight} onChange={event => setNewEntryWeight(event.target.value)} />
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="saveNewArea">
                    <Button variant="success" onClick={saveNewEntry}>Gem ny tømning</Button>

                </div>
            </div>
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Slet måltid?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { wasteEntryToDelete &&
                        <span>Vil du virkelig slette {MealNames[wasteEntryToDelete?.mealType]} fra {prettyPrintDateOnly(wasteEntryToDelete.time)}?. Dette kan ikke fortrydes</span> }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        Fortryd
                    </Button>
                    <Button variant="primary" onClick={() => triggerDeleteWasteEntry(wasteEntryToDelete)}>Slet måltidet</Button>
                </Modal.Footer>
            </Modal>
            { errorMsg && <Alert variant="danger">{errorMsg}</Alert> }
            <table className="wasteTable">
                <tbody>
                    <tr>
                        <th>Tidspunkt</th>
                        <th>Type</th>
                        <th>Vægt</th>
                        <th>Antal spisende</th>
                        <th>Offset</th>
                        <th>Kyllingeben</th>
                    </tr>
                    { wasteHistory.map(wasteEntry =>
                        <tr key={wasteEntry.time} onMouseEnter={() => setHoveredEntry(wasteEntry)} onMouseLeave={() => setHoveredEntry(undefined)}>
                            <td>{prettyPrintDateOnly(wasteEntry.time)}</td>
                            <td>{MealNames[wasteEntry.mealType]}</td>
                            <td>{wasteEntry.chickenLegs && "*"}<StatefulEditableNumber fixedPoint={2} value={wasteEntry.originalWeight} valueChanged={(x: number) => updateWeight(wasteEntry, x)} /></td>
                            <td><StatefulEditableNumber validate={validateNumDining} integerOnly={true} value={wasteEntry.numDining} valueChanged={(x: number) => updateNumDining(wasteEntry, x)} /></td>
                            <td>{wasteEntry.offsetUsed ? wasteEntry.offsetUsed.toFixed(2) : "-"}</td>
                            <td><input type="checkbox" checked={wasteEntry.chickenLegs}
                                   onChange={x => toggleChickenLegs(wasteEntry, x.target.checked)}/>
                                { wasteEntry.chickenLegs && ` (* ${wasteEntry.weight} kg uden kyllingeben)`}
                            </td>
                            <td className="deleteCell">
                                {hoveredEntry && hoveredEntry.id === wasteEntry.id && <FaTimesCircle className="clickable deleteSymbol" onClick={() => { setShowDeleteConfirmation(true); setWasteEntryToDelete(hoveredEntry); }} /> }
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default WasteHistoryTable;
