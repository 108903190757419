import React, {FC, useEffect, useState} from 'react';
import './SuperAdminLogsPage.scss';
import Alert from "react-bootstrap/Alert";
import dayjs from "dayjs";
import {getLogs} from "../superAdminApi";
import {User} from "../../superAdmin/models/User";
import {Log} from "../../models/Log";
import {getDaysAgoDate, localPrettyPrint} from "../../utils/dateUtils";
import DatePicker, {DateObject} from "react-multi-date-picker";
import {Form} from "react-bootstrap";
import Select from "react-select";

const locale = require('dayjs/locale/da')
dayjs.locale(locale);


interface SuperAdminLogsPageProps {
    users: User[]
}

const SuperAdminLogsPage: FC<SuperAdminLogsPageProps> = (props) => {
    const [ errorMsg, setErrorMsg ] = useState<string>("");
    const [ fromDateObject, setFromDateObject ] = useState<DateObject>(new DateObject(getDaysAgoDate(7)));
    const [ userOptions, setUserOptions ] = useState<any[]>([]);
    const [ userFilter, setUserFilter ] = useState<string[]>([])
    const [ severityFilter, setSeverityFilter ] = useState<number>(0)
    const [ userMap, setUserMap ] = useState<{[key: string]: string}>({});
    const [ allLogs, setAllLogs ] = useState<Log[]>([])
    const [ filteredLogs, setFilteredLogs ] = useState<Log[]>([])

    useEffect(() => updateLogs(fromDateObject), [fromDateObject]);

    useEffect(() => {
        if (!props.users) {
            setUserMap({});
            setUserOptions([]);
        } else {
            setUserOptions(props.users.map(u => { return { value: u.id, label: u.userName }}))
            let newMap: {[key: string]: string} = {};
            for (let u of props.users) {
                newMap[u.id] = u.userName;
            }
            setUserMap(newMap)
        }
    }, [props.users]);

    useEffect(() => {
        if (allLogs.length) {
            let flogs: Log[] = [];
            if (userFilter.length === 0 || userFilter.length === userOptions.length) {
                flogs = allLogs;
            } else {
                flogs = allLogs.filter(l => userFilter.includes(l.userId));
            }
            flogs = flogs.filter(l => severityFilter <= l.logLevel);

            setFilteredLogs(flogs);
        }
    }, [allLogs, userFilter, severityFilter]);

    const updateLogs = (fromDateObject: DateObject) => {
        let mounted = true;

        getLogs(fromDateObject.format("YYYY-MM-DD")).then((res: any) => {
            if (mounted) {
                if (res && res.status === 200) {
                    let logs = res.data as Log[];
                    setAllLogs(logs)

                } else {
                    setErrorMsg("Kunne ikke hente logs. Prøv at loade siden igen");
                }
            }
        })
        return () => { mounted = false };
    }

    const classFromSeverity = (logEntry: Log) => {
        switch (logEntry.logLevel) {
            case 10:
            case 20:
                return "normal"
            case 30:
                return "yellow"
            case 40:
            case 50:
                return "red"
            default:
                return "normal"
        }
    }

    const nameFromSeverity = (logLevel: number) => {
        switch (logLevel) {
            case 10:
                return "Trace"
            case 20:
                return "Info"
            case 30:
                return "Advarsel"
            case 40:
                return "Fejl"
            case 50:
                return "Kritisk"
            default:
                return "Ukendt"
        }
    }

    const filterOptions: any[] = [10,20,30,40,50].map(x => { return { value: x, label: nameFromSeverity(x)}})

    // const userFilterChanged = (id: string[]) => console.log(id)
    return (
      <div className="logsPage">
        <div className="adminFrame">
            <h1 className="pageHeader">Logs</h1>
            { errorMsg && <Alert variant="danger">{errorMsg}</Alert> }
            <div className="box">
                <div className="controls">
                    <div className="fromFilter">
                        <Form.Label htmlFor="fromDate">Fra: </Form.Label>
                        <DatePicker value={fromDateObject} onChange={x => {
                            if (x instanceof DateObject) {
                                setFromDateObject(x)
                            }
                        }} />
                    </div>
                    <div className="userFilter dropdownFilter">
                        <div className="label">Bruger filter:</div>
                        <div className="dropdown">
                            <Select
                                onChange={x => setUserFilter(x.map((y: any) => y.value))}
                                defaultValue={userFilter}
                                options={userOptions}
                                // onBlur={triggerTextUpdated}
                                isSearchable={true}
                                isMulti={true}
                                isClearable={true}
                            />
                        </div>
                    </div>
                    <div className="severityFilter dropdownFilter">
                        <div className="label">Type filter:</div>
                        <div className="dropdown">
                            <Select
                                onChange={(x: any) => setSeverityFilter(x?.value || 0)}
                                defaultValue={severityFilter}
                                options={filterOptions}
                                isSearchable={false}
                                isMulti={false}
                                isClearable={false}
                            />
                        </div>
                    </div>
                </div>
                <table className="logEntries">
                    <tbody>
                    <tr><th>Tidspunkt</th><th>User</th><th>Type</th><th>Besked</th></tr>
                        { filteredLogs.map((log, index) => (
                            <tr key={index} className={classFromSeverity(log)}>
                                <td>{localPrettyPrint(log.timestamp)}</td>
                                <td>{userMap[log.userId]}</td>
                                <td>{nameFromSeverity(log.logLevel)}</td>
                                <td>{log.message}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    );
}

export default SuperAdminLogsPage;
