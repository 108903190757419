import React, {FC, useEffect, useState} from 'react';
import styles from './MealsPage.module.scss';
import Alert from "react-bootstrap/Alert";
import {Meal, MealList} from "../../../models/Meal";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {getMealsConfiguration, updateDefaultNumDining, updateMealsConfiguration} from "../../adminApi";
import MealCard from "./MealCard/MealCard";
import Button from "react-bootstrap/Button";
import {Col, Form, FormControl, Row} from "react-bootstrap";

interface MealsPageProps {
    superAdminView: boolean;
}

const MealsPage: FC<MealsPageProps> = (props) => {
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [originalMeals, setOriginalMeals] = useState<Meal[]>([]);
    const [currentMeals, setCurrentMeals] = useState<Meal[]>([]);
    const [modified, setModified] = useState<boolean>(false);
    // const [numDining, setNumDining] = useState<number>(0);
    // const [originalNumDining, setOriginalNumDining] = useState<number>(0);
    // const [loadingNumDining, setLoadingNumDining] = useState<boolean>(true);
    // const [numDiningModified, setNumDiningModified] = useState<boolean>(false);

    useEffect(() => {
        let mounted = true;
        getMealsConfiguration().then((res: any) => {
            console.log("kklssdfkj")
            if (mounted) {
                if (res && res.status === 200) {
                    let meals = res.data?.meals as MealList;
                    if (!meals) {
                        setErrorMsg("Fejl i at hente måltids konfigurationen. Ugyldig data returneret. Load siden igen");
                    } else {
                        setOriginalMeals(mealsToArray(meals));
                        setCurrentMeals(mealsToArray(meals));
                        console.log(mealsToArray(meals));
                    }
                } else {
                    setErrorMsg("Kunne ikke hente grænseværdier. Load siden igen");
                }
            }
        })

        // getNumDining().then((res: any) => {
        //     if (mounted) {
        //         if (res && res.status === 200) {
        //             console.log(res)
        //             var bw = res.data?.numDining;
        //             if (bw === undefined) {
        //                 setErrorMsg("Fejl i at hente værdier. Ugyldig data returneret. Load siden igen");
        //             } else {
        //                 setOriginalNumDining(bw);
        //                 setNumDining(bw);
        //                 setLoadingNumDining(false);
        //             }
        //         } else {
        //             setErrorMsg("Kunne ikke hente værdien for ekstra vægt. Load siden igen");
        //         }
        //     }
        // })

        return () => { mounted = false };
    }, []);

    // const addNewMeal = () => {
    //     let newMeal: Meal = {
    //         name: "",
    //         baselineAvgWaste: 0,
    //         goalWaste: 0,
    //         index: currentMeals.length,
    //         startTime: "00:00",
    //         endTime: "01:00"
    //     };
    //     setCurrentMeals((prev: Meal[]) => prev.concat(newMeal));
    //     setModified(true);
    // }

    const mealChanged = (meal: Meal) => {
        console.log(meal);
        setModified(true);
        setCurrentMeals(prev => updateIndexes(prev.map(x => x.index === meal.index ? meal : x)));
    }

    // const mealDeleted = (meal: Meal) => {
    //     setCurrentMeals((prev: Meal[]) => {
    //         return updateIndexes(prev.filter((x: Meal) => {
    //             return x.index !== meal.index
    //         }));
    //     });
    //     setModified(true);
    //
    // }

    const saveClicked = async () => {
        let output: MealList = {};
        // validate:
        for (let i=0; i<currentMeals.length; i++) {
            let meal = currentMeals[i];
            if (meal.name.length < 2) {
                setErrorMsg("Navn på måltid skal være over 2 bogstaver");
                return;
            }
            if (meal.startTime > meal.endTime) {
                setErrorMsg("Fra-tidspunkt for tømning skal være mindre end til-tidspunkt");
                return;
            }
            let keyName = meal.mealType;
            output[keyName] = meal;
        }

        // then save
        await updateMealsConfiguration(output);
        setCurrentMeals(prev=> updateIndexes(prev));
        setModified(false);
        setErrorMsg("");
    }

    const resetClicked = () => {
        setModified(false);
        setCurrentMeals(originalMeals);
    }

    const mealsToArray = (mealList: MealList): Meal[] => {
        let returnArr: Meal[] = [];
        for (const [, val] of Object.entries(mealList)) {
            returnArr.splice(val.index, 0, val);
        }
        return returnArr;
    }

    // a little function to help us with reordering the result
    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const updateIndexes = (meals: Meal[]): Meal[] => {
        return meals.map((x, i) => Object.assign(x, { index: i}))
    };

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        let items = reorder(
            currentMeals,
            result.source.index,
            result.destination.index
        ) as Meal[];

        updateIndexes(items);
        setCurrentMeals(items);
    }

    const onDefaultNumDiningChanged = async (meal: Meal, newNumDining: number) => {
        console.log(meal, newNumDining)
        await updateDefaultNumDining(meal.mealType, newNumDining)
    }

    return (
        <div className={styles.MealsPage}>
            <div className="adminFrame">
                <h1>Måltider opsætning</h1>
                <hr/>

                {/*<h3>Antal spisende</h3>*/}
                {/*{!loadingNumDining &&*/}
                {/*    <div>*/}
                {/*        <i>*/}
                {/*            <p>Antal spisende som vil blive registreret sammen med hvert måltid. Denne værdi vil*/}
                {/*                IKKE påvirke tidligere målte tømninger - den påvirker kun fremtidige målinger*/}
                {/*            </p>*/}
                {/*        </i>*/}

                {/*        <div className={styles.FormArea}>*/}
                {/*            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">*/}
                {/*                <Form.Label column sm={3}>Antal spisende</Form.Label>*/}
                {/*                <Col sm={2}>*/}
                {/*                    <FormControl type="number" step={1} value={numDining} onChange={x => {*/}
                {/*                        setNumDining(extractNumericValue(x));*/}
                {/*                        setNumDiningModified(true);*/}
                {/*                    }}/>*/}
                {/*                </Col>*/}
                {/*            </Form.Group>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}

                {/*<div className="standardButtons">*/}
                {/*    <Button variant="primary" onClick={saveNumDining} disabled={!numDiningModified && !loadingNumDining}>Gem</Button>*/}
                {/*    <Button variant="warning" onClick={resetNumDining} disabled={!numDiningModified}>Fortryd</Button>*/}
                {/*</div>*/}
                {/*<hr/>*/}

                <h3>Måltider</h3>
                <i>Opsætning af hvilke måltider der er på en dag, hvornår på dagen de er, samt info omkring mål og gennemsnit for et givent måltid.
                    Dette har indvirkning på hvilket måltid en givet tømning bliver registreret, samt hvad der vises på skærmen ift. måltidsnavn, mål, osv. </i>
                { errorMsg && <Alert variant="danger">{errorMsg}</Alert> }
                <hr />
                { props.superAdminView &&
                    <>
                        {/*<Button variant="outline-primary" className="float-right" onClick={addNewMeal}>Tilføj Måltid</Button>*/}
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {currentMeals.map((item, index) => (
                                            <Draggable key={item.index} draggableId={`${item.index}`} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <MealCard meal={item} readOnly={false} onChange={mealChanged} onDefaultNumDiningChanged={onDefaultNumDiningChanged}/>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <div className="standardButtons">
                            <Button variant="primary" onClick={saveClicked} disabled={!modified}>Gem</Button>
                            <Button variant="warning" onClick={resetClicked} disabled={!modified}>Fortryd</Button>
                        </div>
                    </>
                }
                { !props.superAdminView &&
                    <div className="cards">
                        { currentMeals.map(item => <MealCard meal={item} key={item.index} readOnly={true} onDefaultNumDiningChanged={onDefaultNumDiningChanged} />) }
                    </div>
                }
            </div>
        </div>
    );
}

export default MealsPage;
