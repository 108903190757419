import React, {FC, useEffect, useState} from 'react';
import dayjs from "dayjs";
import './statsControl.scss';
import '../../../sharedAdmin.scss';
import "dayjs/plugin/weekday";
import {PeriodPreset, Resolution, StatsConfiguration, Unit} from "../models";
import DatePicker, {DateObject} from "react-multi-date-picker";
import locale from 'dayjs/locale/da'
import ButtonGroup from "./ButtonGroup"; // import plugin

// var locale = require('dayjs/locale/da')
var weekday = require('dayjs/plugin/weekday')
dayjs.locale(locale);
dayjs.extend(weekday)

interface StatsControlsProps {
    onChange: (conf: StatsConfiguration) => any,
    statsConfig: StatsConfiguration | undefined
}

interface Period {
    from: DateObject,
    to: DateObject
}


const StatsControls: FC<StatsControlsProps> = (props) => {
    const [ currentPeriod, setCurrentPeriod ] = useState<Period>({ from: new DateObject(), to: new DateObject() });


    useEffect(() => {
        if (props.statsConfig === undefined) {
            // console.log("Resetting stats config");
            resetStatsConfig()
        }
    }, [])

    // useEffect(() => {
    //     console.log("Statsconfg", props.statsConfig)
    // }, [props.statsConfig])

    useEffect(() => {
        if (props.statsConfig?.from && props.statsConfig?.to) {
            setCurrentPeriod({
                from: new DateObject(props.statsConfig.from),
                to: new DateObject(props.statsConfig.to)
            });
        }
    }, [props.statsConfig?.from, props.statsConfig?.to])

    const resetStatsConfig = () => periodPresetChanged(PeriodPreset.currentWeek, {
        unit: Unit.Absolute,
        resolution: Resolution.day
    } as StatsConfiguration);

    const unitChanged = (x: Unit) => props.onChange({...props.statsConfig, unit: x } as StatsConfiguration);
    const resolutionChanged = (x: any) => console.log(x)

    const customDateChanged = (from: Date, to: Date) => {
        props.onChange({
            ...props.statsConfig,
            to: to,
            from: from
        } as StatsConfiguration);
    }

    const fromChanged = (x: any) => {
        customDateChanged(x.toDate(), props.statsConfig?.to || new Date());
    }

    const toChanged = (x: any) => {
        customDateChanged(props.statsConfig?.from || new Date(), x.toDate());
    }

    const periodPresetChanged = (x: PeriodPreset, prevStatsConfig = props.statsConfig) => {
        const newConf = {
            ...prevStatsConfig,
            periodPreset: x,
            to: new Date(),
        } as StatsConfiguration;
        if (x === PeriodPreset.currentWeek) {
            let s = dayjs();
            newConf.from = dayjs().weekday(1).toDate();
        } else if (x === PeriodPreset.currentMonth) {
            newConf.from = dayjs().date(1).toDate();
        } else if (x === PeriodPreset.currentYear) {
            newConf.from = (dayjs().month() < 6 ? dayjs().subtract(1, 'year') : dayjs()).month(7).date(1).toDate();
        } else {
        }
        props.onChange(newConf);
    }


    return (
        <div className="statsControls">
            <div className="line">
                <label className="periodHeader">Periode:</label>
                <div className="periodArea">
                    <ButtonGroup selected={props?.statsConfig?.periodPreset} onClick={x => periodPresetChanged(x)} opts={Object.values(PeriodPreset)} />
                    <div className={`pickers ${props.statsConfig?.periodPreset !== PeriodPreset.custom ? "disabled" : ""}`}>
                        <label className="fromLbl">Fra</label>
                        <DatePicker disabled={props.statsConfig?.periodPreset !== PeriodPreset.custom} value={currentPeriod.from} onChange={fromChanged} inputClass="datePicker"/>
                        <label className="toLbl">Til</label>
                        <DatePicker disabled={props.statsConfig?.periodPreset !== PeriodPreset.custom} value={currentPeriod.to} onChange={toChanged} inputClass="datePicker" />
                    </div>
                </div>
            </div>
            <div className="line">
                <label className="lbl">Enhed:</label>
                <ButtonGroup selected={props.statsConfig?.unit} onClick={x => unitChanged(x)} opts={Object.values(Unit)} />
            </div>
        </div>
    )
}

export default StatsControls;
