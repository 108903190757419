import React, {useEffect, useState} from 'react';
import {Menu, MenuItem, Sidebar, SubMenu} from "react-pro-sidebar";
import {FaCalendarAlt, FaCalendarTimes, FaWeight} from 'react-icons/fa';
import {GiKnifeFork} from 'react-icons/gi';
import {FiLogOut, FiSettings} from "react-icons/fi";
import {BsChatText, BsFillTrashFill, BsLayoutTextSidebarReverse} from 'react-icons/bs'
import {AiFillEye, AiFillEyeInvisible, AiFillStar, AiOutlineBarChart, AiOutlineDashboard} from "react-icons/ai";

import '../dashboard/App.scss';
import './AdminApp.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useCookies} from "react-cookie";
import DatesPage from "./SettingsPages/DatesPage/DatesPage";
import MealsPage from "./SettingsPages/MealsPage/MealsPage";
import SystemSettingsPage from "./SettingsPages/SystemSettingsPage/SystemSettingsPage";
import {useNavigate} from "react-router-dom";
import TickEditorPage from "./SettingsPages/TickEditorPage/TickEditorPage";
import createPersistentStorage from "../persistent-storage";
import StatsPage from "./StatsPage/StatsPage";
import CampaignsPage from "./CampaignsPage/CampaignsPage";
import {getLogo, getUserInfo} from "./adminApi";
import {User} from "../superAdmin/models/User";
import LogsPage from "./LogsPage/LogsPage";
import DashboardConfigurationPage from "./SettingsPages/DashboardConfigurationPage/DashboardConfigurationPage";
import TraceTable from "./TraceTable/TraceTable";
import WasteHistoryTable from "./WasteHistoryTable/WasteHistoryTable";

const accessTokenStorage = createPersistentStorage<string>("admin_access_token");
const superAccessTokenStorage = createPersistentStorage<string>("super_admin_access_token");

enum Page {
    Stats,
    Campaigns,
    Dates,
    Meals,
    Ticks,
    // Goals,
    SystemSettings,
    DashboardConfiguration,
    Logs,
    Trace,
    LatestWasteHistory
}

function FaWeightScale() {
    return null;
}

export function AdminApp() {
    const [cookies, setCookie] = useCookies<string>(['user']);
    const [initialized, setInitialized ] = useState(false);
    const [currentPage, setCurrentPage] = useState(Page.Stats);
    const navigate = useNavigate();
    const [showSuperAdminOptions, setShowSuperAdminOptions] = useState(false)
    const [userInfo, setUserInfo] = useState<User>({} as User)
    const [logo, setLogo] = useState<any>()

    useEffect(() => {
        setInitialized(true);
    }, [cookies?.username, cookies?.password]);

    const loadLogo = () => {
        let mounted = true;
        getLogo().then((res: any) => {
            if (mounted) {
                if (res && res.status === 200) {
                    setLogo(res.data.logo);
                } else {
                    // setErrorMsg("Kunne ikke hente data. Prøv at loade siden igen");
                }
            }
        })
        return () => { mounted = false };
    }

    useEffect(() => {
        enableSuperAdminView(true);
        loadUserInfo();
        loadLogo();
    }, []);


    const loadUserInfo = () => {
        let mounted = true;
        getUserInfo().then((res: any) => {
            if (mounted) {
                if (res && res.status === 200) {
                    setUserInfo(res.data as User);
                } else {
                    // setErrorMsg("Kunne ikke hente data. Prøv at loade siden igen");
                }
            }
        })
        return () => { mounted = false };
    }
    const logout = () => {
        accessTokenStorage.delete()
        navigateToApp();
    }

    const enableSuperAdminView = (enable: boolean) =>
        setShowSuperAdminOptions(!!(enable && superAccessTokenStorage.read()));

    const toSuperAdmin = () => {
        navigate("/super-admin");
    }

    const navigateToApp = () => navigate("/");

    return (
        <div className="adminApp">
            <div className="sidebarArea">
                <div className="sidebarHeader">
                    { logo && <img  src={`data:image/jpeg;base64,${logo}`} /> }
                    { userInfo?.userName && <h4>{userInfo.userName}</h4> }
                </div>
                <Sidebar>
                    <Menu>
                        <MenuItem icon={<AiOutlineBarChart />} active={currentPage === Page.Stats} onClick={() => setCurrentPage(Page.Stats)}>Madspild</MenuItem>
                        <MenuItem icon={<FaCalendarTimes />}  active={currentPage === Page.Campaigns} onClick={() => setCurrentPage(Page.Campaigns)}>Kampagne Kalender</MenuItem>
                        <MenuItem icon={<BsFillTrashFill />} active={currentPage === Page.LatestWasteHistory} onClick={() => setCurrentPage(Page.LatestWasteHistory)}>Målte måltider</MenuItem>
                        <MenuItem icon={<FaWeight />} active={currentPage === Page.Trace} onClick={() => setCurrentPage(Page.Trace)}>Trace</MenuItem>
                        <SubMenu className="submenu" defaultOpen={false} label="Indstillinger" icon={<FiSettings />}>
                            <MenuItem icon={<FaCalendarAlt />} active={currentPage === Page.Dates} onClick={() => setCurrentPage(Page.Dates)}>Datoer</MenuItem>
                            <MenuItem icon={<GiKnifeFork />} active={currentPage === Page.Meals} onClick={() => setCurrentPage(Page.Meals)}>Måltider</MenuItem>
                            <MenuItem icon={<BsChatText />} active={currentPage === Page.Ticks} onClick={() => setCurrentPage(Page.Ticks)}>Informations tekster</MenuItem>
                            {/*<MenuItem icon={<AiFillTrophy />} active={currentPage === Page.Goals} onClick={() => setCurrentPage(Page.Goals)}>Mål</MenuItem>*/}
                        </SubMenu>

                    </Menu>
                    { superAccessTokenStorage.read() &&
                        <>
                            { showSuperAdminOptions &&
                                <Menu className="borderTop">
                                    <SubMenu className="submenu" defaultOpen={true} label="Super Admin" icon={<AiFillStar />}>
                                        <MenuItem icon={<AiOutlineDashboard />} active={currentPage === Page.DashboardConfiguration} onClick={() => setCurrentPage(Page.DashboardConfiguration)}>Dashboard Config</MenuItem>
                                        <MenuItem icon={<BsLayoutTextSidebarReverse />} active={currentPage === Page.Logs} onClick={() => setCurrentPage(Page.Logs)}>Logs</MenuItem>
                                        {/*<MenuItem icon={<FiSettings />} active={currentPage === Page.SystemSettings} onClick={() => setCurrentPage(Page.SystemSettings)}>System Indstillinger</MenuItem>*/}
                                    </SubMenu>
                                </Menu>
                            }
                            <Menu className="borderTop">
                                { !showSuperAdminOptions && <MenuItem icon={<AiFillEye />} onClick={() => enableSuperAdminView(true)}>Vis som super admin</MenuItem> }
                                { showSuperAdminOptions && <MenuItem icon={<AiFillEyeInvisible />} onClick={() => enableSuperAdminView(false)}>Vis som bruger</MenuItem> }
                            </Menu>
                        </>
                    }
                    <Menu className="borderTop">
                        <MenuItem icon={<FiLogOut />} onClick={navigateToApp}>Go to app</MenuItem>
                        { !superAccessTokenStorage.read() && <MenuItem icon={<FiLogOut />} onClick={logout}>Logout</MenuItem> }
                        { superAccessTokenStorage.read() && <MenuItem icon={<FiLogOut />} onClick={toSuperAdmin}>Back to super admin</MenuItem> }
                    </Menu>
                </Sidebar>
            </div>
            {initialized &&
                <div className="mainArea">
                    {currentPage === Page.Stats && <StatsPage/>}
                    {currentPage === Page.Campaigns && <CampaignsPage userInfo={userInfo} />}
                    {currentPage === Page.Dates && <DatesPage userInfo={userInfo}/>}
                    {currentPage === Page.Meals && <MealsPage superAdminView={showSuperAdminOptions} />}
                    {currentPage === Page.Ticks && <TickEditorPage />}
                    {currentPage === Page.Logs && <LogsPage userInfo={userInfo} />}
                    {currentPage === Page.DashboardConfiguration && <DashboardConfigurationPage />}
                    {currentPage === Page.SystemSettings && <SystemSettingsPage />}
                    {currentPage === Page.Trace && (
                        <div className="adminFrame">
                            <h1>Trace</h1>
                            <div className="box">
                                <TraceTable userId={userInfo.id}></TraceTable>
                            </div>
                        </div>
                        )
                    }
                    {currentPage === Page.LatestWasteHistory && (
                        <div className="adminFrame">
                            <h1>Tømninger</h1>
                            <div className="box">
                                <WasteHistoryTable userId={userInfo.id}></WasteHistoryTable>
                            </div>
                        </div>
                    )
                    }
                </div>
            }
        </div>
    )
}

