export type MealList = {
    [key in MealType]?: Meal;
};

export enum MealType {
    breakfast = "breakfast",
    lunch = "lunch",
    dinner = "dinner"
}

export const MealNames: { [key: string]: string} = {
    "breakfast": "Morgenmad",
    "lunch": "Frokost",
    "dinner": "Aftensmad"
}

export function getMealName(mealType: keyof typeof MealType | string | undefined) : string {
    if (mealType === undefined) {
        return ""
    } else {
        return MealNames[mealType as string]
    }
}

export interface Meal {
    index: number,
    "name": string,
    startTime: string,
    endTime: string,
    baselineAvgWaste: number,
    goalWaste: number,
    mealType: MealType,
    defaultNumDining: number;
}

