import React, {FC, useEffect, useState} from 'react';
import {User} from "../models/User";
import {getAllUserdataXML, getCurrentStatusForAllUsers} from "../superAdminApi";
import {BsDot} from 'react-icons/bs';
import "./AllUsersPage.scss"
import Button from "react-bootstrap/Button";
import {minutesDiff, timeAgo} from "../../utils/dateUtils";
import {CurrentStatus} from "../../models/CurrentStatus";
import {Meal} from "../../models/Meal";

interface AllUsersPageProps {
    users: User[] | undefined
}

const AllUsersPage: FC<AllUsersPageProps> = (props) => {
    const [ userStatusMap, setUserStatusMap] = useState<{[key: string]: CurrentStatus}>({})
    const [ activeUsers, setActiveUsers] = useState<User[]>([])
    const [ inactiveUsers, setInactiveUsers] = useState<User[]>([])

    const updateStates = () => {
        getCurrentStatusForAllUsers().then(payload => {
            const status = payload.data;
            let statusMap: {[key: string]: CurrentStatus} = {};
            for (let s of payload.data) {
                statusMap[s.id] = s;
            }
            setUserStatusMap(statusMap)
        });
    }

    useEffect(updateStates,[])
    useEffect(() => console.log(userStatusMap),[userStatusMap])
    useEffect(() => {
        let active = [];
        let inactive = [];

        for (let user of props.users || []) {
            if (user.isActive) {
                active.push(user);
            } else {
                inactive.push(user);
            }
        }
        setActiveUsers(active);
        setInactiveUsers(inactive);
    },[props.users])

    const getStatusIndicator = (user: User) => {
        if (!user.isActive) {
            return <span></span>
        }
        let currentStatus = userStatusMap[user.id]
        let diff = minutesDiff(currentStatus.lastUpdated);
        return <BsDot className={`statusDot ${diff <= 5 ? "green" : "red" }`} />
    }

    const getRowClass = (user: User) => {
        let diff = minutesDiff(userStatusMap[user.id].lastUpdated);
        if (!user.isActive) {
            return "inactive";
        } else if (diff >= 5 ) {
            return "noContact";
        } else if (userStatusMap[user.id].blacklistedMeal) {
            return "blacklist";
        } else {
            return ""
        }
    }

    const printMeal = (user: User) => {
        if (!userStatusMap[user.id]?.currentMeal) {
            return <span></span>;
        } else {
            let meal = userStatusMap[user.id].currentMeal as Meal;
            return <div className="meal">
                <div className="mealName">{meal.name}</div>
                <div className="timespan">{meal.startTime} - {meal.endTime}</div>
            </div>
        }
    }

    const printUserTable = (title: string, usersList: User[]) => {
        return (<>
                <h3 className="tableHeader">{title}</h3>
                <table className="statusTable">
                <tbody>
                <tr><th>Status</th><th>Navn</th><th>Sidst set</th><th>Måledag</th><th>Måltid</th><th>Målt vægt</th><th>Offset</th></tr>
                { usersList.map((user, index) =>
                    userStatusMap[user.id] !== undefined && (
                        <tr className={getRowClass(user)} key={index}>
                            <td>{getStatusIndicator(user)}</td>
                            <td>{user.userName}</td>
                            <td className="align-center">{timeAgo(userStatusMap[user.id].lastUpdated)}</td>
                            <td className="align-center">{userStatusMap[user.id].currentMeal && userStatusMap[user.id].blacklistedMeal ? "NEJ" : ""}</td>
                            <td className="align-center">{printMeal(user)}</td>
                            <td className="align-right">{userStatusMap[user.id].measuredWeight?.toFixed(2)}</td>
                            <td className="align-right">{userStatusMap[user.id].weightOffsetUsed?.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </>
        )
    }

    const downloadData = () => {
        try {
            // Replace with your API endpoint
            // const endpoint = 'http://yourapi.com/downloadfile';
            //
            // const response = await axios.get(endpoint, {
            //     responseType: 'blob', // Important: This tells Axios to handle the response as a Blob
            // });

            getAllUserdataXML().then(response => {
                // Create a URL for the blob
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'Madspildskriger-spild-data.xlsx'); // Replace 'filename.ext' with the actual filename and extension
                document.body.appendChild(fileLink);

                // Programatically click the link to trigger the download
                fileLink.click();

                // Clean up
                fileLink.remove();
            })

        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    }
    return (
        <div className="AllUsersPage">
            {props.users && (
                <div className="superAdminFrame">
                    <div className="header">
                        <h1>Brugere</h1>
                        <div>
                            <Button variant="primary" onClick={updateStates}>Genindlæs</Button>
                            <Button variant="warning" onClick={downloadData}>Download al data</Button>
                        </div>
                    </div>
                    { printUserTable("Aktive brugere", activeUsers) }
                    { printUserTable("Inaktive brugere", inactiveUsers) }

                    {/*<table className="statusTable">*/}
                    {/*    <tbody>*/}
                    {/*    <tr><th>Status</th><th>Navn</th><th>Sidst set</th><th>Måledag</th><th>Måltid</th><th>Målt vægt</th><th>Offset</th></tr>*/}
                    {/*    { props.users.map((user, index) =>*/}
                    {/*        userStatusMap[user.id] !== undefined && (*/}
                    {/*            <tr className={getRowClass(user)} key={index}>*/}
                    {/*                <td>{getStatusIndicator(user)}</td>*/}
                    {/*                <td>{user.userName}</td>*/}
                    {/*                <td className="align-center">{timeAgo(userStatusMap[user.id].lastUpdated)}</td>*/}
                    {/*                <td className="align-center">{userStatusMap[user.id].blacklistDay ? "NEJ" : ""}</td>*/}
                    {/*                <td className="align-center">{printMeal(user)}</td>*/}
                    {/*                <td className="align-right">{userStatusMap[user.id].measuredWeight?.toFixed(2)}</td>*/}
                    {/*                <td className="align-right">{userStatusMap[user.id].weightOffsetUsed?.toFixed(2)}</td>*/}
                    {/*            </tr>*/}
                    {/*    ))}*/}
                    {/*    </tbody>*/}
                    {/*</table>*/}
                </div>
            )}
        </div>
    );
}

export default AllUsersPage;
