import React, {FC, useEffect} from 'react';
import dayjs from "dayjs";
import './statsControl.scss';
import '../../../sharedAdmin.scss';
import "dayjs/plugin/weekday";
import locale from 'dayjs/locale/da' // import plugin

// var locale = require('dayjs/locale/da')
var weekday = require('dayjs/plugin/weekday')
dayjs.locale(locale);
dayjs.extend(weekday)

interface ButtonGroupProps {
    onClick: (newVal: any) => any,
    opts: any[]
    selected: any
}


const ButtonGroup: FC<ButtonGroupProps> = (props) => {
    useEffect(() => {
        if (props.opts && !!props.onClick && props.selected === undefined) {
            let tmp = Object.keys(props.opts)[0]
            props.onClick(tmp)
        }
    }, [props.opts, props.selected, props.onClick])

    return (
        <div className="buttonGroupContainer">
            { props.opts.map((x, i) =>
              <button key={i} className={props.selected === x ? "selected" : ""} onClick={() => props.onClick(x)}>{x}</button>
            )}
        </div>
    )
}

export default ButtonGroup;
