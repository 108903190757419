const createPersistentStorage = <T>(
  key: string,
  browserStorage: typeof localStorage = localStorage
) => ({
  read: () => {
    const stored = browserStorage.getItem(key);
    if (stored == null) return undefined;
    return JSON.parse(stored) as T;
  },
  write: (value: T) => browserStorage.setItem(key, JSON.stringify(value)),
  delete: () => browserStorage.removeItem(key)
});

export default createPersistentStorage;
