import React, {ChangeEvent, FC, useEffect, useState} from "react";
import styles from './LogoHandler.module.scss';
import noLogo from './noLogo.png'
import {getLogo as apiGetLogo, setLogo as apiSetLogo} from "../../superAdminApi";
import {LogoResponseDto} from "../../../models/LogoResponseDto";
import {Button} from "react-bootstrap";

interface LogoHandlerProps {
    readOnly: boolean,
    userId: string | undefined,
    isAlt: boolean
}

const LogoHandler: FC<LogoHandlerProps> = (props) => {
    const [logo, setLogo] = useState<undefined|string>()
    const [file, setFile] = useState<File>();

    const getLogo = () => {
        if (props.userId) {
            apiGetLogo(props.userId).then((response: LogoResponseDto) => {
                setLogo(props.isAlt ? response.data.altLogo : response.data.logo);
            });
        } else {
            setLogo(undefined);
        }
    }

    useEffect(getLogo, [props.userId, props.isAlt]);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUploadClick = async (event: any) => {
        event.preventDefault()
        if (!file || !props.userId) {
            return;
        }

        const formData = new FormData();
        formData.append("logo", file);
        try {
            await apiSetLogo(formData, props.userId, props.isAlt);
            getLogo();
        } catch(error) {
            console.log(error)
        }
    };

    return (
        <div className={styles.logoHandler}>
            <h4>{props.isAlt ? "Alt logo" : "Logo" }</h4>
            { logo && <img alt="logo" src={`data:image/jpeg;base64,${logo}`} /> }
            { !logo && <img alt="placeholder logo" src={noLogo} />}
            { !props.readOnly && props.userId &&
                <form onSubmit={handleUploadClick}>
                    <div className={styles.uploadArea}>
                        <input type="file" onChange={handleFileChange}/>
                        <div>{file && `${file.name} - ${file.type}`}</div>
                        <Button type="submit" variant="success">Upload</Button>
                    </div>
                </form>
            }
            </div>
    )
}

export default LogoHandler;
