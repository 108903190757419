import React, {FC, useEffect, useState} from 'react';
import {Stats} from '../../../models/Stats';
import './BottomBar.scss';
import Marquee from "react-fast-marquee";
import {TickModel} from '../../../models/TickModel';
import {getIcon} from "../TickerSymbol/TickerSymbol";
import {evaluate} from "mathjs";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import {getMealName} from "../../../models/Meal";


interface TickerItemProps {
    text: string,
    icon: any
}

const TickerItem: FC<TickerItemProps> = (props) => {
    return (
        <div className="tickerItem">
            <img src={props.icon} alt="" />
            <span>{props.text}</span>
        </div>
    )
}

interface BottomBarProps {
    stats: Stats | undefined,
    ticks: TickModel[]
}


const BottomBar: FC<BottomBarProps> = (props) => {
    const [ticks, setTicks] = useState<JSX.Element[]>([]);

    useEffect(() => {
        if (props.ticks === undefined || props.stats?.meals === undefined) {
            return;
        }

        // let ticksToDisplay = props.ticks.filter(x => x.active);
        let ticksToDisplay = props.ticks;
        let formattedTicks: TickModel[] = [];

        let replaceMap = {
            "samletSpildKg": props.stats.totals.waste.kg,
            "samletSparetKg": props.stats.totals.saved.kg,
            "mestSpildPåEtMåltidKg": props.stats.meals.overall?.mostWasteKg.kg,
            "mestSpildPåEtMåltidNavn": getMealName(props.stats.meals.overall?.mostWasteKg.mealType),
            "mindstSpildPåEtMåltidKg": props.stats.meals.overall?.leastWasteKg.kg,
            "mindstSpildPåEtMåltidNavn": getMealName(props.stats.meals.overall?.leastWasteKg.mealType),
            "mestSpildPåEnDagKg": props.stats.days.dayStats?.mostWasteKg.kg,
            "mindstSpildPåEnDagKg": props.stats.days.dayStats?.leastWasteKg.kg,
            "gennemsnitSpildPåEnDagKg": props.stats.days.dayStats?.avgWasteKg,
            "længsteStreak": props.stats.streaks.longest,
            "nuværendeStreak": props.stats.streaks.current
        }

        for (let index in ticksToDisplay) {
            let currentTick = ticksToDisplay[index];
            let abandonTick = false;

            for (const [key, value] of Object.entries(replaceMap)) {
                //find in string
                if (currentTick.text.includes("$"+key)) {
                    if (value === undefined) {
                        abandonTick = true;
                        break;
                    } else {
                        currentTick.text = currentTick.text.replace("$"+key, typeof value === "number" ? value.toFixed(1) : value);
                    }
                }
            }

            if (abandonTick) {
                continue;
            }

            let regexp = /\{[^\}]+\}/gi // eslint-disable-line
            let match: any = null;
            while((match = regexp.exec(currentTick.text)) != null) {
                try {
                    currentTick.text = currentTick.text.replace(match[0], evaluate(match[0].substring(1, match[0].length - 2)).toFixed(1));
                } catch (e: any) {
                    console.log(`Error parsing expression ${currentTick.text}, details: ${e.toString()}`);
                    abandonTick = true;
                }
            }
            if (!abandonTick) {
                formattedTicks.push(ticksToDisplay[index]);
            }
        }

        let ticks: JSX.Element[] = formattedTicks.map((x,i) => <TickerItem key={i} text={x.text} icon={getIcon(x.icon)}></TickerItem>);
        setTicks(ticks)
    }, [props.stats, props.stats?.totals, props.ticks])

    return (
        <div className="BottomBar">
            <div className="ticker">
                <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    arrows={false}
                    responsive={{ desktop: { breakpoint: { max: 6000, min: 100 }, items: 2 }}}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={6000}
                    keyBoardControl={false}
                    transitionDuration={15000}
                    containerClass="carousel-container"
                    itemClass="carousel-item-padding-40-px">
                    {ticks}
                </Carousel>
            </div>
        </div>
    )
};

export default BottomBar;
