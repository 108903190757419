import ReactMarkdown from 'react-markdown'
import styles from "./GuidePage.module.scss"
import React, {FC, useEffect, useState} from 'react';
// @ts-ignore
import markDownPath from './Guide.md'

interface GuideProps {
}

const GuidePage: FC<GuideProps> = (props) => {
    const [markdown, setMarkdown] = useState("")

    useEffect(() => {
        fetch(markDownPath).then((response) => response.text()).then((text) => {
            setMarkdown(text)
        })
    },[])

    useEffect(() => {
        console.log(markdown)
    },[markdown])


    return (
        <div className={styles.guidePage}>
            <h1>Guide</h1>
            <ReactMarkdown children={markdown} />
        </div>
    );
}

export default GuidePage;
