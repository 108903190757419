import React, {FC, useEffect, useState} from 'react';
import './StatsPage.scss';
import Alert from "react-bootstrap/Alert";
import {getHistoricData} from "../adminApi";
import dayjs, {Dayjs} from "dayjs";
import "dayjs/plugin/weekday";
import {HistoricData, MealTypes, Resolution, Stat, StatsConfiguration, TableData, Unit} from "./models";
import StatsControls from "./StatsControls/StatsControls";
import weekOfYear from 'dayjs/plugin/weekOfYear' // import plugin
import {StatsData} from "./StatsData"; // import plugin
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from "react-chartjs-2";
import ButtonGroup from "./StatsControls/ButtonGroup";

const locale = require('dayjs/locale/da')
dayjs.locale(locale);
dayjs.extend(weekOfYear)

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

enum Tabs {
    simple="Oversigt",
    detailed="Detaljeret"
}
interface StatsPageProps {}


const StatsPage: FC<StatsPageProps> = () => {
    const [ errorMsg, setErrorMsg ] = useState<string>("");
    const [ statsConfig, setStatsConfig ] = useState<StatsConfiguration>()
    const [ historicData, setHistoricData ] = useState<HistoricData[]>([]);
    const [ filteredHistoricData, setFilteredHistoricData ] = useState<HistoricData[]>([]);
    const [ statsData, setStatsData] = useState<StatsData>()
    const [ activeTab, setActiveTab] = useState<Tabs>(Tabs.simple)
    const [ chartData, setChartData] = useState<any>(undefined)
    const colors= ["#54B9A1", "#FFDE4E", "#1E1D62", "#2f4b7c", "#f95d6a", "#665191", "#d45087", "#ff7c43"]

    useEffect(() => {
        let mounted = true;
        getHistoricData().then((res: any) => {
                if (mounted) {
                    if (res && res.status === 200) {
                        console.log(res.data);
                        setHistoricData(res.data);
                    } else {
                        setErrorMsg("Kunne ikke hente data. Prøv at loade siden igen");
                    }
                }
            })
        return () => { mounted = false };
    }, [])

    useEffect(() => setStatsData(new StatsData(filteredHistoricData)), [filteredHistoricData])

    useEffect(() => {
        const filtered = historicData.filter(x => {
            let needleDate = dayjs(x.time, "YYYY-MM-DD");
            return compareDays(dayjs(statsConfig?.from), needleDate) <= 0 && compareDays(needleDate, dayjs(statsConfig?.to)) <= 0;
        });
        setFilteredHistoricData(filtered);
    }, [statsConfig, historicData])

    useEffect(() => {
        if (statsData) {
            const options = {
                plugins: {
                    title: {
                        display: false,
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            callback: (x: number) => `${x} ${statsConfig?.unit === Unit.Relative ? "g" : "kg"}`
                        }
                    },
                },
            };

            console.log(statsData)
            const labels = [];
            const datasets: {label: MealTypes, data: number[]}[] = statsData.mealTypes.map((m,i) => {
                return {
                    label: m,
                    data: [],
                    backgroundColor: colors[i]
                }
            });
            let dataToShow = statsConfig?.resolution === Resolution.day ?
                statsData.dailyData :
                (statsConfig?.resolution === Resolution.week ? statsData.weeklyData : statsData.monthlyData)

            for (let day of dataToShow) {
                labels.push(day.title);
                for (const index in statsData.mealTypes) {
                    let meal = day.meals[statsData.mealTypes[index]];
                    if (meal) {
                        datasets[index].data.push(statsConfig?.unit === Unit.Absolute ? meal.absoluteWeight : meal.relativeWeight);
                    } else {
                        datasets[index].data.push(0);
                    }
                }
            }
            setChartData({options, data: { labels, datasets}})
        }
    }, [statsData])
    const compareDays = (a: Dayjs, b: Dayjs) => a.diff(b, 'days')

    const resolutionChanged = (res: Resolution) => setStatsConfig(prev => {return { ...prev, resolution: res} as StatsConfiguration })
    const printTable = (data: TableData[]) => {
        return <table>
            <tbody>
                <tr className="mainHeader">
                    <th key="date">Dato</th>
                    { statsData?.mealTypes.map(mealType =>
                        <th key={mealType} colSpan={2}>{mealType}</th>
                    )}
                    <th key={`total${data.length}`} colSpan={2}>I alt</th>
                </tr>
                <tr className="secondHeader">
                    <th></th>
                    { statsData?.mealTypes.map(mealType =>
                        <>
                            <th key={mealType}>{statsConfig?.unit === Unit.Absolute ? "Vægt (kg)" : "Vægt/person (g)" }</th>
                            <th key={`${mealType}x`}>Antal spisende</th>
                        </>
                    )}
                    <th>{statsConfig?.unit === Unit.Absolute ? "Vægt (kg)" : "Vægt/person (g)" }</th>
                    <th>Antal måltider</th>
                </tr>
                { data.map(dataEntry =>
                    <tr key={dataEntry.date} className={dataEntry.highlight ?"highlight": ""}>
                        <td className="date">{dataEntry.title}</td>
                        { statsData?.mealTypes.map(mealType =>
                            <>
                                { dataEntry.meals[mealType] === undefined &&
                                    <>
                                        <td key={mealType}>-</td>
                                        <td key={`${mealType}x`}>-</td>
                                    </>
                                }
                                { dataEntry.meals[mealType] !== undefined &&
                                    <>
                                        <td key={mealType}>{statsConfig?.unit === Unit.Absolute ? dataEntry.meals[mealType]?.absoluteWeight.toFixed(2) : dataEntry.meals[mealType]?.relativeWeight.toFixed(2)}</td>
                                        <td key={`${mealType}x`}>{dataEntry.meals[mealType]?.numDining}</td>
                                    </>
                                }

                            </>


                        )}
                        <td>{dataEntry.totals !== undefined ? (statsConfig?.unit === Unit.Absolute ? dataEntry.totals.absoluteWeight.toFixed(2) : dataEntry.totals.relativeWeight.toFixed(2)) : "-"}</td>
                        <td>{dataEntry.totals !== undefined ? dataEntry.totals.numDining : "-"}</td>
                    </tr>
                )}
            </tbody>
        </table>
    }

    const simpleStatBox = (header: string, main: string) => (
        <div className="simpleStatBox">
            <div className="header">{header}</div>
            <div className="mainText">{main}</div>
        </div>
    );

    const simpleMealStatBox = (mealType: MealTypes, meal: Stat) => { return (
        <div className="simpleMealStatBox statsBox">
            <div className="header">{mealType}</div>
            <div className="mainText">Gennemsnit spild: <span className="value">{(meal.totalWaste / (meal.count || 1)).toFixed(1)} kg</span></div>
            <div className="mainText">Spild per person: <span className="value">{(1000 * meal.totalWaste / (meal.totalMeals || 1)).toFixed(2)} g/person</span></div>
            <div className="mainText">Antal målt: <span className="value">{meal.totalMeals} måltider</span></div>
        </div>
    )};

    return (
      <div className="StatsPage">
        <div className="adminFrame">
            <h1 className="pageHeader">Oversigt</h1>
            { errorMsg && <Alert variant="danger">{errorMsg}</Alert> }
            <div className="displayControlsArea">
                <StatsControls onChange={setStatsConfig} statsConfig={statsConfig} />
            </div>
            <div className="box">
                <ButtonGroup selected={activeTab} onClick={x => setActiveTab(x)} opts={Object.values(Tabs)} />
                <div className="content">
                    { activeTab === Tabs.simple && statsData?.generalStats &&
                        <div className="simple">
                            <div className="statBoxes">
                                <div className="simpleStatsArea">
                                    { simpleStatBox("Samlet spild", `${statsData.generalStats.total.totalWaste.toFixed(1)} kg`) }
                                    { simpleStatBox("Gennemsnit spild", `${(1000 * statsData.generalStats.total.totalWaste / (statsData?.generalStats?.total.totalMeals || 1)).toFixed(2)} g/måltid`) }
                                    { simpleStatBox("Antal måltider", `${(statsData?.generalStats?.total.totalMeals || 0)} måltider`) }
                                </div>
                                <div className="simpleStatsArea">
                                    { Object.entries(statsData?.generalStats?.byMeal || {}).map(([key, value]) => simpleMealStatBox(key as MealTypes, value)) }
                                </div>
                            </div>
                            <div className="graphArea">
                                <div className="resolutions">
                                    <ButtonGroup selected={statsConfig?.resolution} onClick={x => resolutionChanged(x)} opts={Object.values(Resolution)} />
                                </div>
                                <div className="graph">
                                    { chartData && <Bar options={chartData.options} data={chartData.data} /> }
                                </div>
                            </div>
                        </div>
                    }
                    { activeTab === Tabs.detailed &&
                        <div className="wasteTable">
                            <h4>Dagligt spild</h4>
                            { statsData?.dailyData && printTable(statsData?.dailyData) }

                            <h4>Ugentligt spild</h4>
                            { statsData?.weeklyData && printTable(statsData?.weeklyData)}

                            <h4>Månedligt spild</h4>
                            { statsData?.monthlyData && printTable(statsData?.monthlyData)}
                        </div>
                    }
                </div>
            </div>
        </div>
      </div>
    );
}

export default StatsPage;
