import React, {FC, useEffect, useState} from 'react';
import "./DashboardConfigurationPage.scss";
import Alert from "react-bootstrap/Alert";
// import {getEmptyingLimits, updateEmptyingLimits} from "../adminApi";
import Button from "react-bootstrap/Button";
import {Col, Form, FormControl, Row} from "react-bootstrap";
import {getZoomLevel, updateZoomLevel} from "../../adminApi";

interface DashboardConfigurationProps {}

const DashboardConfigurationPage: FC<DashboardConfigurationProps> = () => {
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [zoomLevel, setZoomLevel] = useState<number>(1);
    const [originalZoomLevel, setOriginalZoomLevel] = useState<number>(0);
    const [loadingZoomLevel, setLoadingZoomLevel] = useState<boolean>(true);
    const [zoomLevelModified, setZoomLevelModified] = useState<boolean>(false);

    useEffect(() => {
        let mounted = true;
        getZoomLevel().then((res: any) => {
            if (mounted) {
                if (res && res.status === 200) {
                    var zoom = res.data?.zoomLevel;
                    if (zoom === undefined) {
                        setErrorMsg("Fejl i at hente værdier. Ugyldig data returneret. Load siden igen");
                    } else {
                        setOriginalZoomLevel(zoom);
                        setZoomLevel(zoom);
                        setLoadingZoomLevel(false);
                    }
                } else {
                    setErrorMsg("Kunne ikke hente værdien for zoom level. Load siden igen");
                }
            }
        })
        return () => { mounted = false };
    }, []);


    const saveZoomLevel = async () => {
        setErrorMsg("");
        try {
            await updateZoomLevel(zoomLevel);
            setZoomLevelModified(false);
        } catch (e) {
            setErrorMsg("Kunne ikke gemme værdi for zoom level. Prøv igen");
        }
    }

    const extractNumericValue = (val: any): number =>  {
        let numeric = Number(val?.target?.value);
        if (isNaN(numeric)) {
            setErrorMsg(`Ikke et gyldigt tal: ${val}. Brug '.' som komma`);
            return 0;
        } else {
            return numeric;
        }
    }

    const resetNumDining = () => {
        setErrorMsg("");
        setZoomLevelModified(false);
        setZoomLevel(originalZoomLevel);
    }

    return (
        <div className="DashboardConfigurationPage">
            <div className="adminFrame">
                <h1>Konfiguration af Dashboard</h1>
                <i>Her kan du sætte diverse indstillinger for dashboardet. Lav kun ændringer hvis du ved hvad du laver.</i>
                {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
                <hr />
                <h3>Zoom</h3>
                {!loadingZoomLevel &&
                    <div>
                        <i>
                            <p>Hvis fjernsynet ikke fungerer med default opløsning på dashboard kan man sætte en zoom level her</p>
                        </i>

                        <div className="formArea">
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                <Form.Label column sm={3}>Zoom level</Form.Label>
                                <Col sm={2}>
                                    <FormControl type="number" step={1} value={zoomLevel} onChange={x => {
                                        setZoomLevel(extractNumericValue(x));
                                        setZoomLevelModified(true);
                                    }}/>
                                </Col>
                            </Form.Group>
                        </div>
                    </div>
                }

                <div className="standardButtons">
                    <Button variant="primary" onClick={saveZoomLevel} disabled={!zoomLevelModified && !loadingZoomLevel}>Gem</Button>
                    <Button variant="warning" onClick={resetNumDining} disabled={!zoomLevelModified}>Fortryd</Button>
                </div>
            </div>
        </div>
    );
};

export default DashboardConfigurationPage;
