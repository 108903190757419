import React, {FC, useEffect, useState} from "react";
import {getSuccessClass, withDecimals} from "../../../utils/utils";
import {CampaignState} from "./UserCampaignCard";
import {Dayjs} from "dayjs";
import {Campaign} from "../../../models/Campaign";
import {User} from "../../../superAdmin/models/User";

interface SoloMealGoalDetailsProps {
    campaignState: CampaignState,
    campaignDate: Dayjs,
    campaign: Campaign,
    userInfo: User
}
export const SoloMealGoalDetails: FC<SoloMealGoalDetailsProps> = (props) => {
    const [results, setResults ] = useState<any>()

    useEffect(() => {
        if (props.campaign?.results && props.userInfo) {
            setResults(props.campaign.results[props.userInfo.id]);
            console.log(props);
        }
    }, [props.campaign?.results, props.userInfo])

    if (props.campaignState === CampaignState.active) {
        return (
            <div className="soloMealGoals">
                <p>Kæmp for at nå holde jeres daglige mål hele {props.campaignDate.format("MMMM")} måned. Det samlede
                    madspild på hele måneden må maks være på <b>{withDecimals(results?.totalGoal, 1)} kg</b>, fordelt
                    på {results?.totalMeasuredDays} måledage ({withDecimals(results?.perDayGoal, 1)} kg/dag)
                </p>
                <p>
                    Indtil videre er der målt <b>{withDecimals(results?.measuredDaysUntilNow, 0)}</b> dage, hvilket betyder at i skulle være
                    under <b>{withDecimals(results?.currentDayGoal, 1)} kg</b>.
                    Jeres spild indtil nu er:</p>
                <p className="status">
                    <span className={`waste ${getSuccessClass(results?.success)}`}>{withDecimals(results?.currentDayWeight, 1)} kg</span>
                    <span className="goal">/{withDecimals(results?.currentDayGoal, 1)} kg</span>
                </p>
            </div>
        )
    } else if (props.campaignState !== CampaignState.pending) {
        return (
            <div className="soloMealGoals">
                <p>Kæmp for at nå holde jeres daglige mål hele {props.campaignDate.format("MMMM")} måned. Det samlede
                    madspild for hele måneden måtte maks være på <b>{withDecimals(results?.totalGoal, 1)} kg</b>, fordelt
                    på <b>{withDecimals(results?.totalMeasuredDays, 0)}</b> måledage (<b>{withDecimals(results?.perDayGoal, 1)}</b> kg/dag)
                </p>
                <p className="status">
                    <span
                        className={`waste ${getSuccessClass(results?.success)}`}>{withDecimals(results?.currentDayWeight, 1)} kg</span>
                    <span className="goal">/{withDecimals(results?.totalGoal, 1)} kg</span>
                </p>
            </div>
        );
    } else {
        return <></>
    }
}

