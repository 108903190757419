import {ThisWeekDay} from "./Stats";

export enum CampaignTypes {
    SoloMealGoals = "SoloMealGoals",
    GroupContest = "GroupContest",
    GroupContestElimination = "GroupContestElimination",
    SmallGroupsContest = "SmallGroupsContest",
    BackgroundGroupContest = "BackgroundGroupContest",
    MemoryLane = "MemoryLane"
}

export const campaignTypesNameMap: { [key in CampaignTypes]: string} = {
    [CampaignTypes.SoloMealGoals]: "Kæmp mod dig selv",
    [CampaignTypes.GroupContest]: "Pulje spil",
    [CampaignTypes.GroupContestElimination]: "Puljespil med eliminering",
    [CampaignTypes.SmallGroupsContest]: "1vs1",
    [CampaignTypes.BackgroundGroupContest]: "Baggrunds gruppe konkurrence",
    [CampaignTypes.MemoryLane]: "Memory Lane"
};

export interface CampaignGroup {
    name: string;
    userIds: string[];
}

export interface CampaignDetails {
    configuration?: any;
    groups?: CampaignGroup[];
}

export interface SoloMealGoalResult {
    totalGoal: number;
    currentDayGoal: number;
    currentDayWeight: number;
    measuredDaysUntilNow: number;
    remainingDays: number;
    totalMeasurementDays: number;
    success: boolean;
    thisWeek: ThisWeekDay[]
}

export interface GroupContestUserEntry {
    remainingDays: number;
    groupIndex: number;
    groupPlacementIndex: number;
    userName: string;
    avgRelWaste?: number;
}

export interface GroupContestGroupEntry {
    name: string
    placements: string[]
}

export interface GroupContestResult {
    users: { [key: string]: GroupContestUserEntry }
    groups: GroupContestGroupEntry[]
}

export interface Campaign {
    id?: string;
    name: string;
    monthIndex: number;
    year: number;
    type: CampaignTypes;
    isValid: boolean;
    data?: CampaignDetails;
    userIds: string[];
    results?: { [key: string]: SoloMealGoalResult } | GroupContestResult | any;
}
