export function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function withDecimals(v: number | undefined, numDecimals: number = 2): string {
    return v === undefined ? "" : v.toFixed(numDecimals)
}
export function getSuccessClass(success: boolean | undefined) {
    if (success === undefined) {
        return "even"
    } else if (success) {
        return "success"
    } else {
        return "fail"
    }
}

export function classGoalIsMinimize(val: number, goal: number) {
    if (val === goal) {
        return "even"
    } else if (val < goal) {
        return "success"
    } else {
        return "fail"
    }
}

export function truncateString(str: string, n: number) {
    return (str.length > n) ? str.slice(0, n-1) + String.fromCharCode(8230) : str;
}
