import React, {FC, useEffect, useState} from 'react';
import {Stats} from '../../../models/Stats';
import {Campaign, CampaignTypes} from "../../../models/Campaign";
import SoloMealGoalsCampaign from "./SoloMealGoalsCampaign";
import './CampaignBox.scss'
import {UserInfo} from "../../../models/UserInfo";
import GroupContestCampaign from "./GroupContestCampaign";

interface CampaignBoxProps {
    stats: Stats | undefined,
    campaign: Campaign | undefined,
    userInfo: UserInfo
}

const CampaignBox: FC<CampaignBoxProps> = (props) => {
    const [campaignType, setCampaignType] = useState<CampaignTypes>();

    useEffect(() => setCampaignType(props.campaign?.type), [props.campaign])

    return (
        <div className="CampaignBox blueBox">
            { campaignType === CampaignTypes.SoloMealGoals && <SoloMealGoalsCampaign {...props} />}
            { campaignType === CampaignTypes.GroupContest && <GroupContestCampaign {...props} />}
        </div>
    );
}

export default CampaignBox;
