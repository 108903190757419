import {MealType} from "../../models/Meal";

export enum Unit {
    Absolute="Samlet vægt", // kg
    Relative="Per spisende" // g/person
}

export enum Resolution {
    day="Dagligt",
    week="Ugentligt",
    month="Månedligt"
}

export enum MealTypes {
    breakfast="breakfast",
    lunch="lunch",
    dinner="dinner"
}

export enum PeriodPreset {
    currentWeek="Denne Uge",
    currentMonth="Denne måned",
    currentYear="Dette år",
    custom="Brugerbestemt"
}
export interface StatsConfiguration {
    unit: Unit,
    resolution: Resolution,
    periodPreset: PeriodPreset,
    from?: Date,
    to?: Date
}

export interface HistoricData {
    userId: string,
    userType: string,
    mealType: MealTypes,
    time: Date
    weight: number,
    numDining: number
}

export interface TableData {
    date: string, // date as yyyy-mm-dd
    title: string,
    meals: {
        [key in MealTypes]?: { // meal_type
            absoluteWeight: number,
            relativeWeight: number,
            numDining: number
        }
    },
    totals?: {
        absoluteWeight: number,
        relativeWeight: number,
        numDining: number
    },
    highlight?: boolean
}

export interface Stat {
    totalWaste: number,
    totalMeals: number,
    count: number
}

export interface GeneralStats {
    byMeal: { [keys in MealTypes]?: Stat },
    total: Stat,
    numWeeks: number;
    numMonths: number;
    numDays: number;
}
