import idea_icon from "./idea.svg";
import info_icon from "./info.svg";
import stat_icon from "./stat.svg";

enum Icons {
    Idea,
    Info,
    Stat
};

let getIcon = (icon: Icons): string => {
    switch (icon) {
        case Icons.Idea:
            return idea_icon;
        case Icons.Stat:
            return stat_icon;
        default:
            return info_icon;
    }
};

namespace IconFunctions {
    export function toString(dir: Icons): string {
        return Icons[dir];
    }

    export function fromString(dir: string): Icons {
        return (Icons as any)[dir];
    }
}


export { getIcon, Icons, IconFunctions };