import {Campaign, CampaignTypes} from "../models/Campaign";

export const validateCampaign = (campaign: Campaign): string[] => {
    let errors: string[] = [];
    // check basics
    if (!campaign.name || campaign.name.length < 3) {
        errors.push("Kampagnens navn skal minimum have tre bogstaver");
    }
    if (!campaign.monthIndex === undefined|| !campaign.year) {
        errors.push("Ugyldig tidspunkt for kampagnen");
    }
    if (!campaign.userIds || campaign.userIds.length === 0) {
        errors.push("Der er ingen brugere tilknyttet kampagnen");
    }
    if (isGroupType(campaign) && campaign.data?.groups) {
        // clear any userIds from groups that arent part of campaign
        for (const group of campaign.data.groups) {
            group.userIds = group.userIds.filter((x: string) => campaign.userIds.find(sid => sid === x));
        }
        //  error if there are unassigned users:
        let assignedUsers: string[] = [];
        for (const group of campaign.data.groups) {
            assignedUsers = [...assignedUsers, ...group.userIds];
        }
        let remaining = campaign.userIds.filter((x: string) => !assignedUsers.includes(x));
        if (remaining.length > 0) {
            errors.push("Der er deltagere der ikke er tildelt en pulje");
        }
    }
    campaign.isValid = errors.length === 0;
    return errors;
}

export const isGroupType = (campign: Campaign): boolean =>
    campign.type === CampaignTypes.GroupContest ||
    campign.type === CampaignTypes.GroupContestElimination ||
    campign.type === CampaignTypes.BackgroundGroupContest ||
    campign.type === CampaignTypes.SmallGroupsContest;
