import React, {FC, useEffect, useState} from 'react';
// import './UserCampaignCardList.scss';
import Alert from "react-bootstrap/Alert";
import dayjs, {Dayjs} from "dayjs";
import {Campaign} from "../../models/Campaign";
import {getCampaigns} from "../adminApi";
import UserCampaignCard from "./UserCampaignCard/UserCampaignCard";
import {User} from "../../superAdmin/models/User";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";

const locale = require('dayjs/locale/da')
dayjs.locale(locale);


interface UserCampaignCardListProps {
    defaultFolded: boolean,
    campaigns: Campaign[],
    userInfo: User,
    title: string
}

const UserCampaignCardList: FC<UserCampaignCardListProps> = (props) => {
    const [ isFolded, setIsFolded ] = useState<boolean>(props.defaultFolded)

    return (
        <>
            {
                props.campaigns.length !== 0 &&
                    <div className="campaignsListing">
                        <h3 onClick={() => setIsFolded(x => !x)}>{props.title} {isFolded ? <FaChevronUp/> : <FaChevronDown/>}</h3>
                        {props.campaigns.map(x => <UserCampaignCard key={x.id} headerOnly={isFolded} campaign={x} userInfo={props.userInfo}/>)}
                    </div>
            }
        </>
    );
}

export default UserCampaignCardList;
