
import "./OtherFunctionsPage.scss"
import React, {FC} from 'react';
import {
    forceStatsUpdate,
    updateCampaignStatus
} from "../superAdminApi";
import Button from "react-bootstrap/Button";

interface OtherFunctionsPageProps {
}

const OtherFunctionsPage: FC<OtherFunctionsPageProps> = (props) => {
    const forceCampaignUpdateClicked  = async () => await updateCampaignStatus();
    const forceStatsUpdateClicked  = async () => await forceStatsUpdate();

    return (
        <div className="OtherFunctionsPage">
            <h1>Tving kampagne opdatering</h1>
            <Button variant="btn btn-primary" onClick={forceCampaignUpdateClicked}>Opdater kampagner</Button>
            <h1>Tving stats opdatering</h1>
            <Button variant="btn btn-primary" onClick={forceStatsUpdateClicked}>Opdater stats</Button>
        </div>
    );
}

export default OtherFunctionsPage;
