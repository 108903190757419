import React, {FC, useEffect, useState} from "react";
import {CampaignState} from "./UserCampaignCard";
import {Dayjs} from "dayjs";
import {Campaign, GroupContestGroupEntry, GroupContestResult, GroupContestUserEntry} from "../../../models/Campaign";
import {User} from "../../../superAdmin/models/User";

interface GroupCampaignDetailsProps {
    campaignState: CampaignState,
    campaignDate: Dayjs,
    campaign: Campaign,
    userInfo: User
}
export const GroupCampaignDetails: FC<GroupCampaignDetailsProps> = (props) => {
    const [results, setResults ] = useState<GroupContestResult>()
    const [userGroup, setUserGroup ] = useState<GroupContestGroupEntry>()
    const [userStats, setUserStats ] = useState<GroupContestUserEntry>()

    useEffect(() => {
        if (props.campaign?.results && props.userInfo) {
            const res = props.campaign.results as GroupContestResult
            setResults(res);
            const userSt = res.users[props.userInfo.id]
            setUserStats(userSt);
            setUserGroup(res.groups[userSt.groupIndex])
        }
    }, [props.campaign?.results, props.userInfo])

    useEffect(() => console.log("Props", props), [props])

    if ((props.campaignState === CampaignState.active || props.campaignState === CampaignState.completed) && results) {
        return (
            <div className="groupCampaignGoals">
                <p>Kæmp mod andre skoler i din pulje for at få det laveste madspild per elev per måltid for hele {props.campaignDate.format("MMMM")} måned.</p>
                <h5>{userGroup?.name}</h5>
                <p className="status">
                    <table>
                        <tbody>
                            { userGroup?.placements.map((v: string) => (
                                <tr>
                                    <td style={{paddingRight: 20}}>{results?.users[v].userName}</td>
                                    <td>
                                        {results?.users[v].avgRelWaste ?
                                            ((results.users[v].avgRelWaste || 0) * 1000).toFixed(2) :
                                            "-"} g/elev/måltid</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </p>
            </div>
        )
    } else {
        return (
            <div className="groupCampaignGoals">
                {/*{ props.campaign?.data?.groups && userGroupIndex !== undefined && (*/}
                {/*    <>*/}
                {/*        <p>Kæmp mod andre skoler i din pulje for at få det laveste madspild per elev per måltid for hele {props.campaignDate.format("MMMM")} måned.</p>*/}

                {/*        <h5>{props.campaign?.data?.groups[userGroupIndex].name}</h5>*/}
                {/*        <p className="status">*/}
                {/*            <table>*/}
                {/*                <tbody>*/}
                {/*                { props.campaign?.data?.groups[userGroupIndex].userIds.map((v: any) => (*/}
                {/*                    <tr>*/}
                {/*                        <td>{v}</td>*/}
                {/*                    </tr>*/}
                {/*                ))}*/}
                {/*                </tbody>*/}
                {/*            </table>*/}
                {/*        </p>*/}
                {/*    </>*/}
                {/*)}*/}
            </div>
        );
    }
}

