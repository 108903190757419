import {MealType} from "./Meal";

export enum BlacklistType {
    manual="manual",
    auto="auto"
}

export interface BlacklistEntry {
    id?: string,
    date: string, //yymmdd
    userId?: string,
    mealType: MealType,
    blacklisted: BlacklistType
}
