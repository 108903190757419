import React, {FC, useEffect, useState} from 'react';
import styles from './TotalSavedBox.module.scss';
import {Stats} from '../../../models/Stats';
import ok from './ok.svg';
import thumbsDown from './thumbs_down.png';
import {CurrentStatus} from "../../../models/CurrentStatus";

interface TotalSavedBoxProps {
    stats: Stats | undefined // TODO,
    currentStatus: CurrentStatus | undefined
}

const TotalSavedBox: FC<TotalSavedBoxProps> = (props) => {
    const [saved, setSaved] = useState<string | number>("?");
    const [isNegative, setIsNegative] = useState<boolean>(false);

    useEffect(() => {
        if (props.stats?.totals.saved.kg === undefined) {
            setSaved("?");
            setIsNegative(false);
        }
        let numberVal: number = typeof props.stats?.totals.saved.kg === "string" ? Number.parseFloat(props.stats.totals.saved.kg) : (props.stats?.totals.saved.kg || 0);
        if (numberVal < 0) {
            setIsNegative(true);
            setSaved(props.stats?.totals.waste.kg || 0);
        } else {
            setIsNegative(false);
            setSaved(numberVal);
        }
    }, [props.stats?.totals.saved.kg, props.stats?.totals.waste.kg])

    return (
        <div className={styles.TotalSavedBox + ' blueBox'}>
            <div className="texts">
                <h2 className="test">{isNegative ? "Mad spildt i alt" : "Mad sparet i alt"}</h2>
                <h1 className={isNegative ? "red" : ""}>{ typeof saved === "number" ? saved.toFixed(1) : saved } kg</h1>
            </div>
            <div className="ok">
                <img src={isNegative ? thumbsDown : ok} alt=""/>
            </div>
        </div>
    );
}

export default TotalSavedBox;
