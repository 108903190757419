import React, {FC, useEffect, useState} from 'react';
import './CampaignsPage.scss';
import Alert from "react-bootstrap/Alert";
import dayjs, {Dayjs} from "dayjs";
import {Campaign} from "../../models/Campaign";
import {getCampaigns} from "../adminApi";
import UserCampaignCard from "./UserCampaignCard/UserCampaignCard";
import {User} from "../../superAdmin/models/User";
import UserCampaignCardList from "./UserCampaignCardList";

const locale = require('dayjs/locale/da')
dayjs.locale(locale);


interface CampaignsPageProps {
    userInfo: User
}

const CampaignsPage: FC<CampaignsPageProps> = (props) => {
    const [ errorMsg, setErrorMsg ] = useState<string>("");
    // const [ campaigns, setCampaigns ] = useState<Campaign[]>([])
    const [ activeCampaigns, setActiveCampaigns ] = useState<Campaign[]>([])
    const [ latestCampaigns, setLatestCampaigns ] = useState<Campaign[]>([])
    const [ completedCampaigns, setCompletedCampaigns ] = useState<Campaign[]>([])
    const [ futureCampaigns, setFutureCampaigns ] = useState<Campaign[]>([])


    useEffect(() => {
        let mounted = true;

        getCampaigns().then((res: any) => {
                if (mounted) {
                    if (res && res.status === 200) {
                        let cam = res.data as Campaign[];
                        const now = dayjs();
                        console.log(res.data);
                        setCompletedCampaigns(cam.filter(x => dayjs(new Date(x.year, x.monthIndex, 1)).subtract(1, "month") < now))
                        setLatestCampaigns(cam.filter(x => x.year === dayjs().subtract(1, "month").year() && x.monthIndex === dayjs().subtract(1, "month").month()))
                        setActiveCampaigns(cam.filter(x => x.year === dayjs().year() && x.monthIndex === dayjs().month()))
                        setFutureCampaigns(cam.filter(x => dayjs(new Date(x.year, x.monthIndex, 1)) > now));
                        // setCampaigns(cam);
                    } else {
                        setErrorMsg("Kunne ikke hente data. Prøv at loade siden igen");
                    }
                }
            })
        return () => { mounted = false };
    }, [])

    return (
      <div className="campaignsPage">
        <div className="adminFrame">
            <h1 className="pageHeader">Kampagner</h1>
            { errorMsg && <Alert variant="danger">{errorMsg}</Alert> }
            <UserCampaignCardList defaultFolded={false} campaigns={latestCampaigns} userInfo={props.userInfo} title="Nyligt afsluttede" />
            <UserCampaignCardList defaultFolded={false} campaigns={activeCampaigns} userInfo={props.userInfo} title="Aktive kampagner" />
            <UserCampaignCardList defaultFolded={false} campaigns={futureCampaigns} userInfo={props.userInfo} title="Fremtidige kampagner" />
            <UserCampaignCardList defaultFolded={true} campaigns={completedCampaigns} userInfo={props.userInfo} title="Afsluttede kampagner" />
        </div>
      </div>
    );
}

export default CampaignsPage;
