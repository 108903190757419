import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import createPersistentStorage from "../persistent-storage";
import {CreateUserDto, User} from "./models/User";
import {AuthTokensDto} from "./models/AuthTokensDto";
import {Trace} from "./models/Trace";
import {WasteEntryDto} from "./models/WasteEntryDto";
import {react_app_backend_url} from "../globalApi";
import {LogoResponseDto} from "../models/LogoResponseDto";
import {Campaign} from "../models/Campaign";
import {Log} from "../models/Log";
import {CurrentStatus} from "../models/CurrentStatus";

const superAccessTokenStorage = createPersistentStorage<string>("super_admin_access_token");

const orgCreate = axios.create;
axios.create = function (config?: AxiosRequestConfig): AxiosInstance {

    const result = orgCreate.call(this, config);
    (axios.interceptors.request as any).handlers.forEach((handler: any) => {
        result.interceptors.request.use(handler.fulfilled, handler.rejected);
    });
    (axios.interceptors.response as any).handlers.forEach((handler: any) => {
        result.interceptors.response.use(handler.fulfilled, handler.rejected);
    });

    return result;
}

const adminAxios = axios.create();

adminAxios.interceptors.request.use(function (config) {
    if (config?.headers) {
        const token = superAccessTokenStorage.read();
        if (token !== undefined) {
            config.headers.Authorization = 'Bearer ' + superAccessTokenStorage.read();
        }
        config.responseEncoding = "utf-8"
        config.headers.responseEncoding = "utf-8"
        config.headers.charset = "utf-8";
    }

    return config;
});

adminAxios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response?.status === 403 || error.response?.status === 401) {
            console.error("Authorization error: " + error.response.status)
            window.location.href = "/super-admin/login";
        }

        return Promise.reject(error.response ? {
            status: error.response.status,
            statusText: error.response.statusText,
            message: error.response.data?.detail
        } : {
            status: "unkown",
            message: error.message
        })
    });

const backendUrl = react_app_backend_url + "super_admin/";

export function getUsers() : Promise<{data: User[], status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + "get_users")
}

export function getBearerTokensForUser(userId: string) : Promise<{data: AuthTokensDto, status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + `get_bearer_tokens?user_id=${userId}`)
}

export function createUser(new_user: CreateUserDto) : Promise<{data: User, status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.post(backendUrl + `users`, new_user)
}

export function updateUser(user: User) : Promise<{data: any, status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.put(backendUrl + `users/${user.id}`, user)
}

export function getLogo(userId: string): Promise<LogoResponseDto> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + `logos/${userId}`);
}

export function setLogo(formData: FormData, userId: string, altLogo: boolean = false): Promise<string> {
    const client = applyCaseMiddleware(adminAxios);

    return client.put(backendUrl + `logos/${userId}/${altLogo ? "altLogo" : "logo"}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

export function getLogs(fromYMD: string): Promise<{data: Log[], status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + `logs?from_str=${fromYMD}`);
}

export async function getCurrentStatusForAllUsers(): Promise<{data: CurrentStatus[], status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    // AxiosRequestConfig
    // let tmp = await client.get(backendUrl + `current_status_for_all`, {
    //     responseEncoding: 'utf-8'});
    // debugger;
    return client.get(backendUrl + `current_status_for_all`);
}

export function getCampaigns(): Promise<{data: Campaign[], status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + `campaigns`);
}

export function updateCampaign(campaign: Campaign): Promise<{data: Campaign, status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.put(backendUrl + `campaigns/${campaign.id}`, campaign);
}


export function createNewCampaign(campaign: Campaign): Promise<{data: Campaign, status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.post(backendUrl + `campaigns`, campaign);
}

export function forceStatsUpdate(): Promise<{data: any, status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + `forceStats`);
}

export function updateCampaignStatus(): Promise<{data: any, status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + `updateCampaignStatus`);
}

export function getAllUserdataXML(): Promise<{data: any, status: number}> {
    const client = applyCaseMiddleware(adminAxios);
    return client.get(backendUrl + `full-xml-data`, {
        responseType: 'blob', // Important: This tells Axios to handle the response as a Blob
    });
}
