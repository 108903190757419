import React, {FC, useEffect, useState} from 'react';
import "./SoloMealGoalsCampaign.scss"
import {Stats} from '../../../models/Stats';
import {Campaign, SoloMealGoalResult} from "../../../models/Campaign";
import {dayName3String, monthNameString} from "../../../utils/dateUtils";
import {UserInfo} from "../../../models/UserInfo";
import NervousIcon from "./Nervous.svg"
import SadSmileyIcon from "./FailedSmiley.svg";

interface SoloMealGoalsCampaignProps {
    stats: Stats | undefined,
    campaign: Campaign | undefined,
    userInfo: UserInfo
}

interface MeterSettings {
    currentLimitPx: number,
    gradientMid: number,
    meterWidthPx: number,
    greenUntil: number,
    currentRealPosPx: number,
    whiteBoxClampRight: boolean,
    showWarningBox: boolean,
    showWarningLine: boolean,
    overweight: number,
    warningBoxClampRight: boolean,
    realCurrentLimitPx: number,
    failed: boolean
}
const SoloMealGoalsCampaign: FC<SoloMealGoalsCampaignProps> = (props) => {
    const [ result, setResult ] = useState<SoloMealGoalResult>();
    const [ remainingDays, setRemainingDays ] = useState(0);
    const [ meterProps, setMeterProps ] = useState<MeterSettings>({} as MeterSettings)

    useEffect(() => console.log("PROPS", props), [props])

    useEffect(() => {
        setRemainingDays(result ? result.remainingDays : 0);
    })

    useEffect(() => setResult(props.campaign?.results ? props.campaign.results[props.userInfo.id] as SoloMealGoalResult : undefined), [props.campaign?.results, props.userInfo.id])

    useEffect(() => {
        if (result === undefined) {
            return;
        }
        let fullWidthPx = 518;
        let realCurrentLimitPx =  fullWidthPx * result.currentDayGoal/result.totalGoal
        let currentLimitPx = Math.max(130, realCurrentLimitPx)
        let currentRealPosPx = fullWidthPx * result.currentDayWeight / result.totalGoal;
        let meterPosPx = Math.max(82, fullWidthPx * result.currentDayWeight / result.totalGoal);
        let greenUntilPx = currentLimitPx - 80;

        let showWarningLine = result.currentDayWeight - result.currentDayGoal > 0 || // if we're past goal
            (meterPosPx > 150 && result.currentDayGoal - result.currentDayWeight < result.currentDayGoal / (result.measuredDaysUntilNow || 1))

        let warningBoxClampRight = currentLimitPx > (fullWidthPx - 180);

        let failed = false;
        if (result.currentDayWeight > result.totalGoal) {
            failed = true;
            showWarningLine = false;
        }
        setMeterProps({
            meterWidthPx: Math.min(meterPosPx, fullWidthPx),
            currentLimitPx: currentLimitPx,
            greenUntil: greenUntilPx,
            whiteBoxClampRight: meterPosPx > 220,
            showWarningLine: showWarningLine,
            currentRealPosPx: currentRealPosPx,
            showWarningBox: !failed && result.currentDayWeight - result.currentDayGoal > 0,
            overweight: result.currentDayWeight - result.currentDayGoal,
            warningBoxClampRight: warningBoxClampRight,
            realCurrentLimitPx: realCurrentLimitPx,
            failed: failed
        } as MeterSettings)
    }, [result])

    const convert_result = () => {

    }
    return (
        <div className="SoloMealGoalsCampaign">
            { result &&
                <>
                    <div className="tinyTopText">Bekæmp madspildet i {monthNameString(props.campaign?.monthIndex || 0)}</div>
                    <div className="remainingText">{remainingDays} måledag{remainingDays === 1 ? "" : "e"} tilbage</div>
                    <div className="center">
                        <div className="barArea">
                            <div className="bar">
                                <div className="backgroundBar">
                                </div>
                                <div className={`meter ${meterProps.failed ? "failed": ""}`} style={
                                    meterProps.failed ?
                                        { background: "#FF6636", width: meterProps.meterWidthPx } :
                                        {background: `linear-gradient(90deg, #78E7A0 0%, #78E7A0 ${meterProps.greenUntil}px, #FF6636 ${meterProps.currentLimitPx}px)`, width: meterProps.meterWidthPx}
                                }>
                                    <div className="whiteBox" style={ (meterProps.whiteBoxClampRight ? { right: "8px" } : { left: "8px" })}>
                                        {result?.currentDayWeight?.toFixed(1) || 0} kg
                                    </div>
                                </div>
                                { meterProps.failed &&
                                    <img className="failedSmiley" src={SadSmileyIcon} alt="" />
                                }
                                { meterProps.showWarningBox &&
                                    <div className="warningBox" style={ (meterProps.warningBoxClampRight ? { right: "8px" } : { left: `clamp(8px, ${meterProps.currentRealPosPx-50}px, 500px)` })}>
                                        <img src={NervousIcon} alt=""/>
                                        { meterProps.overweight > 0 && <span>{meterProps.overweight.toFixed(1)} kg for meget</span>}
                                    </div>
                                }
                                { meterProps.showWarningLine &&
                                    <div className="warningLine" style={{left: `${meterProps.realCurrentLimitPx}px`}}></div>
                                }
                            </div>
                        </div>
                        <div className="monthLimit red">
                            <div className="header">Månedens grænse</div>
                            <div className="weight">{result.totalGoal && result.totalGoal.toFixed(2)} kg</div>
                        </div>
                    </div>
                    <div className="bottom">
                        { props.stats?.thisWeek.slice(0, 5).map(day =>
                            <div key={day.ymd} className="dayEntry">
                                <div className={`weight ${day.kg && day.kg > day.goalKg ? "red" : "green"}`}>{day.kg !== null ? `${day.kg.toFixed(1)} kg` : "-"}</div>
                                <div className="dayName">{dayName3String(day.ymd)}</div>
                            </div>
                        )}
                    </div>
                </>
            }
        </div>
    );
}

export default SoloMealGoalsCampaign;
