import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/da'
dayjs.extend(utc);
dayjs.extend(relativeTime)
dayjs.locale('da')

export function toDate(date: string): Date {
    dayjs.extend(utc);
    return dayjs.utc(date).local().toDate()
}
export function localFormat(date: string): string {
    dayjs.extend(utc);
    return dayjs.utc(date).local().format("YYYY-MM-DD HH:mm:ss")
}

export function toYmd(date: Date | string): string {
    dayjs.extend(utc);
    return dayjs.utc(date).local().format("YYYY-MM-DD")
}

export function monthNameString(monthIndex: number): string {
    return dayjs(`2000-${monthIndex+1}-01`).format("MMMM");
}

export function dayName3String(ymdDate: string): string {
    return dayjs(ymdDate).format("ddd");
}

export function minutesDiff(date: string | undefined): number {
    return dayjs.utc().local().diff(dayjs.utc(date).local(), "minutes")
}
export function timeAgo(date: string | undefined): string {
    if (date === undefined) return ""
    return dayjs.utc(date).local().fromNow()
}

export function localPrettyPrint(date: string): string {
    return dayjs.utc(date).local().format("DD/MM/YYYY HH:mm:ss")
}

export function prettyPrintDateOnly(date: Date | string): string {
    return dayjs.utc(date).local().format("dddd DD MMM")
}

export function getDaysAgoDate(days: number): Date {
    return dayjs().subtract(days, 'day').toDate()
}
