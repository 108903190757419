import React, {FC, useEffect, useState} from 'react';
import styles from './MainBox.module.scss';
import {CurrentStatus} from "../../../models/CurrentStatus";
import moonIcon from './moon.svg'
import StreakBox from "../StreakBox/StreakBox";
import {Stats} from "../../../models/Stats";
import {getMealName} from "../../../models/Meal";

interface MainBoxProps {
    currentStatus: CurrentStatus | undefined,
    stats: Stats | undefined
}
const MainBox: FC<MainBoxProps> = (props) => {
    const { currentStatus } = props;
    const [ currentWeight, setCurrentWeight ] = useState<number | undefined>();
    const [ inactive, setInactive ] = useState<boolean>(true);
    const [ currentWeightFormatted, setCurrentWeightFormatted ] = useState("-");
    const [ currentSaved, setCurrentSaved ] = useState("-");
    const [ mealName, setMealName ] = useState("");
    const [ goalWasteFormatted, setGoalWasteFormatted ] = useState("-");
    const [ goalWasteKg, setGoalWasteKg ] = useState<number>();
    const [ tick25, setTick25 ] = useState(0);
    const [ tick50, setTick50 ] = useState(0);
    const [ tick75, setTick75 ] = useState(0);
    const [ speedometerValue, setSpeedometerValue ] = useState(0);
    const [ isAboveLimit, setIsAboveLimit ] = useState(false);



    // useEffect(() => {
    //     setMealName(currentStatus?.currentMeal?.name ? currentStatus?.currentMeal.name.toLocaleLowerCase() : "");
    // }, [currentStatus?.currentMeal?.name ]);
    //
    // useEffect(() => {
    //     setGoalWasteFormatted(currentStatus?.currentMeal?.goalWaste ?
    //         (currentStatus.currentMeal.goalWaste * currentStatus.currentMeal.defaultNumDining).toFixed(1) : "-");
    // }, [currentStatus?.currentMeal?.goalWaste ]);


    useEffect(() => {
        if (!currentStatus?.currentMeal) {
            setGoalWasteKg(undefined)
            setMealName("");
            setGoalWasteFormatted("-")
        } else {
            setMealName(getMealName(currentStatus.currentMeal.mealType))
            const gw = (currentStatus.currentMeal.goalWaste * currentStatus.currentMeal.defaultNumDining)
            setGoalWasteKg(gw)
            setGoalWasteFormatted(gw.toFixed(1))
        }
    }, [currentStatus?.currentMeal]);


    useEffect(() => {
        setCurrentWeight(currentStatus?.measuredWeight === undefined ? undefined : currentStatus.measuredWeight)
        setCurrentWeightFormatted(currentStatus?.measuredWeight !== undefined ? currentStatus.measuredWeight.toFixed(1) : "-");
    }, [currentStatus?.measuredWeight])


    useEffect(() => {
        if (goalWasteKg && currentWeight !== undefined) {
            let diff: number = goalWasteKg - currentWeight;
            setCurrentSaved(Math.abs(diff).toFixed(1))
            setIsAboveLimit(diff < 0);
        } else {
            setCurrentSaved("-");
            setIsAboveLimit(false);
        }

    }, [goalWasteKg, currentWeight ]);

    useEffect(() => {
        let goalWaste = goalWasteKg || 20;
        let percent100 = goalWaste / 0.65; // assume goal is at 65% mark
        setTick25(Math.round(percent100 * 0.25));
        setTick50(Math.round(percent100 * 0.5));
        setTick75(Math.round(percent100 * 0.75));

        if (currentWeight) {
            let speedometerPercent = currentWeight / percent100;
            setSpeedometerValue(Math.ceil(speedometerPercent * 198));
        } else {
            setSpeedometerValue(0)
        }
    }, [goalWasteKg, currentWeight ]);

    useEffect(() => {
        setInactive(!!currentStatus?.blacklistedMeal);
    }, [currentStatus?.blacklistedMeal])

    return (
        <div className={styles.MainBox}>
            <div className="outerBox">
                <div className={`innerBox blueBox  ${currentStatus?.blacklistedMeal ? 'inactive' : ''}`}>
                    <div className="speedoMeter">
                        {/*based off of https://jsfiddle.net/9005q67j/ (see also https://stackoverflow.com/questions/50649381/svg-arc-progress-bar-with-constant-stroke-dasharray-object)*/}
                        <svg viewBox="0 0 100 100">
                            <linearGradient id="gradient" x1="30%" y1="0" x2="70%" y2="0">
                                <stop offset="0%" stopColor="#78e7a0" />
                                <stop offset="100%" stopColor="#e77878" />
                            </linearGradient>
                            <defs>
                                <filter id="f1">
                                    <feGaussianBlur in="SourceGraphic" stdDeviation="1.5" edgeMode="true" />
                                </filter>
                            </defs>
                            <path className="meterBackground" d="M 25 90 A 40 40 0 1 1 80 90" fill='none'/>
                            <path className="meter" style={ {strokeDasharray: `${speedometerValue}, 198px` }} d="M25,90 A40,40 0 1,1 80,90" fill='none'/>
                            <path filter="url(#f1)" style={ {strokeDasharray: `${speedometerValue}, 198px` }} className="meter" d="M25,90 A40,40 0 1,1 80,90" fill='none' opacity="0.6"/>
                        </svg>
                    </div>
                    <div className="centerText">
                        <div className={`savedInfoBox ${isAboveLimit ? "red" : ""}`}>{inactive ? "0,0" : currentSaved} kg {isAboveLimit ? "over" : "fra"} grænsen</div>
                        <div className="mainText"><span className="weight">{inactive ? "0,0" : currentWeightFormatted}</span> <span className="unit">kg</span></div>
                        <div className="subText">Spildt {inactive ? "mad" : mealName}</div>
                    </div>
                    {!inactive && (
                        <div className="goalBox">
                            <img src={moonIcon} alt="" />
                            <div className="goalText">
                            <span className="goalTop">Grænse for måltid</span>
                            <h3 className="goalBottom">{goalWasteFormatted} kg</h3>
                            </div>
                        </div>
                    )}
                    <div className="tick tick0">0 kg</div>
                    <div className="tick tick25">{tick25} kg</div>
                    <div className="tick tick50">{tick50} kg</div>
                    <div className="tick tick75">{tick75} kg</div>
                    <StreakBox stats={props.stats} />
                </div>
            </div>
        </div>
    );
}

export default MainBox;
