import React, {FC, useEffect, useState} from 'react';

import DatePicker, {DateObject} from "react-multi-date-picker";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {getBlacklist, getStatsStartDate, updateStatsStartDate} from "../../adminApi";
import {Form} from "react-bootstrap";
import './DatesPage.scss';
import BlacklistManager from "./BlacklistManager";
import {User} from "../../../superAdmin/models/User";

interface DatesPageProps {
    userInfo?: User
}

const DatesPage: FC<DatesPageProps> = (props) => {
    const [ errorMsg, setErrorMsg ] = useState<string>("");
    const [ currentBlacklist, setCurrentBlacklist ] = useState<DateObject[]>([]);
    const [ originalBlacklist, setOriginalBlacklist ] = useState<DateObject[]>([]);
    const [ blacklistModified, setBlacklistModified ] = useState(false);

    const [ currentStartDate, setCurrentStartDate ] = useState<DateObject>(new DateObject());
    const [ originalStartDate, setOriginalStartDate ] = useState<DateObject>(new DateObject());
    const [ startDateModified, setStartDateModified ] = useState(false);

    useEffect(() => {
        let mounted = true;
        getBlacklist().then((res: any) => {
                if (mounted) {
                    if (res && res.status === 200) {
                        var blacklist =(res.data?.blacklist || []).map((x: string) => new DateObject().setFormat("YYYY-MM-DD").parse(x));
                        setOriginalBlacklist(blacklist);
                        setCurrentBlacklist(blacklist);
                    } else {
                        setErrorMsg("Kunne ikke hente listen over datoer der ikke skal måles på. Load siden igen");
                    }
                }
            })
        return () => { mounted = false };
    }, [])

    useEffect(() => {
        let mounted = true;
        getStatsStartDate().then((res: any) => {
            if (mounted) {
                if (res && res.status === 200) {
                    let dateString = res.data;
                    let from = dateString ? new DateObject().setFormat("YYYY-MM-DD").parse(dateString): new DateObject();
                    setOriginalStartDate(from);
                    setCurrentStartDate(from);
                } else {
                    setErrorMsg("Kunne ikke hente start dato form statestik beregninger. Load siden igen");
                }
            }
        })
        return () => { mounted = false };
    }, [])

    const saveStartDate = async () => {
        setErrorMsg("");
        try {
            await updateStatsStartDate(currentStartDate.format("YYYY-MM-DD"));
            setStartDateModified(false);
        } catch (e) {
            setErrorMsg("Kunne ikke gemme dato for statestik beregning")
        }
    }

    // const saveBlacklist = async () => {
    //     setErrorMsg("");
    //     try {
    //         await updateBlacklist(currentBlacklist.map(x => x.format("YYYY-MM-DD")))
    //         setBlacklistModified(false);
    //     } catch (e) {
    //         setErrorMsg("Kunne ikke gemme listen over datoer der ikke skulle måles")
    //     }
    // }

    // const resetBlacklist = async () => {
    //     setErrorMsg("");
    //     setBlacklistModified(false);
    //     setCurrentBlacklist(originalBlacklist);
    // }

    const resetStatsStartDate = async () => {
        setErrorMsg("");
        setStartDateModified(false);
        setCurrentStartDate(originalStartDate);
    }

    const arrayify = (x: DateObject[] | DateObject | null): DateObject[] =>  x == null ? [] : x instanceof DateObject ? [x] : x;

    const markAllWeekends = () => {
        setErrorMsg("");
        console.log(currentBlacklist);
        let daysLater = (date: Date, days=1): Date => {
            let newDate = new Date(date);
            newDate.setDate(newDate.getDate() + days);
            return newDate;
        }

        let weekendDays: Date[] = [];
        // find next saturday (saturday is getDay()=6):
        let saturday = daysLater(new Date(), 6 - new Date().getDay());
        // get the next 100 weekends:
        for (let i=0; i<100; i++) {
            weekendDays.push(saturday);
            weekendDays.push(daysLater(saturday, 1));
            saturday = daysLater(saturday, 7);
        }

        let mergeDedup = (a: DateObject[], b: DateObject[]): DateObject[] => {
            let filtered = a.filter(aNeedle => !b.find(haystack => haystack.toString() === aNeedle.toString()));
            return filtered.concat(b);
        }

        setCurrentBlacklist(prev => mergeDedup(weekendDays.map(x => new DateObject(x)), prev));
        setBlacklistModified(true);
    }

    return (
      <div className="DatesPage">
        <div className="adminFrame">
            <h1>Datoer</h1>
            { errorMsg && <Alert variant="danger">{errorMsg}</Alert> }
            <hr />
            <BlacklistManager userInfo={props.userInfo}/>
            {/*<h3>Datoer der <b>ikke</b> skal måles på</h3>*/}
            {/*<i>Vælg alle de datoer hvor målinger ikke skal gemmes. Hvis en dato står på denne liste betyder det*/}
            {/*at mængden af mad der bliver smidt ud ikke gemmes for den pågældende dag, og derved heller indgår i diverse*/}
            {/*statistikker.</i>*/}
            {/*<div className="datesArea">*/}
            {/*    <Calendar*/}
            {/*        className="calendar1"*/}
            {/*        weekStartDayIndex={1}*/}
            {/*        displayWeekNumbers={true}*/}
            {/*        value={currentBlacklist}*/}
            {/*        onChange={x => {*/}
            {/*            setCurrentBlacklist(arrayify(x));*/}
            {/*            setBlacklistModified(true);*/}
            {/*    }}/>*/}
            {/*    <Button variant="outline-warning" onClick={markAllWeekends}>Markér alle weekender</Button>*/}
            {/*</div>*/}
            {/*<div className="standardButtons">*/}
            {/*    <Button variant="primary" onClick={saveBlacklist} disabled={!blacklistModified}>Gem</Button>*/}
            {/*    <Button variant="warning" onClick={resetBlacklist} disabled={!blacklistModified}>Fortryd</Button>*/}
            {/*</div>*/}
            <hr />
            <h3>Periode</h3>
            <i>Vælg den periode hvorfra der skal beregnes statistik. Dette vil typisk være fra starten af et skoleår. Alle mål, samlet
            mængde spild, osv., beregnes ud fra dette interval.</i>
            <div className="periodCalendars">
                <div className="labelInput">
                    <Form.Label htmlFor="fromDate">Fra</Form.Label>
                    <DatePicker value={currentStartDate} onChange={x => {
                        if (x instanceof DateObject) {
                            setCurrentStartDate(x)
                            setStartDateModified(true);
                        }
                    }} />
                </div>
            </div>
            <div className="standardButtons">
                <Button variant="primary" onClick={saveStartDate} disabled={!startDateModified}>Gem</Button>
                <Button variant="warning" onClick={resetStatsStartDate} disabled={!startDateModified}>Fortryd</Button>
            </div>
        </div>
      </div>
    );
}

export default DatesPage;
