import React, {FC, useEffect, useState} from 'react';
import './TickCard.scss';
import {getIcon, IconFunctions, Icons} from "../../../../dashboard/components/TickerSymbol/TickerSymbol";
import {TickModel} from "../../../../models/TickModel";
// import "./style.css";
import Dropdown from 'react-bootstrap/Dropdown';
import {Col, Form, FormControl} from "react-bootstrap";
import {TiDelete} from 'react-icons/ti';

let options: any[] = [];
for (let item in Icons) {
    if (isNaN(Number(item))) {
        options.push({label: item, icon: getIcon(IconFunctions.fromString(item))})
    }
}

interface TickCardProps {
    tick: TickModel,
    id: number,
    onChange: (tick: TickModel, id: number) => any,
    onDelete: (id: number) => any
}

const TickCard: FC<TickCardProps> = (props) => {
    const [selectedType, setSelectedType] = useState<Icons>(Icons.Stat);

    useEffect(() => {
        setSelectedType(props.tick.icon)
    }, [props.tick?.icon]);

    const triggerChange = (update: { [key: string]: boolean | string | Icons }) =>  {
        let toReturn = Object.assign({}, props.tick, update);
        props.onChange(toReturn, props.id);
    };

    return (
        <div className="TickCard">
            <div>
                <Dropdown onSelect={x => triggerChange({icon: IconFunctions.fromString(x as string) as Icons})}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-left"  disabled={!props.tick.active}>
                        <img src={getIcon(selectedType)} alt="icon"/>
                    </Dropdown.Toggle>
                        <Dropdown.Menu>
                        {options.map(({ label, icon }) => (
                            <Dropdown.Item key={label} eventKey={label}><img src={icon} alt="icon" /></Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="textArea">
                <FormControl type="text" required value={props.tick.text} onChange={(x: any) => triggerChange({ text: x.target.value})}/>
            </div>
            <div className="align-items-center">
                <TiDelete className="DeleteItem" onClick={() => props.onDelete(props.id)} />
            </div>
        </div>
    )
};

export default TickCard;
