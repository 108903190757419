import React, {FC, ReactNode, useEffect, useState} from 'react';
import './UserCampaignCard.scss';
import dayjs, {Dayjs} from "dayjs";
import {Campaign, CampaignTypes} from "../../../models/Campaign";
import {AiFillCheckCircle} from "react-icons/ai";
import {FaClock} from "react-icons/fa";
import {capitalizeFirstLetter, classGoalIsMinimize, getSuccessClass, withDecimals} from "../../../utils/utils";
import {User} from "../../../superAdmin/models/User";
import {BiSad} from "react-icons/bi";
import {SoloMealGoalDetails} from "./SoloMealGoalDetails";
import {userInfo} from "os";
import {GroupCampaignDetails} from "./GroupCampaignDetails";
import {isGroupType} from "../../../utils/campaignUtils";

const locale = require('dayjs/locale/da')
dayjs.locale(locale);

interface UserCampaignCardProps {
    campaign: Campaign,
    userInfo: User,
    headerOnly?: boolean
}

export enum CampaignState {
    completed,
    active,
    pending
}
const UserCampaignCard: FC<UserCampaignCardProps> = (props) => {
    const [campaignState, setCampaignState] = useState<CampaignState>(CampaignState.pending)
    const [campaignDate, setCampaignDate ] = useState<Dayjs>(dayjs())
    const [results, setResults ] = useState<any>({})


    useEffect(() => {
        const thisDate = dayjs(new Date(dayjs().year(), dayjs().month(), 1));
        const campaignDate = dayjs(new Date(props.campaign.year, props.campaign.monthIndex, 1));
        setCampaignDate(campaignDate);
        setCampaignState(campaignDate.isSame(thisDate) ? CampaignState.active : campaignDate > thisDate ? CampaignState.pending : CampaignState.completed)
    }, [props.campaign])

    return (
        // ${results?.success == true ? "success" : results?.success === false ? "failed" : ""}
        <div className={`userCampaignCard ${campaignState === CampaignState.active ? "active" : campaignState === CampaignState.completed ? "completed" : "pending"} `}>
            <div className="header">
                <h6>{capitalizeFirstLetter(campaignDate.format("MMMM YYYY"))}</h6>
                <h5>{props.campaign.name}</h5>
            </div>
            { !props.headerOnly &&
                <div className="results">
                    { props.campaign.type === CampaignTypes.SoloMealGoals && <SoloMealGoalDetails campaignState={campaignState} campaignDate={campaignDate} campaign={props.campaign} userInfo={props.userInfo} /> }
                    { isGroupType(props.campaign) && <GroupCampaignDetails campaignState={campaignState} campaignDate={campaignDate} campaign={props.campaign} userInfo={props.userInfo} /> }
                </div>
            }


        </div>
    )
}

export default UserCampaignCard;
