import React, {FC, useEffect, useState} from "react";
import {AiOutlineCheck, AiOutlineClose, AiOutlineEdit} from "react-icons/ai";
import Select from "react-select";

interface EditableSelectProps {
    multi?: boolean
    selected_id?: string
    selected_ids?: string[]
    options: {value: string, label: string}[]
    selectionUpdated: (id: string | string[]) => any;
}

const EditableSelect: FC<EditableSelectProps> = (props) => {
    const [selected, setSelected] = useState<any>()
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [selectedBeforeEdit, setSelectedBeforeEdit ] = useState<any>();

    // create selected object based on input in props
    useEffect(() => {
        if (!props.multi && props.selected_id && props.options) {
            let selected = props.options.find(x => x.value === props.selected_id);
            setSelected(selected);
        } else if (props.multi && props.selected_ids && props.options) {
            let selected = props.options.filter(x => props.selected_ids?.find(sid => sid === x.value));
            setSelected(selected || []);
        }
    }, [props.selected_id, props.selected_ids, props.options, props.multi])

    const startEditMode = () => { setSelectedBeforeEdit(props.selected_id); setIsEditing(true); }
    const endEditMode = () => { setIsEditing(false); }
    const cancel = () => { endEditMode(); props.selectionUpdated(selectedBeforeEdit); }


    return (
        <span style={{marginLeft: "4px"}}>
            { !isEditing && !props.multi &&
                <span style={{cursor: "pointer"}} onClick={() => startEditMode()}>{selected?.label}<AiOutlineEdit /></span>
            }
            { !isEditing && props.multi && selected && selected.length !== 0 &&
                <div style={{cursor: "pointer", display: "flex", alignItems: "baseline"}} onClick={() => startEditMode()}>
                    <ul style={{marginBottom: "0px", marginRight: "10px"}}>
                        { selected.map((s:any) => <li key={s.value}>{s.label}<br /></li>) }
                    </ul>
                    <AiOutlineEdit />
                </div>
            }
            { !isEditing && props.multi && !(selected?.length) &&
                <span style={{cursor: "pointer"}} onClick={() => startEditMode()}><AiOutlineEdit /></span>
            }
            { isEditing &&
                <span style={{width: "100%", display: "flex", color: "black"}}>
                    <div style={{minWidth: '300px'}}>
                        <Select
                                onChange={x => props.selectionUpdated(props.multi ?  x.map((y: any) => y.value) : x.value)}
                                defaultValue={selected}
                                options={props.options}
                                // onBlur={triggerTextUpdated}
                                onKeyDown={event => event.key === 'Enter' && endEditMode()}
                                isSearchable={true}
                                isMulti={props.multi}
                                isClearable={!props.multi}
                        />
                    </div>
                    <AiOutlineCheck onClick={endEditMode} style={{fontSize: "10px", marginLeft: "4px", color: "white"}}/>
                    <AiOutlineClose onClick={cancel} style={{fontSize: "10px", marginLeft: "4px", color: "white"}}/>
                </span>
            }
        </span>
    )
}

export default EditableSelect;
