import React, {FC, useState} from "react";
import {AiOutlineCheck, AiOutlineClose, AiOutlineEdit} from "react-icons/ai";

interface EditableTextProps {
    text: string
    textUpdated: (val: string) => any;
}

const EditableText: FC<EditableTextProps> = (props) => {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [textBeforeEdit, setTextBeforeEdit] = useState<string>("")

    const startEditMode = () => { setTextBeforeEdit(props.text); setIsEditing(true); }
    const endEditMode = () => { setIsEditing(false); }
    const cancel = () => { endEditMode(); props.textUpdated(textBeforeEdit)}

    return (
        <span style={{marginLeft: "4px"}}>
            { !isEditing &&
                <span style={{cursor: "pointer"}} onClick={() => startEditMode()}>
                    {props.text} <AiOutlineEdit />
                </span>
            }
            { isEditing &&
                <span>
                    <input value={props.text}
                           onChange={event => props.textUpdated(event.target.value)}
                           onKeyDown={event => event.key === 'Enter' && endEditMode()}
                    />
                    <AiOutlineCheck onClick={endEditMode} style={{fontSize: "10px", marginLeft: "4px"}}/>
                    <AiOutlineClose onClick={cancel} style={{fontSize: "10px", marginLeft: "4px"}}/>
                </span>
            }
        </span>
    )
}

export default EditableText;
