import dayjs, {Dayjs} from "dayjs";
import {GeneralStats, MealTypes, Stat, TableData} from "./models";
import {HistoricData} from "./models";
import weekOfYear from 'dayjs/plugin/weekOfYear'
import {Simulate} from "react-dom/test-utils";
import input = Simulate.input; // import plugin
var locale = require('dayjs/locale/da')
dayjs.locale(locale);
dayjs.extend(weekOfYear)

export class StatsData {
    public dailyData: TableData[] = [];
    public weeklyData: TableData[] = [];
    public monthlyData: TableData[] = [];
    public mealTypes: MealTypes[] = [];
    public generalStats?: GeneralStats;

    constructor(inputData: HistoricData[]) {
        this.initMealTypes(inputData);
        this.initDailyData(inputData);
        this.weeklyData = this.summaryData(d => `${this.getWeek(d)}`, d => `Uge ${this.getWeek(d)}`);
        this.monthlyData = this.summaryData(d => `${d.month()}`, d => d.format("MMMM"));
        this.generalStats = this.calculateKeyNumbers(inputData);
    }

    compareDays = (a: Dayjs, b: Dayjs) => a.startOf('d').diff(b.startOf('d'), 'days')

    initMealTypes = (inputData: HistoricData[]) => {
        let mealTypes = new Set<MealTypes>();
        for (let f of inputData) {
            mealTypes.add(f.mealType);
        }
        this.mealTypes = Array.from(mealTypes);
    }

    initDailyData = (inputData: HistoricData[]) => {
        if (inputData.length < 2) {
            return;
        }
        const startDate = dayjs(inputData[0].time);
        let filteredHistoricDataIndex = 0;
        let currentDate = dayjs(startDate);

        let dailyTable: TableData[] = [{
            title: currentDate.format("dddd, D. MMM 'YY"),
            date: currentDate.format("YYYY-MM-DD"),
            meals: {},
        }];

        while (filteredHistoricDataIndex < inputData.length) {
            const d = dayjs;
            const dayDiff = this.compareDays(dayjs(inputData[filteredHistoricDataIndex].time), currentDate);
            if (dayDiff < 0) {
                console.error("Something went wrong when generating dataset");
                return;
            } else if (dayDiff === 0) {
                let histEntry = inputData[filteredHistoricDataIndex];
                let meals = dailyTable[dailyTable.length-1].meals;
                meals[histEntry.mealType] = {
                    absoluteWeight: histEntry.weight,
                    numDining: histEntry.numDining,
                    relativeWeight: histEntry.numDining === 0 ? 0 : 1000*histEntry.weight / histEntry.numDining
                }
                filteredHistoricDataIndex++;
                continue;
            }

            currentDate = currentDate.add(1, "day");
            dailyTable.push({
                title: currentDate.format("dddd, D. MMM 'YY"),
                date: currentDate.format("YYYY-MM-DD"),
                meals: {},
                highlight: currentDate.day() === 0 || currentDate.day() === 6 // highlight if weekend
            })
        }

        for (let day of dailyTable) {
            if (Object.keys(day.meals).length > 0) {
                day.totals = {
                    absoluteWeight: 0,
                    relativeWeight: 0,
                    numDining: 0
                }

                for (let meal of Object.values(day.meals)) {
                    day.totals.absoluteWeight += meal.absoluteWeight;
                    day.totals.numDining += meal.numDining;
                    day.totals.relativeWeight += meal.relativeWeight;
                }
            }
        }

        this.dailyData = dailyTable;
    }

    summaryData = (getKey: (date: Dayjs) => string, getTitle: (date: Dayjs) => string): TableData[] => {
        if (this.dailyData?.length == 0) {
            return [];
        }

        let currentKey: string = "!";
        let currentEntry: TableData = {} as TableData;
        const retData: TableData[] = [];

        for (let day of this.dailyData) {
            if (currentKey !== getKey(dayjs(day.date, "YYYY-MM-DD"))) {
                currentKey = getKey(dayjs(day.date, "YYYY-MM-DD"));
                currentEntry = {
                    date: "...",
                    title: getTitle(dayjs(day.date, "YYYY-MM-DD")),
                    meals: {},
                    totals: {
                        absoluteWeight: 0,
                        relativeWeight: 0,
                        numDining: 0
                    }
                }
                retData.push(currentEntry);
            }

            let meals = day.meals;
            for (let mealType of Object.keys(meals) as MealTypes[]) {
                if (currentEntry.meals[mealType] === undefined) {
                    currentEntry.meals[mealType] = {
                        absoluteWeight: 0,
                        relativeWeight: 0,
                        numDining: 0
                    }
                }

                let source = meals[mealType]
                let target = currentEntry.meals[mealType];

                if (source && target) {
                    target.absoluteWeight += source.absoluteWeight;
                    target.relativeWeight += source.relativeWeight;
                    target.numDining += source.numDining;
                }
            }
            if (currentEntry.totals && day.totals) {
                currentEntry.totals.absoluteWeight += day.totals.absoluteWeight;
                currentEntry.totals.relativeWeight += day.totals.relativeWeight;
                currentEntry.totals.numDining += day.totals.numDining;
            }
        }

        return retData;
    }

    getWeek = (date: Dayjs): number => date.week();


    private calculateKeyNumbers(inputData: HistoricData[]): GeneralStats {
        let stats: GeneralStats = {
            byMeal: {},
            total: {
                totalWaste: 0,
                totalMeals: 0,
                count: 0
            },
            numWeeks: this.weeklyData.length,
            numMonths: this.monthlyData.length,
            numDays: this.dailyData.length
        }

        for (let month of this.monthlyData) {
            if (month.totals) {
                stats.total.totalMeals += month.totals.numDining;
                stats.total.totalWaste += month.totals.absoluteWeight;
            }
            for (let mealTypeAsString of Object.keys(month.meals) as MealTypes[]) {
                let mealType = mealTypeAsString as MealTypes;
                if (!stats.byMeal[mealType]) {
                    stats.byMeal[mealType] = {
                        totalWaste: 0,
                        totalMeals: 0,
                        count: 0
                    }
                }
                let meal = stats.byMeal[mealType];
                if (meal !== undefined) {
                    // @ts-ignore
                    meal.totalWaste += month.meals[mealType]?.absoluteWeight;
                    // @ts-ignore
                    meal.totalMeals += month.meals[mealType]?.numDining;
                }
            }
        }
        for (let d of inputData) {
            if (stats.byMeal[d.mealType]) {
                // @ts-ignore
                stats.byMeal[d.mealType].count++
            }
        }
        return stats;
    }
}
