import React, {FC, FormEvent, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import createPersistentStorage from "../../persistent-storage";
import {useNavigate} from "react-router-dom";
import './Login.scss';
import './../../sharedAdmin.scss'
import {react_app_backend_url} from "../../globalApi";

const accessTokenStorage = createPersistentStorage<string>("admin_access_token");

interface LoginProps {}

export const Login: FC<LoginProps> = () => {
    const [isSubmitted] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        //Prevent page reload
        event.preventDefault();


        let formData = new FormData(event.currentTarget);

        axios.post(react_app_backend_url + "login/access-token",
            formData,
            {headers: {'Content-Type': 'multipart/form-data'}, validateStatus: () => true})
            .then((response) => {
                if (response.status === 200 ) {
                    accessTokenStorage.write(response.data.access_token)
                    window.location.href = "/admin"
                }
                if (response.status !== 200) {
                    setError(response.data.detail)
                }
            }, error => {
                console.error("error", error)
            })
    };

    const navigateToApp = () => navigate("/");

    // JSX code for login form
    const renderForm = (
        <div className="form">
            <span style={{"color":"red"}}>{error}</span>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>Brugernavn </label>
                    <input type="text" name="username" id="username" required/>
                </div>
                <div className="input-container">
                    <label>Kode </label>
                    <input type="password" name="password" id="password" required/>
                </div>
                <div className="button-container">
                    <button type="submit">Log ind</button>
                    <button className="backButton" onClick={navigateToApp}>Tilbage</button>
                </div>
            </form>
        </div>
    );

    return (
        <div className="app">
            <div className="login-form box">
                <h1>Administrator log ind</h1>
                {isSubmitted ? <div>Bruger er blevet logget ind</div> : renderForm}
            </div>
        </div>
    );
};
