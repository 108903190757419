import styles from "./UserPage.module.scss"
import React, {FC, useEffect, useState} from 'react';
import Button from "react-bootstrap/Button";
import {User} from "../models/User";
import {getBearerTokensForUser} from "../superAdminApi";
import createPersistentStorage from "../../persistent-storage";
import TraceTable from "../../admin/TraceTable/TraceTable";
import WasteHistoryTable from "../../admin/WasteHistoryTable/WasteHistoryTable";
import {FaRegEdit} from "react-icons/fa";
import LogoHandler from "../components/LogoHandler/LogoHandler";

const accessTokenStorage = createPersistentStorage<string>("access_token");
const adminAccessTokenStorage = createPersistentStorage<string>("admin_access_token");

interface UserPageProps {
    user: User | undefined,
    onEditUser: (user: User) => any;
}

enum SubPage {
    None,
    Trace,
    LatestWasteHistory
}

const UserPage: FC<UserPageProps> = (props) => {
    const [currentSubPage, setCurrentSubPage] = useState<SubPage>(SubPage.None);
    // const [logo, setLogo] = useState<string|undefined>();

    useEffect(() => {
        let cancel = false;

        if (props.user) {
            getBearerTokensForUser(props.user.id).then(payload => {
                if (cancel) return;
                const tokens = payload.data;
                accessTokenStorage.write(tokens.dashboardToken)
                adminAccessTokenStorage.write(tokens.adminToken)
            });
        }

        return () => {
            cancel = true;
        }
    },[props.user])


    useEffect(() => {
        setCurrentSubPage(SubPage.None);
    },[props.user])

    const gotoUser = () => {
        window.location.href = "/admin"
    }

    const viewTrace = () => {
        setCurrentSubPage(SubPage.Trace);
    }
    return (
        <div className={styles.userPage}>
            {props.user && (
                <div className="superAdminFrame userPage">
                    <h1>{props.user.userName}&nbsp;&nbsp;&nbsp;<FaRegEdit className={styles.pointer} onClick={() => props.onEditUser(props.user as User)}/>
                    </h1>
                    <LogoHandler userId={props.user.id} readOnly={true} isAlt={false} />
                    <LogoHandler userId={props.user.id} readOnly={true} isAlt={true} />
                    <ul>
                        <li><b>Id</b>: {props.user.id}</li>
                        <li><b>Aktiv:</b> {props.user.isActive ? "ja" : "nej"}</li>
                        <li><b>Login:</b> {props.user.email}</li>
                        <li><b>Notifikations email:</b> {props.user.notificationsEmail}</li>
                        <li><b>Email rapporter aktiveret:</b> {props.user.emailReportsEnabled ? "ja" : "nej"}</li>
                        <li><b>RPI key:</b> {props.user.rpiKey}</li>
                    </ul>

                    <Button variant="info" onClick={event => gotoUser()}>Log ind</Button>
                    {/*<Button variant="warning" onClick={event => viewTrace()}>Se vægt trace</Button>*/}
                    {/*<Button variant="primary" onClick={event => setCurrentSubPage(SubPage.LatestWasteHistory)}>Tømnings historik</Button>*/}

                    <div className={styles.subPageArea}>
                        { currentSubPage === SubPage.Trace && (
                            <TraceTable userId={props.user.id}></TraceTable>
                        )}
                        { currentSubPage === SubPage.LatestWasteHistory && (
                            <WasteHistoryTable userId={props.user.id}></WasteHistoryTable>
                        )}
                    </div>

                </div>
            )}
        </div>
    );
}

export default UserPage;
