import React, {FC, useEffect, useState} from 'react';
import "./GroupContestCampaign.scss"
import {Stats} from '../../../models/Stats';
import {Campaign, GroupContestResult, GroupContestUserEntry} from "../../../models/Campaign";
import {UserInfo} from "../../../models/UserInfo";
import GoldMedal from "../../../imgs/medal2_gold.png"
import SilverMedal from "../../../imgs/medal2_silver.png"
import BronzeMedal from "../../../imgs/medal2_bronze.png"
import Dot from "../../../imgs/white-dot.svg"
import {truncateString} from "../../../utils/utils";

interface GroupContestProps {
    stats: Stats | undefined,
    campaign: Campaign | undefined,
    userInfo: UserInfo
}

const GroupContestCampaign: FC<GroupContestProps> = (props) => {
    const [ result, setResult ] = useState<GroupContestResult>();
    const [ userResults, setUserResults ] = useState<GroupContestUserEntry>();
    const [ positions, setPositions] = useState<{ dotsPos: number | undefined, pos: string[]}>()

    useEffect(() => {
        if (props.campaign?.data === undefined || props.userInfo?.id === undefined) {
            return;
        }
        const results = props.campaign.results as GroupContestResult;
        const userResults = results.users[props.userInfo.id]
        const userGroup = results.groups[userResults.groupIndex]

        let dotsPos:  number | undefined;
        let pos = [];
        if (userGroup.placements.length <= 4) {
            pos = userGroup.placements
        } else {
            if (userResults.groupPlacementIndex < 4) { // if top 4, put all entries and dots at the end
                pos = userGroup.placements.slice(0, 4)
                dotsPos = 4;
            } else { // else show first three, then dots, then the user with user position
                pos = userGroup.placements.slice(0,3);
                pos.push(userGroup.placements[userResults.groupPlacementIndex]);
                dotsPos = 3;
            }
        }
        setPositions({ pos, dotsPos })
        setUserResults(userResults)
        setResult(results)
    }, [props.campaign?.results, props.userInfo?.id])


    return (
        <div className="GroupContestCampaign">
            { result && userResults &&
                <>
                    <div className="header">
                        <h3>{result.groups[userResults.groupIndex].name}</h3>
                        <span>{userResults.remainingDays} måledag{userResults.remainingDays > 1 ? "e": ""} tilbage</span>
                    </div>
                    <div className="positionsList">
                        { positions?.pos?.map((posUserId: any, index) =>
                            <React.Fragment key={index}>
                                <div className={index === 0 ? "winner entry" : "entry"}>
                                    <div className="column0">
                                        { index === 0 && result.users[posUserId].avgRelWaste && <img className="column-0" src={GoldMedal} alt="" />}
                                        { index === 1 && result.users[posUserId].avgRelWaste && <img className="column-0" src={SilverMedal} alt="" />}
                                        { index === 2 && result.users[posUserId].avgRelWaste && <img className="column-0" src={BronzeMedal} alt="" />}
                                        { index >= 3 &&
                                            <span className="column-0">
                                                { userResults.groupPlacementIndex >= 4 ? userResults.groupPlacementIndex + 1 : 4 }
                                            </span>
                                        }
                                        { index < 3 && !result.users[posUserId].avgRelWaste &&
                                            <span className="column-0">{result.users[posUserId].groupPlacementIndex+1}</span>
                                        }
                                    </div>
                                    <div className="schoolName">
                                        {truncateString(result.users[posUserId].userName, 20)}
                                    </div>
                                    <div className="weight">
                                        { result.users[posUserId].avgRelWaste === null ? "-" : (1000 * (result.users[posUserId].avgRelWaste || 0)).toFixed(2)} g
                                    </div>
                                </div>
                                { positions.dotsPos === index+1 &&
                                    <div className="dots">
                                        <img src={Dot} alt="" />
                                        <img src={Dot} alt="" />
                                        <img src={Dot} alt="" />
                                    </div>
                                }
                            </React.Fragment>
                        )}
                    </div>
                </>
            }
        </div>
    )
}

export default GroupContestCampaign;
