import React, {useEffect, useState} from 'react';
import './App.scss';
import {
  getCurrentCampaign,
  getCurrentStatus,
  getLogo,
  getStats,
  getTicks,
  getUserInfo,
  getZoomLevel,
  renewToken
} from './dashboardApi'
import TotalSavedBox from "./components/TotalSavedBox/TotalSavedBox";
import TopBar from "./components/TopBar/TopBar";
import BottomBar from "./components/BottomBar/BottomBar";
import MainBox from "./components/MainBox/MainBox";
import LeftBox from "./components/LeftBox/LeftBox";
import {CurrentStatus} from "../models/CurrentStatus";
import {Stats} from "../models/Stats";
import {TickModel} from '../models/TickModel';
import {Campaign} from "../models/Campaign";
import CampaignBox from "./components/CampaignBox/CampaignBox";
import {UserInfo} from "../models/UserInfo";
import {useParams} from "react-router-dom";

const currentStatusInterval = 1000;
const statsInterval = 10000;
const ticksInterval = 10*60*1000;
const tokenInterval = 1000 * 60 * 60 * 24; // every 24 hrs

function App() {
  const [ currentStatus, setCurrentStatus] = useState<CurrentStatus | undefined>();
  const [ stats, setStats ] = useState<Stats | undefined>();
  const [ ticks, setTicks ] = useState<TickModel[]>([]);
  const [ logo, setLogo ] = useState();
  const [ campaign, setCampaign ] = useState<Campaign>();
  const [ userInfo, setUserInfo ] = useState<UserInfo>()
  const [ zoomLevel, setZoomLevel ] = useState<number>(1);

  const { zoomParam} = useParams();

  useEffect(() => {
    getZoomLevel().then((response: any) => {
        if (response.status === 200) {
          if (response.data.zoomLevel !== zoomLevel) {
            setZoomLevel(response.data.zoomLevel)
          }
        }
      });
  }, [])


  useEffect(() => {
    const fetchData = async () => {
      const response = await getCurrentStatus();
      if (response.status === 200) {
        // if compareTo is given, we compare against this object, and if they're the same, dont set
        setCurrentStatus(x => {
            let tmpA =  {...x};
            let tmpB = {...response.data}
            delete tmpA.lastUpdated;
            delete tmpB.lastUpdated;
            return JSON.stringify(tmpA) === JSON.stringify(tmpB) ? x : response.data;
          })
      }
    }

    const timer = setInterval(fetchData, currentStatusInterval)
    fetchData();

    return () => {
      clearInterval(timer)
    }
  }, [])


  useEffect(() => console.log("CURRENT STATUS CHANGED", currentStatus), [currentStatus])
  useEffect(() => console.log("STATS CHANGED", stats, zoomLevel), [stats])
  useEffect(() => console.log("CAMPAIGN CHANGED", campaign), [campaign])
  useEffect(() => console.log("CAMPAIGN CHANGED", campaign), [campaign])


  const fetchWithInterval = (fetchFunction: () => any, setFunction: (arg0: any) => any, interval: number, checkIfShouldSet: boolean = false) => {
    const fetchData = async () => {
      const response = await fetchFunction();
      if (response.status === 200) {
        if (checkIfShouldSet) {
          setFunction((x: any) => JSON.stringify(x) === JSON.stringify(response.data) ? x : response.data);
        } else {
          setFunction(response.data)
        }
      }
    }

    const timer = setInterval(fetchData, interval)
    fetchData();

    return () => {
      clearInterval(timer)
    }
  }

  useEffect(() => {
    fetchWithInterval(getUserInfo, setUserInfo, tokenInterval, true);
    fetchWithInterval(getStats, setStats, statsInterval, true);
    fetchWithInterval(getCurrentCampaign, setCampaign, statsInterval, true);
    fetchWithInterval(getTicks, (x) => {
      let t: TickModel[] = [] as TickModel[];
      t = t.concat(x.generalTicks || []).concat(x.customTicks || []);
      setTicks(t);
    }, ticksInterval, false);
    fetchWithInterval(getLogo, x => setLogo(x?.logo), tokenInterval, false);
  }, [])

  useEffect(() => {
    const localRenewToken = async () => {
      await renewToken();
    }

    const interval = setInterval(localRenewToken, tokenInterval)

    return () => {
      clearInterval(interval)
    }
  }, [])



  return (
    <div className="App" style={{zoom: zoomLevel}}>
      { userInfo &&
        <>
          <div id="topArea">
            <TopBar currentStatus={currentStatus} logo={logo}/>
          </div>
          <div id="middleArea">
            <div className={`midLeft blueBox ${currentStatus?.blacklistedMeal ? 'inactive' : ''}`}>
              <LeftBox currentStatus={currentStatus} stats={stats} />
            </div>
            <div className="midCenter">
              <MainBox currentStatus={currentStatus} stats={stats} />
            </div>
            <div className="midRight">
              <TotalSavedBox stats={stats} currentStatus={currentStatus}/>
              { campaign &&
                  <CampaignBox stats={stats} campaign={campaign} userInfo={userInfo} />
              }
              {/*<GoalBox stats={stats} />*/}
              {/*<BadgesBox stats={stats} />*/}
            </div>
          </div>
          <div id="bottomArea">
            <BottomBar stats={stats} ticks={ticks} />
          </div>
        </>
      }
    </div>
  );
}

export default App;
