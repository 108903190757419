import React, {FC, useEffect, useState} from 'react';
import './TickEditorPage.scss';
import TickCard from "./TickCard/TickCard";
import {TickModel, TicksConfiguration} from "../../../models/TickModel";
import {Icons} from "../../../dashboard/components/TickerSymbol/TickerSymbol";
import Button from "react-bootstrap/Button";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {getTicksConfiguration, updateTicksConfiguration} from "../../adminApi";
import {Form} from "react-bootstrap";


interface TickEditorPageProps {}

const TickEditorPage: FC<TickEditorPageProps> = () => {
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [originalTicks, setOriginalTicks] = useState<TickModel[]>([]);
    const [currentTicks, setCurrentTicks] = useState<TickModel[]>([]);
    const [ticksConfiguration, setTicksConfiguration] = useState<TicksConfiguration>();
    const [modified, setModified] = useState<boolean>(false);
    const [showExtendedHelp, setShowExtendedHelp] = useState<boolean>(false);

    useEffect(() => {
        let mounted = true;
        getTicksConfiguration().then((res: any) => {
            if (mounted) {
                if (res && res.status === 200) {
                    console.log("BLALLBLBLB", res)
                    let ticks = res.data;
                    if (!ticks) {
                        setErrorMsg("Fejl i at hente informations tekster. Ugyldig data returneret. Load siden igen");
                    } else {
                        setTicksConfiguration(ticks);
                        setCurrentTicks(ticks.customTicks);
                        setOriginalTicks(ticks.customTicks);
                        setModified(false);
                    }
                } else {
                    setErrorMsg("Kunne ikke hente grænseværdier. Load siden igen");
                }
            }
        })
        return () => { mounted = false };
    }, []);

    const addNewCustomTick = () => {
        let newTick: TickModel = {
            text: "",
            active: true,
            icon: Icons.Info
        };
        setCurrentTicks((prev: TickModel[]) => prev.concat(newTick));
        setModified(true);
    }

    const tickChanged = (tick: TickModel, index: number) => {
        setModified(true);
        setCurrentTicks(prev => {
            let t = [...prev];
            t[index] = tick;
            return t;
        });
    }

    const tickDeleted = (index: number) => {
        setCurrentTicks((prev: TickModel[]) => {
            const copy = Array.from(prev);
            copy.splice(index, 1)
            return copy;
        });
        setModified(true);
    }

    // a little function to help us with reordering the result
    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        let items = reorder(
            currentTicks,
            result.source.index,
            result.destination.index
        ) as TickModel[];

        // updateIndexes(items);
        setCurrentTicks(items);
        setModified(true);
    }

    const saveClicked = async () => {
        // validate:
        for (let i=0; i< currentTicks.length; i++) {
            if (currentTicks[i].text.length < 5) {
                setErrorMsg("Tekst skal være over 2 bogstaver");
                return;
            }
        }
        console.log("KASPER",  currentTicks);

        // then save
        updateAndSave({ customTicks: currentTicks });
        setOriginalTicks(currentTicks);
        setModified(false);
        setErrorMsg("");
    }

    const resetClicked = () => {
        setModified(false);
        setCurrentTicks(originalTicks);
    }

    const updateAndSave = (change: any) => {
        setTicksConfiguration(x => {
            if (x) {
                let n = {...x, ...change}
                updateTicksConfiguration(n)
                console.log(n)
                return n;
            }
        });
    }

    const toggleStandardEnabled = () => {
        updateAndSave({standardTicksEnabled: !ticksConfiguration?.standardTicksEnabled})
    }

    const toggleCampaignEnabled = () => {
        updateAndSave({campaignTicksEnabled: !ticksConfiguration?.campaignTicksEnabled})
    }


    return (
        <div className="TickEditorPage">
            <div className="adminFrame">
                <h1>Informations tekster</h1>
                <i>Her kan man opsætte hvilke beskeder der kører i bunden af skærmen og deres rækkefølge.</i>
                <div className="box">
                    <h4>Standard tekster</h4>
                    <i>Madspildskrigernes standard tekster med nogle stats om jeres madspild og gode råd til at mindske det</i>
                    <div className="activationArea">
                        <label>Aktivér standard tekster</label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={ticksConfiguration?.standardTicksEnabled}
                            onChange={toggleStandardEnabled}
                        />
                    </div>
                </div>
                <div className="box">
                    <h4>Kampagne tekster</h4>
                    <i>Tekster der relaterer til månedens kampagne(r)</i>
                    <div className="activationArea">
                        <label>Aktivér kampagne tekster</label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            checked={ticksConfiguration?.campaignTicksEnabled}
                            onChange={toggleCampaignEnabled}
                        />
                    </div>
                </div>
                <div className="box">
                    <h4>Egne beskeder</h4>
                    <i>Dette er beskeder i har fuld kontrol over, og de kan indeholde hvad det skulle være</i>
                    {/*    <i>*/}
                    {/*        Med simple beskeder*/}
                    {/*          kan man blot skrive teksten, vælge type og sætte som aktiv. Der er nogle udvidede funktioner som man kan bruge*/}
                    {/*          i besked-teksterne. <Button onClick={() => setShowExtendedHelp(prev => !prev)} variant={"info"}>{showExtendedHelp ? "Skjul info" : "Mere info"}</Button>*/}
                    {/*    </i>*/}
                    {/*    { errorMsg && <Alert variant="danger">{errorMsg}</Alert> }*/}
                    {/*    { showExtendedHelp && (*/}
                    {/*        <div>*/}
                    {/*            <hr />*/}
                    {/*            Tekstfelterne understøtter både muligheden for at henvise til den indsamlede data og lave simpel matematiske beregninger.*/}
                    {/*            For at henvise til noget indsamlet data bruges syntax <code>$dataPunkt</code>. De gyldige værdier er følgende:*/}
                    {/*            <ul>*/}
                    {/*                <li><code>$samletSpildKg</code>: Erstattes af det samlede madspild til dags dato</li>*/}
                    {/*                <li><code>$samletSparetKg</code>: Erstattes af det samlede mad sparet ift til det oprindelige gennemsnit til dags dato </li>*/}
                    {/*                <li><code>$mestSpildPåEtMåltidKg</code>: Erstattes af den største mængde mad der er spildt i kg på et enkelt måltid</li>*/}
                    {/*                <li><code>$mestSpildPåEtMåltidNavn</code>: Erstattes af navnet på det måltid hvor der er spildt mest på et enkelt måltid (f.eks. frokost)</li>*/}
                    {/*                <li><code>$mindstSpildPåEtMåltidKg</code>: Erstattes af den mindste mængde mad der er spildt i kg på et enkelt måltid</li>*/}
                    {/*                <li><code>$mindstSpildPåEtMåltidNavn</code>: Erstattes af navnet på det måltid hvor der er spildt mindst på et enkelt måltid (f.eks. frokost)</li>*/}
                    {/*                <li><code>$mestSpildPåEnDagKg</code>: Erstattes af den største mængde mad smidt ud på en enkelt dag</li>*/}
                    {/*                <li><code>$mindstSpildPåEnDagKg</code>: Erstattes af den mindste mængde mad smidt ud på en enkelt dag</li>*/}
                    {/*                <li><code>$gennemsnitSpildPåEnDagKg</code>: Erstattes af den gennemsnitlige mængde mad smidt ud på en enkelt dag</li>*/}
                    {/*                <li><code>$længsteStreak</code>: Erstattes af antal dage for det længste streak</li>*/}
                    {/*                <li><code>$nuværendeStreak</code>: Erstattes af antal dage for det nuværende streak</li>*/}
                    {/*            </ul>*/}
                    {/*            Herudover kan man desuden lave simpel matematik ved at indkapsle matematikken i tuborg-parenteser: <code>{}</code>*/}
                    {/*            <p>*/}
                    {/*                <b>Eksempeler</b>*/}
                    {/*                <ul>*/}
                    {/*                    <li><code>I har i alt spildt $samletSpildKg kg mad</code> -&gt; I har i alt spildt 43.6 kg mad</li>*/}
                    {/*                    <li><code>Forskellen mellem den dag i har smidt mindst ud og mest ud er {"{100*$mestSpildPåEnDagKg/$mindstSpildPåEnDagKg}"}%</code> -&gt; Forskellen mellem den dag i har smidt mindst ud og mest ud er 280%</li>*/}
                    {/*                    <li><code>1 + 1 er {"{1 + 1}"}</code> -&gt; 1 + 1 er 2</li>*/}
                    {/*                </ul>*/}
                    {/*            </p>*/}
                    {/*            <Button onClick={() => setShowExtendedHelp(false)} variant={"info"}>Skjul info</Button>*/}

                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*    <hr />*/}
                    <Button variant="outline-primary" className="float-right" onClick={addNewCustomTick}>Tilføj Besked</Button>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {currentTicks.map((item, index) => (
                                        <Draggable key={index} draggableId={`${index}`} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <TickCard tick={item} id={index} onChange={tickChanged} onDelete={tickDeleted}/>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <div className="standardButtons">
                        <Button variant="primary" onClick={saveClicked} disabled={!modified}>Gem</Button>
                        <Button variant="warning" onClick={resetClicked} disabled={!modified}>Fortryd</Button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TickEditorPage;
