import React, {FC, useEffect, useState} from "react";
import './CampaignCard.scss';
import {Campaign, CampaignTypes, campaignTypesNameMap} from "../../../models/Campaign";
import {AiOutlineCheckCircle, AiOutlineExclamationCircle} from "react-icons/ai";
import Button from "react-bootstrap/Button";
import EditableText from "../EditableFields/EditableText";
import EditableMonth from "../EditableFields/EditableMonth";
import EditableSelect from "../EditableFields/EditableSelect";
import {isGroupType, validateCampaign} from "../../../utils/campaignUtils";
import EditableGroups from "../EditableFields/EditableGroups";

interface CampaignProps {
    campaign: Campaign | undefined,
    readOnly: boolean,
    users: {[key: string]: string}
    onOpenClick?: (campaign: Campaign) => any;
    onCancelClick?: () => any;
    onSaveClick?: (campaign: Campaign) => any;
}

const CampaignCard: FC<CampaignProps> = (props) => {
    const [editedCampaign, setEditedCampaign] = useState<Campaign>({} as Campaign)
    const [isModified, setIsModified] = useState<boolean>(false)
    const [errors, setErrors ] = useState<string[]>([])
    const [campaign, setCampaign] = useState<Campaign>({} as Campaign);
    const [isNewCampaign, setIsNewCampaign] = useState<boolean>();

    useEffect(() => {
        if (props.campaign === undefined) {
            setIsNewCampaign(true);
            let newCampaign: Campaign = {
                name: "Ny Kampagne",
                monthIndex: new Date().getMonth(),
                year: new Date().getFullYear(),
                type: CampaignTypes.SoloMealGoals,
                isValid: false,
                userIds: [],
                data: {}
            };
            setCampaign(newCampaign);
        } else {
            setCampaign(props.campaign);
            setIsNewCampaign(false);
        }
    }, [props.campaign]);

    useEffect(() => {
        if (campaign) {
            let clone = {...campaign};
            if (clone.data) {
                clone.data = {...clone.data};
                if (clone.data.groups) {
                    clone.data.groups = clone.data.groups.map(group => {
                        return {
                            ...group,
                            userIds: [...group.userIds]
                        }
                    });
                }
            }
            setErrors(validateCampaign(clone));
            setEditedCampaign(clone)
        }
    }, [campaign]);

    const mapToList = (origMap: {[key: string]: string}) =>
        Object.entries(origMap).map(([key, val]) => {return { value: key, label: val }})

    const updateCampaign = (obj: any) => {
        setEditedCampaign(campaign => {
            let updatedCampaign = {...campaign, ...obj} as Campaign;
            setErrors(validateCampaign(updatedCampaign));
            return updatedCampaign;
        })
        setIsModified(true);
    }

    const updateCampaignData = (obj: any) => {
        setEditedCampaign(campaign => {
            let updatedCampaign = {...campaign, data: {...campaign.data, ...obj}} as Campaign;
            setErrors(validateCampaign(updatedCampaign));
            return updatedCampaign;
        });
        setIsModified(true);
    }

    return (
        <div className={`campaignCard ${props.readOnly ? "readOnly" : "editAllowed"}`}>

            { props.readOnly &&
                <>
                    <div className="header">
                        <h4>Kampagne: {campaign.name}</h4>
                        { campaign.isValid ? <AiOutlineCheckCircle className="valid"/> : <AiOutlineExclamationCircle className="invalid" />}
                    </div>
                    <div className="type">{campaignTypesNameMap[campaign.type]}</div>
                    <div className="mainArea">
                        <div className="participants">
                            <p className="title">Deltagere:</p>
                            { campaign.userIds && campaign.userIds.length > 0 &&
                                <ul>
                                    { campaign.userIds && campaign.userIds.map(userId =>
                                        <li key={userId}>{props.users[userId]}</li>
                                    )}
                                </ul>
                            }
                            { (!campaign.userIds || !campaign.userIds.length) &&
                                <p className="noContestants">Ingen deltagere</p>
                            }
                        </div>
                        { props.onOpenClick &&
                            <div className="openButtonContainer">
                                <Button variant="btn btn-secondary" onClick={() => props.onOpenClick && props.onOpenClick(campaign)}>Åbn</Button>
                            </div>
                        }
                    </div>
                </>
            }
            {!props.readOnly &&
                <>
                    <div className="header">
                        <h4>Kampagne:
                            <EditableText text={editedCampaign.name} textUpdated={(newName) => updateCampaign({ name: newName })} /></h4>

                        { editedCampaign.isValid ? <AiOutlineCheckCircle className="valid"/> : <AiOutlineExclamationCircle className="invalid" />}
                    </div>
                    <div className="type">
                        <span>Type:</span> <EditableSelect selected_id={editedCampaign.type}
                                        options={mapToList(campaignTypesNameMap)}
                                        selectionUpdated={x => updateCampaign({ type: x })} />
                    </div>
                    <div className="time">Dato:
                        <EditableMonth year={editedCampaign.year} monthIndex={editedCampaign.monthIndex}
                                       dateUpdated={(y, m) => updateCampaign({year: y, monthIndex: m})} />
                    </div>
                    <div className="partipants">
                        <span>Participants:</span> <EditableSelect
                            multi={true}
                            selected_ids={editedCampaign.userIds}
                            options={mapToList(props.users)}
                            selectionUpdated={x => updateCampaign({ userIds: x })} />
                    </div>
                    { editedCampaign.type === CampaignTypes.GroupContestElimination && (
                        <div className="eleminationLimit">
                            <hr />
                            <label>Antal der går videre:</label>
                            <EditableText text={editedCampaign.data?.configuration?.eliminationLimit} textUpdated={v => updateCampaignData({configuration: { eliminationLimit: +v}})} />
                        </div>
                    )}
                    <div className="campaignData">
                        { isGroupType(editedCampaign) &&
                            <>
                                <hr />
                                <div className="groupArea">
                                    <span>Groups:</span>
                                    <EditableGroups usersMap={props.users}
                                                    campaign={editedCampaign}
                                                    groupsUpdated={x => updateCampaignData({ groups: x })}
                                    />
                                </div>
                            </>
                        }
                    </div>
                    <hr />
                    { errors.length > 0 &&
                        <>
                            <div className="errorsArea">
                                <h5>Errors</h5>
                                <ul>
                                    { errors.map(error => <li key={error}>{error}</li>)}
                                </ul>
                            </div>
                            <hr />
                        </>
                    }
                    <div className="mainArea">
                        <div className="buttons">
                            <Button variant="btn btn-primary"
                                    onClick={() => props.onSaveClick && editedCampaign && props.onSaveClick(editedCampaign)}
                                    disabled={!isModified && !isNewCampaign}
                            >{isNewCampaign ? "Opret" : "Gem" }</Button>
                            <Button variant="btn btn-warning" onClick={() => props.onCancelClick && props.onCancelClick()}>Cancel</Button>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default CampaignCard;
