import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import {CurrentStatus} from "../models/CurrentStatus";
import {Stats} from "../models/Stats";
import {TickModel} from '../models/TickModel';
import createPersistentStorage from "../persistent-storage";
import {react_app_backend_url} from "../globalApi";
import {Campaign} from "../models/Campaign";
import {UserInfo} from "../models/UserInfo";
// import { camelCase } from 'lodash';

let backendUrl = react_app_backend_url

const accessTokenStorage = createPersistentStorage<string>("access_token");

const dashboardAxios = axios.create();


dashboardAxios.interceptors.request.use(function (config) {
  if (config?.headers) {
    let token = accessTokenStorage.read()
    config.headers.Authorization = `Bearer ${token}`;

  config.responseEncoding = "utf8"
  config.headers.responseEncoding = "utf8"
  config.headers.charset = "utf8";
  }

  return config;
});

dashboardAxios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response.status === 403) {
        window.location.href = "/login"
      }
    });

// NOT UTF-8 compliant
// const camelizeKeys: any = (obj: any) => {
//     if (Array.isArray(obj)) {
//         return obj.map(v => camelizeKeys(v));
//     } else if (obj != null && obj.constructor === Object) {
//         return Object.keys(obj).reduce(
//             (result, key) => ({
//                 ...result,
//                 [cc(key)]: camelizeKeys(obj[key]),
//             }),
//             {},
//         );
//     }
//     return obj;
// };

export const recursiveToCamel = (item: unknown): unknown => {
    if (Array.isArray(item)) {
        return item.map((el: unknown) => recursiveToCamel(el));
    } else if (typeof item === 'function' || item !== Object(item)) {
        return item;
    }
    return Object.fromEntries(
        Object.entries(item as Record<string, unknown>).map(
            ([key, value]: [string, unknown]) => [
                key.replace(/([-_][a-z])/gi, c => c.toUpperCase().replace(/[-_]/g, '')),
                recursiveToCamel(value),
            ],
        ),
    );
};


// adminAxios.interceptors.request.use(function (config) {
//     if (config?.headers) {
//         const token = superAccessTokenStorage.read();
//         if (token !== undefined) {
//             config.headers.Authorization = 'Bearer ' + superAccessTokenStorage.read();
//         }
//         config.responseEncoding = "utf-8"
//         config.headers.responseEncoding = "utf-8"
//         config.headers.charset = "utf-8";
//     }
//
//     return config;
// });


export async function checkIfLoggedNoRedirect() : Promise<boolean> {
    return new Promise<boolean>(resolve => {
        axios.get(backendUrl + "current_state", { headers: {'Authorization': `Bearer ${accessTokenStorage.read()}`}})
            .then(
                success => resolve(true),
                fail => resolve(false))
        });

}

export async function getCurrentStatus() : Promise<{data: CurrentStatus, status: number}> {
  const client = applyCaseMiddleware(dashboardAxios);
  return client.get(backendUrl + "current_state");
}

export function getStats() : Promise<{data: Stats, status: number}> {
  const client = applyCaseMiddleware(dashboardAxios);
  return client.get(backendUrl + "stats");
}

export function getTicks() : Promise<{data: { generalTicks: TickModel[], customTicks: TickModel[], campaignTicks: TickModel[] }, status: number}> {
  const client = applyCaseMiddleware(dashboardAxios);
  return client.get(backendUrl + "ticks");
}

export function getLogo() : Promise<any> {
    const client = applyCaseMiddleware((dashboardAxios));
    return client.get(backendUrl + "logo");
}

export function getCurrentCampaign() : Promise<{data: Campaign[], status: number}> {
    // let p: Promise<{data: Campaign[], status: number}> = new Promise(async a => {
    //     const client = applyCaseMiddleware((dashboardAxios));
    //     // const client =  applyCaseMiddleware(dashboardAxios);
    //     let res: {data: Campaign[], status: number} = await client.get(backendUrl + "campaigns");
    //     let res2 = await client({method: "GET", url: backendUrl + "campaigns", responseType:'arraybuffer'});
    //     let toReturn = JSON.parse(new TextDecoder("utf-8").decode(res2.data))
    //     a({data: toReturn, status: 200})
    // })
    // return p
    const client = applyCaseMiddleware((dashboardAxios));
    return client.get(backendUrl + "campaigns", {
            transformResponse: (res) => {
            // Do your own parsing here if needed ie JSON.parse(res);
                const parsed = JSON.parse(res)
                const camelized = recursiveToCamel(parsed)
                return camelized;
        }}   );
}

export function getUserInfo() : Promise<UserInfo> {
    const client = applyCaseMiddleware((dashboardAxios));
    return client.get(backendUrl + "userInfo");
}

export function getZoomLevel() : Promise<UserInfo> {
    const client = applyCaseMiddleware((dashboardAxios));
    return client.get(backendUrl + "zoomLevel");
}

export async function renewToken() {
    const client = applyCaseMiddleware(dashboardAxios);
    await client.post(backendUrl + "login/extend-access-token")
      .then((response) => {
        if (response.status === 200 ) {
            if (response.data?.accessToken) {
                accessTokenStorage.write(response.data.accessToken)
            }
        }
        if (response.status !== 200) {
          console.error("Failed to renew auth token");
        }
      })
}

