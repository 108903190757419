import React, {FC, useEffect, useState} from 'react';

import {Trace} from "../../superAdmin/models/Trace";
import "./TraceTable.scss"
import {localFormat} from "../../utils/dateUtils";
import {FaSpinner} from "react-icons/fa";
import {getTrace} from "../adminApi";

interface TraceTableProps {
    userId: string
}
const TraceTable: FC<TraceTableProps> = (props) => {
    const [trace, setTrace] = useState<Trace[]>([]);
    const [loadingTrace, setLoadingTrace] = useState(true);

    useEffect(() => {
        if (props.userId) {
            getTrace().then(payload => {
                setTrace(payload.data)
                setLoadingTrace(false);
            });
        }
    },[props.userId])

    return (
        <table className="traceTable">
            <tbody>
                <tr><th>Date</th><th>Weight reported</th><th>Offset used</th></tr>
                { loadingTrace && <tr><td><FaSpinner className="spin"></FaSpinner></td></tr>}
                { !loadingTrace && trace.map(traceEntry =>
                    <tr key={traceEntry.time} className={traceEntry.weight > 0 ? "nonZero" : ""}>
                        <td>{localFormat(traceEntry.time)}</td><td className="weight">{traceEntry.weight !== undefined ? traceEntry.weight.toFixed(2) : traceEntry.weight}</td><td className="offset">{traceEntry.offsetUsed === undefined ? "-" : traceEntry.offsetUsed.toFixed(2)}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default TraceTable;
