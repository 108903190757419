import React, {FC, useEffect, useState} from 'react';

import DatePicker, {DateObject} from "react-multi-date-picker";
import Button from "react-bootstrap/Button";
import {addBlacklistEntries as apiAddBlacklistEntries, getBlacklist, getMealsConfiguration, removeBlacklistEntries as apiRemoveBlacklistEntries} from "../../adminApi";
import './BlacklistManager.scss';
import {BlacklistEntry, BlacklistType} from "../../../models/BlacklistEntry";
import {MealList, MealType} from "../../../models/Meal";
import {prettyPrintDateOnly, toYmd} from "../../../utils/dateUtils";
import {User} from "../../../superAdmin/models/User";

interface BlacklistManagerProps {
    userInfo?: User
}


const BlacklistManager: FC<BlacklistManagerProps> = (props) => {
    const [ errorMsg, setErrorMsg ] = useState<string>("");
    const [ blacklistMap, setBlacklistMap ] = useState<{[key: string]: BlacklistType}>({})
    const [ selectedMonth, setSelectedMonth ] = useState<DateObject>(new DateObject());
    const [ meals, setMeals ] = useState<MealList>({} as MealList);
    const [ mealTypeList, setMealTypeList ] = useState<MealType[]>([]);
    const [ daysInMonth, setDaysInMonth ] = useState<Date[]>([])

    useEffect(() => {
        let mounted = true;
        getBlacklist().then(res => {
            if (mounted) {
                if (res && res.status === 200) {
                    let tmpMap: {[key: string]: BlacklistType} = {};
                    for (let entry of res.data) {
                        if (entry.id)
                            tmpMap[entry.id] = entry.blacklisted
                    }
                    setBlacklistMap(tmpMap)
                }
            }
        })
        return () => { mounted = false };
    }, [])

    useEffect(() => {
        let mounted = true;
        getMealsConfiguration().then((res: any) => {
            if (mounted) {
                if (res && res.status === 200) {
                    setMeals(res.data.meals);
                    setMealTypeList(Object.keys(res.data.meals) as MealType[]);
                } else {
                    setErrorMsg("Kunne ikke hente måltids konfiguration. Load siden igen");
                }
            }
        })

        return () => { mounted = false };
    }, [])

    useEffect(() => console.log(mealTypeList, meals), [mealTypeList, meals])

    useEffect(() => {
        var date = new Date(selectedMonth.year, selectedMonth.month.index, 1);
        var days: Date[] = [];
        while (date.getMonth() === selectedMonth.month.index) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        setDaysInMonth(days);
    }, [selectedMonth]);

    const blacklistWeekends = async () => {
        setErrorMsg("");
        console.log("mark all weekends");
        var blacklist = []
        for (let date of daysInMonth) {
            if (date.getDay()%6 === 0) { // weekend
                for (let mealType of mealTypeList) {
                    blacklist.push({
                        date: toYmd(date),
                        mealType: mealType,
                        blacklisted: BlacklistType.manual
                    });
                }
            } else if (date.getDay() === 5) {
                if (meals[MealType.dinner]) {
                    blacklist.push({
                        date: toYmd(date),
                        mealType: MealType.dinner,
                        blacklisted: BlacklistType.manual
                    });
                }
            }
        }
        await addBlacklistEntries(blacklist);
    }

    const generateBlacklistKey = (date: Date, mealType: MealType) => `${props.userInfo?.id}-${toYmd(date)}-${mealType}`;

    const selectedMonthChanged = (x: DateObject) => setSelectedMonth(x);

    const addBlacklistEntries = async (entries: BlacklistEntry[]) => {
        let ret = await apiAddBlacklistEntries(entries)
        let newEntriesMap: {[key: string]: BlacklistType} = {};
        for (let id of Object.values(ret.data)) {
            newEntriesMap[id] = BlacklistType.manual
        }
        setBlacklistMap(oldMap => Object.assign({}, oldMap, newEntriesMap))
    }

    const removeBlacklistEntries = async (ids: string[]) => {
        let ret = await apiRemoveBlacklistEntries(ids)
        setBlacklistMap(oldMap => {
            let updatedMap = {...oldMap};
            ids.forEach(id => delete updatedMap[id]);
            return updatedMap;
        })
    }

    const whitelistDayClicked = async (date: Date) => {
        let toRemove = [];
        for (let mealType of mealTypeList) {
            toRemove.push(generateBlacklistKey(date, mealType))
        }
        await removeBlacklistEntries(toRemove);
    }

    const blacklistDayClicked = async (date: Date) => {
        let newEntries = [];
        for (let mealType of mealTypeList) {
            newEntries.push({
                date: toYmd(date),
                mealType: mealType,
                blacklisted: BlacklistType.manual
            });
        }
        await addBlacklistEntries(newEntries);
    }

    const singleMealChanged = async (date: Date, mealType: MealType, whitelist: boolean) => {
        if (!whitelist) {
            let newEntry: BlacklistEntry = {
                date: toYmd(date),
                mealType: mealType,
                blacklisted: BlacklistType.manual
            };
            await addBlacklistEntries([newEntry])
        } else { // removed
            await removeBlacklistEntries([generateBlacklistKey(date, mealType)])
        }
    }

    return (
        <div className="BlacklistManager">
            <h3>Måltider der skal måles på</h3>
            <i>Vælg alle de måltider hvor målinger skal gemmes. Hvis et måltid IKKE er markeret på denne liste betyder det
                at mængden af mad der bliver smidt ud ikke gemmes for det pågældende dag, og derved heller indgår i diverse
                statistikker.</i>
            <div className="datesArea">
                <DatePicker
                    onlyMonthPicker
                    format="MMMM YYYY"
                    className="calendar1"
                    value={selectedMonth}
                    onChange={selectedMonthChanged}
                />
                <Button variant="outline-warning" onClick={blacklistWeekends}>Blacklist alle weekender</Button>
            </div>

            <div className="datesList">
                <table>
                    <tbody>
                        <tr>
                            <th>Dato</th>
                            { mealTypeList.map(mealType => <th key={mealType}>{meals[mealType]?.name}</th>) }
                            <th></th>
                        </tr>
                        { daysInMonth.map(date => (
                           <tr className={date.getDay()%6 === 0 ? "weekend" : ""}>
                               <td>{prettyPrintDateOnly(date)}</td>
                               { mealTypeList.map(mealType => (
                                   <td key={mealType}>
                                       <input type="checkbox"
                                              checked={!blacklistMap[generateBlacklistKey(date, mealType)]}
                                              onChange={x => singleMealChanged(date, mealType, x.target.checked)}/></td>))
                               }
                               <td className="buttons">
                                   <Button variant="outline-light" size="sm" onClick={() => whitelistDayClicked(date)}>Marker alle</Button>
                                   <Button variant="outline-light" size="sm" onClick={() => blacklistDayClicked(date)}>Fjern alle</Button>
                               </td>

                           </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BlacklistManager;
