import React, {FC, useEffect, useState} from 'react';
import {CurrentStatus} from "../../../models/CurrentStatus";
import Lottie from "lottie-react";
import aliveDrop from './lottie_alive.json';
// import alivePulse from './alive_pulse.gif'
import {Link} from "react-router-dom";
import {RiSignalWifiErrorLine} from "react-icons/ri";
import createPersistentStorage from "../../../persistent-storage";
import './TopBar.scss';

interface TopBarProps {
    currentStatus: CurrentStatus | undefined,
    logo: string | undefined
}

const accessTokenStorage = createPersistentStorage<string>("access_token");

const TopBar: FC<TopBarProps> = (props) => {
    const [mealText, setMealText] = useState("")
    const [subText, setSubText] = useState<string | undefined>()
    const [inactive, setInactive] = useState<boolean>(true)

    useEffect(() => {
        let txt: string = props.currentStatus?.currentMeal?.name || "";
        setSubText(undefined)
        if (inactive || !txt) {
            let now = new Date();
            if (now.getDay() <= 0 || now.getDay() >= 6) {
                txt = "God Weekend";
                if (inactive) {
                    setSubText("Jeres madspild registreres ikke i weekenden");
                }
            } else {
                if (now.getHours() < 10) {
                    txt = "Godmorgen";
                } else if (now.getHours() < 17) {
                    txt = "God dag";
                } else {
                    txt = "God aften";
                }
                if (inactive) {
                    setSubText("Jeres madspild registreres ikke i dag");
                }
            }
        }
        setMealText(txt);
    }, [props.currentStatus?.currentMeal?.name, inactive])

    useEffect(() => setInactive(!!props.currentStatus?.blacklistedMeal), [props.currentStatus?.blacklistedMeal])

    const logout = () => {
        accessTokenStorage.delete()
    }

    return (
        <div className="TopBar">
            <div className="logo" onClick={logout}>
                {props.logo && <img  src={`data:image/jpeg;base64,${props.logo}`} /> }
                {!props.logo && <RiSignalWifiErrorLine className="ErrorLogo" />}
            </div>

            {!inactive && <h1>{mealText}</h1>}
            {inactive &&
                <div className="inactiveHeaders">
                    <h1>{mealText}</h1>
                    <h3>{subText}</h3>
                </div>
            }
            <div className="aliveDrop">
                <Link to="/admin">
                    {/*<img src={alivePulse} />*/}
                    <Lottie animationData={aliveDrop} loop={true} autoplay={true}/>
                </Link>
            </div>
        </div>
    )
};

export default TopBar;
