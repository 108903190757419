import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './dashboard/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {AdminApp} from "./admin/AdminApp";
import {Login} from "./admin/Login/Login";
import LoginClient from "./dashboard/components/Login/Login";
import { CookiesProvider } from "react-cookie";
import {SuperAdminLogin} from "./superAdmin/Login/SuperAdminLogin";
import {SuperAdminApp} from "./superAdmin/SuperAdminApp";
import { ProSidebarProvider } from 'react-pro-sidebar';

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
        <ProSidebarProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/super-admin/login" element={<SuperAdminLogin />} />
                    <Route path="/super-admin" element={<SuperAdminApp />} />
                    <Route path="/admin/login" element={<Login />} />
                    <Route path="/admin" element={<AdminApp />} />
                    <Route path="/login" element={<LoginClient />} />
                    <Route path="/">
                        <Route index element={<App />} />
                        {/*<Route path=":zoomParam" element={<App />} />*/}
                    </Route>
                    {/*<Route path="/:zoomParam?" element={<App />} />*/}
                </Routes>
            </BrowserRouter>
        </ProSidebarProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
