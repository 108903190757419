import React, {FC, useEffect, useState} from "react";
import {AiOutlineCheck, AiOutlineClose, AiOutlineEdit} from "react-icons/ai";
import {monthNameString} from "../../../utils/dateUtils";
import DatePicker from "react-multi-date-picker";

interface EditableMonthProps {
    monthIndex: number,
    year: number,
    dateUpdated: (year: number, monthIndex: number) => any;
}

const EditableMonth: FC<EditableMonthProps> = (props) => {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [datepickerValue, setDatepickerValue] = useState(new Date())
    const [yearBeforeEdit, setYearBeforeEdit] = useState<number>(0);
    const [monthIndexBeforeEdit, setMonthIndexBeforeEdit] = useState<number>(0);

    useEffect(() => setDatepickerValue(new Date(props.year, props.monthIndex)), [props.monthIndex, props.year])

    const startEditMode = () => { setYearBeforeEdit(props.year); setMonthIndexBeforeEdit(props.monthIndex); setIsEditing(true); }
    const endEditMode = () => { setIsEditing(false); }
    const cancel = () => { endEditMode(); props.dateUpdated(yearBeforeEdit, monthIndexBeforeEdit)}

    return (
        <span style={{marginLeft: "4px"}}>
            { !isEditing &&
                <span style={{cursor: "pointer"}} onClick={() => startEditMode()}>
                    {monthNameString(props.monthIndex)} {props.year} <AiOutlineEdit />
                </span>
            }
            { isEditing &&
                <span>
                    <DatePicker
                        onlyMonthPicker
                        value={datepickerValue}
                        onChange={(x: any) => props.dateUpdated(x.year, x.monthIndex)}
                    />
                    <AiOutlineCheck onClick={endEditMode} style={{fontSize: "10px", marginLeft: "4px"}}/>
                    <AiOutlineClose onClick={cancel} style={{fontSize: "10px", marginLeft: "4px"}}/>
                </span>
            }
        </span>
    )
}

export default EditableMonth;
