import React, {FC, useEffect, useState} from 'react';
import './LogsPage.scss';
import Alert from "react-bootstrap/Alert";
import dayjs from "dayjs";
import {getLogs} from "../adminApi";
import {User} from "../../superAdmin/models/User";
import {Log} from "../../models/Log";
import {localPrettyPrint} from "../../utils/dateUtils";

const locale = require('dayjs/locale/da')
dayjs.locale(locale);


interface LogsPageProps {
    userInfo: User
}

const LogsPage: FC<LogsPageProps> = (props) => {
    const [ errorMsg, setErrorMsg ] = useState<string>("");
    // const [ campaigns, setCampaigns ] = useState<Campaign[]>([])
    const [ logs, setLogs ] = useState<Log[]>([])

    useEffect(() => {
        let mounted = true;

        getLogs().then((res: any) => {
            if (mounted) {
                if (res && res.status === 200) {
                    let logs = res.data as Log[];
                    setLogs(logs)
                } else {
                    setErrorMsg("Kunne ikke hente logs. Prøv at loade siden igen");
                }
            }
        })
        return () => { mounted = false };
    }, [])

    const classFromSeverity = (logEntry: Log) => {
        switch (logEntry.logLevel) {
            case 10:
            case 20:
                return "normal"
            case 30:
                return "yellow"
            case 40:
            case 50:
                return "red"
            default:
                return "normal"
        }
    }

    const nameFromSeverity = (logEntry: Log) => {
        switch (logEntry.logLevel) {
            case 10:
                return "Trace"
            case 20:
                return "Info"
            case 30:
                return "Advarsel"
            case 40:
                return "Fejl"
            case 50:
                return "Kritisk"
            default:
                return "Ukendt"
        }
    }

    return (
      <div className="logsPage">
        <div className="adminFrame">
            <h1 className="pageHeader">Logs</h1>
            { errorMsg && <Alert variant="danger">{errorMsg}</Alert> }
            <div className="box">
                <table className="logEntries">
                    <tbody>
                        <tr><th>Tidspunkt</th><th>Type</th><th>Besked</th></tr>
                        { logs.map((log, index) => (
                            <tr key={index} className={classFromSeverity(log)}>
                                <td>{localPrettyPrint(log.timestamp)}</td>
                                <td>{nameFromSeverity(log)}</td>
                                <td>{log.message}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    );
}

export default LogsPage;
